import axios from 'axios';
import Bluebird from 'bluebird';

export const FETCH_MY_LEARNING = 'FETCH_MY_LEARNING';
export const FETCH_MY_LEARNING_TRAIL = 'FETCH_MY_LEARNING_TRAIL';

export function fetchMyLearning() {

    return (dispatch) => {

        return axios.get('api/myLearning')
        .then(function(response) {

            dispatch({
                type: FETCH_MY_LEARNING,
                payload: response.data
            });

            return response;

        });
            
    }

}