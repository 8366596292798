import React from 'react';
import createReactClass from 'create-react-class';
import moment from 'moment';
// import {Button} from 'uiKit';
import {Button, Pagination, ActionBar} from 'uiKit';
import MyAccountOverviewTitle from './myAccountOverviewTitle'
import MyAccountOverviewItem from './myAccountOverviewItem'

var MyAccountOverviewSubscribers = createReactClass({

    getInitialState: function() {
        return {
            currentPage: 1,
            totalPages: Math.ceil( this.props.subscription.usersInGroup.length / 5 ),
            members: this.props.subscription.usersInGroup ? this.props.subscription.usersInGroup : 0,
            showedMembers: this.props.subscription.usersInGroup.slice(0, 5),
            maxUsersInGroup: 0
        };
    },

    componentDidMount: function() {
        const planId = this.props.subscription.plan_id;
        let maxUsersInGroup;

        switch (true) {
            case planId.startsWith('kl-small'):
                maxUsersInGroup = 5;
                break;
            case planId.startsWith('kl-medium'):
                maxUsersInGroup = 15;
                break;
            case planId.startsWith('kl-large'):
                maxUsersInGroup = 999999999;
                break;
            default:
                // TODO - handle error
        }

        this.setState({
            maxUsersInGroup: maxUsersInGroup
        });
    },

    onPreviousPageClicked: function() {
        let currentPage = this.state.currentPage - 1;

        this.setState({
            currentPage: currentPage,
            showedMembers: this.state.members.slice( ((currentPage - 1) * 5 ), (currentPage * 5) )
        });
    },

    onNextPageClicked: function() {
        let currentPage = this.state.currentPage + 1;

        this.setState({
            currentPage: currentPage,
            showedMembers: this.state.members.slice( ((currentPage - 1) * 5 ), (currentPage * 5) )
        });
    },

    renderSeniorMemberButton: function(user) {
        if ( user._role === 'teacher' ) {
            return (
                <div>
                    <Button
                        key={user._id}
                        className="my-account-subscribers-list-button"
                        text="Remove Senior Member"
                        type="secondary"
                        onClick={() => { this.props.onRemoveSeniorMemberClicked({'_id': user._id}) }}
                    />
                </div>
            );
        } else {
            return (
                <div>
                    <Button
                        key={user._id}
                        className="my-account-subscribers-list-button"
                        text="Set Senior Member"
                        type="secondary"
                        onClick={() => { this.props.onSetSeniorMemberClicked({'_id': user._id}) }}
                    />
                </div>
            );
        }
        
    },

    renderItems: function() {

        if ( this.state.members.length ) {
            
            return (
                <div className="clearfix">
                    <div className="my-account-overview-section-column">
                        {

                            this.state.showedMembers.map((user, index) => {

                                var userLine = user.firstName + " " + user.lastName;

                                if ( user._id !== this.props.user._id ) {
                                    return (
                                        <div className="clearfix" key={index}>
                                            <div className="my-account-overview-section-left-column">
                                                <MyAccountOverviewItem
                                                    key={index}
                                                    label={user._role === 'teacher' ? 'Senior Member' : user._role}
                                                    value={userLine}
                                                />
                                            </div>
                                            <div className="my-account-overview-section-right-column">
                                                <div>
                                                    <Button
                                                        key={user._id}
                                                        className="my-account-subscribers-list-button"
                                                        text="Remove from group"
                                                        type="secondary"
                                                        onClick={() => { this.props.onRemoveUserFromSubscriptionGroupClicked({'_id': user._id}) }}
                                                    />
                                                </div>
                                                {this.renderSeniorMemberButton(user)}
                                            </div>
                                        </div>
                                    );
                                }
                            })

                        }
                    </div>
                </div>
            );
        } else
            return (
                <div className="clearfix">
                    <div className="my-account-overview-section-column">
                        No members yet.
                    </div>
                </div>
            );
    },

    renderContent: function() {

            return (
                <div>
                    <div className="my-account-overview-section-column">
                        <div className="my-account-overview-section-left-column">
                            
                            <div className="my-account-overview-section-column">
                                <div className="my-account-overview-section-left-column">
                                    <MyAccountOverviewItem
                                        key="0"
                                        label="manager"
                                        value={this.props.customer.first_name + ' ' + this.props.customer.last_name}
                                    />
                                </div>
                                
                                <div className="my-account-overview-section-right-column">
                                    <MyAccountOverviewItem
                                        key="1"
                                        label="group name"
                                        value={this.props.subscription.subscriptionGroupName}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="my-account-overview-section-right-column">
                            <Button 
                                text="Change group name" 
                                type="primary"  
                                className="my-account-invoice-pdf-download-button"
                                onClick={this.props.onChangeSubscriptionGroupNameDialogClicked}
                            />
                        </div>
                    </div>

                    <div className="clearfix"></div>

                    
                    <hr />
                    {this.renderItems()}
                </div>
            );
    },

    renderPagination: function() {
        if ( this.state.members.length > 5 ) {
            return (
                <Pagination
                    isSmall={true}
                    className="action-bar-item"
                    currentPage={this.state.currentPage}
                    totalPages={this.state.totalPages}
                    buttonType="secondary"
                    onPreviousPageClicked={this.onPreviousPageClicked}
                    onNextPageClicked={this.onNextPageClicked}
                    hasDataLoaded={!this.props.isSyncing}
                />
            );
        }
    },

    render: function() {

        if(!this.props.isGroupManager) {
            return(
                <div />
            );
        }

        return (
            <div className="my-account-overview-section">
                <MyAccountOverviewTitle text="Users in Group"/>
                <div className="my-account-overview-content">
                    {this.renderContent()}
                </div>

                <ActionBar>
                    <div className="action-bar-left">
                        <Button 
                            text="Add User to Subscription Group" 
                            type="primary"  
                            onClick={this.props.onAddUserToSubscriptionGroupDialogClicked}
                            disabled={ this.props.subscription.usersInGroup.length >= this.state.maxUsersInGroup ? true : false }
                        />
                    </div>
                    <div className="action-bar-center">
                        {this.renderPagination()}
                    </div>
                    <div className="action-bar-right">
                        <span>
                            {this.state.members.length} of {this.state.maxUsersInGroup}
                        </span>
                    </div>
                </ActionBar>
            </div>
        );
    }

});

export default MyAccountOverviewSubscribers;
