import React from 'react';
import createReactClass from 'create-react-class';
import {registerType} from '../helpers';
import Field from '../components/field.jsx';

var FormFieldTextAreaPlain = createReactClass({

    getInitialState: function() {
        return {
            value: this.props.value || ''
        }
    },

    onChange: function(event) {
        var value = event.target.value;
        var that = this;

        this.setState({
            value: value
        });

        this.updateField();

    },

    updateField: _.throttle(function() {
        this.props.updateField(this.state.value);
    }, 800, {leading: false}),

    renderField: function({ ariaDescribedBy, ariaLabelledBy }) {
        return (
            <textarea 
                id={this.props.fieldId}
                className="form-field-input"
                type="text"
                aria-labelledby={ariaLabelledBy}
                aria-describedby={ariaDescribedBy}
                aria-required={this.props.isRequired}
                aria-invalid={!!this.props.error}
                value={this.state.value}
                onChange={this.onChange}
                onBlur={this.props.onBlur}
                onFocus={this.props.onFocus}/>
        )
    },

    render: function() {
        
        return (
            <Field 
                fieldId={this.props.fieldId}
                label={this.props.label}
                help={this.props.help}
                error={this.props.error} >
                {this.renderField}
            </Field>
        );
    }

});

registerType('TextArea:Plain', FormFieldTextAreaPlain);

export default FormFieldTextAreaPlain;