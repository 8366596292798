import axios from 'axios';
import {getSockets} from 'sockets'
import Bluebird from 'bluebird';

export const MARK_MESSAGE_AS_VIEWED = 'MARK_MESSAGE_AS_VIEWED';
export const OPEN_MESSAGES = 'OPEN_MESSAGES';
export const CLOSE_MESSAGES = 'CLOSE_MESSAGES';
export const LISTEN_TO_MESSAGES = 'LISTEN_TO_MESSAGES';
export const MESSAGES_UPDATED = 'MESSAGES_UPDATED';
export const IGNORE_MESSAGES = 'IGNORE_MESSAGES';

export function openMessages() {

    return (dispatch) => {

        dispatch({
            type: OPEN_MESSAGES
        });
            
    }

}

export function closeMessages() {

    return (dispatch) => {

        dispatch({
            type: CLOSE_MESSAGES
        });
            
    }

}

export function markMessageAsViewed(messageId) {
    return (dispatch) => {

        return axios.put('api/users/markMessageAsViewed/' + messageId)
        .then(function(response) {
            
            dispatch({
                type: MARK_MESSAGE_AS_VIEWED,
                payload: response.data
            });

            return response.data;

        });

    }
}

export function listenToMessages() {

    return (dispatch) => {

        var socket = getSockets();
        socket.on('server/messagesUpdated', function(data) {

            dispatch({
                type: MESSAGES_UPDATED,
                payload: data
            })

        });

        return dispatch({
            type: LISTEN_TO_MESSAGES,
            payload: {}
        });

    }        

}

export function ignoreMessages() {
    return (dispatch) => {

        var socket = getSockets();
        socket.removeAllListeners('server/messagesUpdated');

        return dispatch({
            type: IGNORE_MESSAGES,
            payload: {}
        });

    }
}