import React from 'react';
import classNames from 'classnames';

const ListItems = function(props) {
	return (
		<ul role="list" className={classNames('list-items', { "list-items-alternative-colors": props.shouldShowAlternativeColors }, props.className)}>
			{props.items}
		</ul>
	);	
}

export default ListItems;