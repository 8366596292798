import React from 'react';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import classNames from 'classnames';
import SearchBarResultItemContent from './searchBarResultItemContent';
import LP from 'modules/app/helpers/lp';

var SearchBarResultItem = createReactClass({

    getInitialState: function() {
        return {
            shouldRenderResult: this.props.shouldShowResult
        }
    },

    componentDidUpdate: function(prevProps) {
        if (this.props.shouldShowResult !== prevProps.shouldShowResult) {
            this.setState({
                shouldRenderResult: true
            })
        }
    },

    setRenderFalse: function() {
        if (!this.props.shouldShowResult) {
            this.setState({
                shouldRenderResult: false
            })
        }
    },

    handleClick: function(result) {
        if (this.props.isAdmin || (result._type === 'course' && !result._isAvailable)) {
            return;
        }

        this.props.onResultClicked(result);
    },

    renderResult: function() {
        var {shouldShowResult, result, isAdmin, index} = this.props;

        if (result._currentAvailability && !result._isAvailable && result._currentAvailability._shouldHideCourseWhenNotAvailable) {
            return null;
        }

        var isResultItemDisabled = false;

        if (result._type === 'course' && !result._isAvailable) {
            isResultItemDisabled = true;
        }

        return (
            <li
                style={{animation: `${shouldShowResult ? "fadeIn" : "fadeOut"} 0.3s forwards ease-in`, animationDelay: `${shouldShowResult ? `${0.1 * index}s` : '0s'}`}}
                onAnimationEnd={() => {this.setRenderFalse()}}
                className={classNames("search-bar-result-item", isAdmin ? "is-admin" : "", isResultItemDisabled ? "is-disabled" : "")}
                onClick={() => this.handleClick(result)}
                key={result._id}>
                <SearchBarResultItemContent {...this.props}/>
            </li>
        )
    },

    render: function() {
        return (this.state.shouldRenderResult && this.renderResult());
    }
})
export default SearchBarResultItem;