import React from 'react';
import classNames from 'classnames';

const ItemToggle = ({className, toggleId, onChange, initialValue}) => {
	var nameOfClass = classNames('forms-boolean-toggle', className);

	var onInputChange = (event) => {
		var checked = event.target.checked;
		onChange(checked);
	}

	return (
		<div>
			<input
				type="checkbox"
				id={toggleId}
				className={nameOfClass}
				defaultChecked={initialValue}
				onInputChange={onInputChange}
			/>
			<label htmlFor={toggleId} />
		</div>
	)
}

export default ItemToggle;