import React from 'react';
import classNames from 'classnames';


const ProgressBar = ({value, className, backgroundColor}) => {
	if (_.isUndefined(value) || !_.isNumber(value)) {
		console.warn("You're currently missing progress property");
	}

	var nameOfClass = classNames('progress-bar', className);
	var colorOfBackground = backgroundColor ? backgroundColor : '';
	var progressPercentage = value + '%';
	var style = {width: progressPercentage, backgroundColor: colorOfBackground}

	return (
		<div className={nameOfClass}
			aria-valuenow={Math.floor(value)}
			aria-valuetext="completion"
			role="progressbar"
			aria-valuemin="0"
			aria-valuemax="100"
		>
			<div className='progress-bar-inner' style={style}>
			</div>
		</div>
	)
}

export default ProgressBar;