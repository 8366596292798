import React from 'react';
import createReactClass from 'create-react-class';
import {Button} from 'uiKit';
import LP from 'modules/app/helpers/lp';

var LoginOpenRegister = createReactClass({

    onOpenRegisterClicked: function(event) {
        event.preventDefault();
        this.props.navigateToOpenRegister(event, 'register');
    },

    render: function() {
        if (authentication._shouldAllowOpenRegistrations) {  
            return (
                <div className="login-open-register">
                    <div className="login-open-register-title">
                        {LP('login', 'loginRegisterTitle', 'sentencecase')}
                    </div>
                    <div className="login-open-register-body" dangerouslySetInnerHTML={{__html: LP('login', 'loginRegisterBody', '')}}>
                    </div>
                    <Button type="primary large" text={LP('users', 'register', 'sentencecase')} onClick={this.onOpenRegisterClicked}/>
                </div>
            );
        }

        return null;
    }

});

export default LoginOpenRegister;