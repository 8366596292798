import React from 'react';
import createReactClass from 'create-react-class';
import {Button} from 'uiKit';
import MyAccountOverviewTitle from './myAccountOverviewTitle';
import MyAccountOverviewItem from './myAccountOverviewItem';

var MyAccountOverviewProfile = createReactClass({

    render: function() {

        if(this.props.isUserBasic) {
            return(
                <div />
            );
        }

        return (
            <div className="my-account-overview-section">
                <MyAccountOverviewTitle text="Payment Contact Details"/>
                <div className="my-account-overview-content">
                    <MyAccountOverviewItem
                        label="First Name"
                        value={this.props.customer.first_name}
                    />
                    <MyAccountOverviewItem
                        label="Last Name"
                        value={this.props.customer.last_name}
                    />
                    <MyAccountOverviewItem
                        label="Email"
                        value={this.props.customer.email}
                    />
                    <Button text="Update Details" type="primary" onClick={this.props.onUpdateDetailsClicked}/>
                </div>
            </div>
        );
    }

});

export default MyAccountOverviewProfile;
