import React from 'react';

const ClickableWrapper = function({onClick, children, href, ariaLabel}) {
	return (
		<a
			className="list-item-clickable"
			onClick={onClick}
			aria-label={ariaLabel}
			tabIndex="0"
			href={href || null}
			onKeyPress={(event) => {
				if (event.keyCode === 0) {
					return onClick(event);
				}
			}}
		>
			<div className="list-item-inner clearfix">
				{children}
			</div>
		</a>
	);
}

const ActionsWrapper = function({children}) {
	return (
		<div className="list-item-inner clearfix">
			{children}
		</div>
	);
}

const ListItemWrapper = function(props) {
	return (props.onClick && !props.buttons) ? <ClickableWrapper {...props} /> : <ActionsWrapper {...props} />;
}

export default ListItemWrapper;