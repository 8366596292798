import {registerCondition} from '../helpers';
import Store from 'store';

registerCondition("myProfile:shouldAllowUsersToChooseLanguage", (props, next) => {
    var shouldAllowUsersToChooseLanguage = _.get(Store.getState(), 'settings._translations._shouldAllowUsersToChooseLanguage');

    next(!shouldAllowUsersToChooseLanguage);
});


registerCondition("myProfile:shouldAllowUsersToReselectGroups", (props, next) => {
    var shouldAllowUsersToChooseLanguage = _.get(Store.getState(), 'settings._myProfile._shouldAllowUsersToReselectGroups', true);

    next(!shouldAllowUsersToChooseLanguage);
});