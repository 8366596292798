const INITIAL_STATE = {
    _toasts:[],
    _dialogs:[],
    _currentNotification:null
};
 
export const ADD_DIALOG = 'ADD_DIALOG';
export const REMOVE_DIALOG = 'REMOVE_DIALOG';
export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST ='REMOVE_TOAST';


export default function(state = INITIAL_STATE, action) {    
    switch(action.type) {
        case ADD_DIALOG:
            return addDialog(state, action.payload);
        case ADD_TOAST:
            return addToast(state, action.payload);
        case REMOVE_DIALOG:
            return removeDialog(state);
        case REMOVE_TOAST:
            return removeToast(state);
        default:
            return state;          
    }       
}

function addDialog(state, dialog){
    var newState = _.assign({}, state, {
                _dialogs : [
                    ...state._dialogs, 
                    _.merge(dialog, {
                        _id: assignNotificationId(),
                        _type: 'dialog'
                    })
                ]
            });
    newState._currentNotification = resolveCurrentNotification(newState);
    return newState;
}

function addToast(state, toast){
   var newState = _.assign({}, state, {
                _toasts : [
                    ...state._toasts, 
                    _.merge(toast,{
                        _id: assignNotificationId(),
                        _type: 'toast'
                    })
                ]
            });
    newState._currentNotification = resolveCurrentNotification(newState);
    return newState;
}

function removeDialog(state){
    var newState = _.assign({}, state, {
                _dialogs: state._dialogs.slice(1)
            });
    newState._currentNotification = resolveCurrentNotification(newState);
    return newState;
}

function removeToast(state){
    var newState = _.assign({}, state, {
                _toasts: state._toasts.slice(1)
            });
    newState._currentNotification = resolveCurrentNotification(newState);
    return newState;
}

function resolveCurrentNotification(newState){
    var currentNotification = null;
    if(newState._dialogs.length > 0) {
        currentNotification = newState._dialogs[0];
    } else if(newState._toasts.length > 0) {
        currentNotification = newState._toasts[0];
    }
    return currentNotification;
}


function assignNotificationId(){
    return Math.random() * (99999 - 10000) + 10000;
}









