import React, {Fragment} from 'react';
import createReactClass from 'create-react-class';
import classnames from 'classnames';

var SearchBarInput = createReactClass({
    getInitialState: function() {
        return {
            placeholderIndex: 0
        }
    },

    componentDidMount: function() {
        if (!this.props.placeholders) {
            return;
        }
        
        this.placeholderInterval = setInterval(() => {
            var newPlaceholderIndex = this.state.placeholderIndex === this.props.placeholders.length - 1 ? 0 : this.state.placeholderIndex + 1;
            this.setState({
                placeholderIndex: newPlaceholderIndex
            })
        }, 4000);
    },

    componentWillUnmount: function() {
        clearInterval(this.placeholderInterval);
    },

    getSearchBarInputStyles: function() {
        return classnames("search-bar-input",
            this.props.shouldAlwaysBeVisible ? "is-always-visible" : "",
            this.props.isSmall ? "is-small" : "")
    },

    handleInputChange: function(event) {
        this.props.onSearchBarInputChanged(event.target.value);
    },

    renderPlaceholder: function() {
        var opacity = 0;
        var animationIterationCount = 1;
        var animationDuration = '0s';

        if (this.props.searchValue.length === 0) {
            opacity = 1;
            animationIterationCount = 'infinite';
            animationDuration = '4s';
        }
        
        if ((this.props.placeholders && this.props.placeholders.length === 1) && this.props.searchValue.length === 0) {
            opacity = 1;
            animationIterationCount = 0;
            animationDuration = '0s';
        }

        if (!this.props.placeholders) {
            return (
                <span style={{opacity: opacity}} className="search-bar-input-placeholder-prefix">Search</span>
            )
        }

        return (
            <div className="search-bar-input-placeholder-prefix-wrapper">
                <span style={{opacity: opacity}} className="search-bar-input-placeholder-prefix">Search for</span>                
                <span style={{opacity: opacity, animationIterationCount: animationIterationCount, animationDuration: animationDuration}} className="search-bar-input-placeholder-suffix">{` ${this.props.placeholders[this.state.placeholderIndex]}`}</span>
            </div>

        )

    },

    render: function() {
        const {isSyncing} = this.props;

        return (
            <div className={this.getSearchBarInputStyles()}>
                <div className="unified-search-bar-icon">
                    <i className="icon icon-magnifier" />
                    <div className={classnames("spinner", {"is-visible": isSyncing})} />
                </div>
                <input
                    onChange={(event) => {this.handleInputChange(event)}}
                    className="search-bar-input-field"
                    type="text"
                    value={this.props.searchValue} />
                {this.renderPlaceholder()}
            </div>
        );
    }
});

export default SearchBarInput;