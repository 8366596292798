import React from 'react';
import createReactClass from 'create-react-class';
import {DialogTitle, DialogBody, DialogActions} from 'modules/notifications/components/dialogComponents.js';
import Dropzone from 'react-dropzone';
import LP from 'modules/app/helpers/lp';
import {Form} from 'modules/form';
import AlertInfo from 'modules/app/components/alertInfo';
import {ProgressBar, Button, Loading} from 'uiKit';


var MyAccountImportUsersDialog = createReactClass({

    getInitialState: function() {
        return {
            _isSyncing: false,
            _error: null, 
            progress: 0,
            files: [],
            _userUpload: {
                _role: 'learner',
                _shouldSendUserRegisterLink: true,
                _shouldEmailUser: true,
                _defaultPassword: '',
                _confirmDefaultPassword: ''
            }
        }
    },

    handleDialogAction: function(action) {
        this.props.commitAction({
            action: action,
            payload: this.state
        });
    },

    onDrop: function(files) {
        // dropzone on windows doesn't work with file type
        /*if (files[0].type != 'text/csv') {
            return this.setState({
                _error: 'Please upload a CSV file'
            })
        }*/

        this.setState({
            files: files
        });
    },

    onFormUpdate: function(updateObject, hasError, fieldKey) {

        var userUpload = _.extend(this.state._userUpload, updateObject);

        return new Promise((resolve, reject) => {
            this.setState({
                _userUpload: userUpload
            })
            resolve();
        });

    },

    onUploadClicked: function() {
        
        if (this.state.files.length === 0) {
            return this.setState({
                _error: 'Upload a CSV file.'
            })
        }

        this.setState({
            _error: null,
            _isSyncing: true
        }, function() {

            this.props.options.uploadCsv(this.state.files, this.state._userUpload, (progress) => {
                this.setState({
                    progress: progress
                })
            })
            .then(() => {
                this.handleDialogAction('done');
            })
            .catch((error) => {
                this.setState({
                    _error: error.response.data.message,
                    _isSyncing: false
                });
            });

        });
    },

    onCancelClicked: function() {
        this.handleDialogAction('cancel');
    },

    renderWidget: function() {

        if (this.state.files.length) {
            return (
                <AlertInfo type="success" text="CSV looks good" icon="check"/>
            );
        }

        return (
            <Dropzone 
                multiple={false} 
                onDrop={this.onDrop}
                >
                {({getRootProps, getInputProps}) => {
                    return (
                        <div {...getRootProps()} className="drop-zone">
                            <input {...getInputProps()}/>
                            {LP("assets", "dropUpload", true)}
                        </div>
                    )
                }}
            </Dropzone>
        );
    },

    renderError: function() {
        if (this.state._error) {
            return (
                <AlertInfo type="warning" text={this.state._error}/>
            );
        }
    },

    renderProgressBar: function() {
        if (this.state._isSyncing) {
            return (
                <ProgressBar value={this.state.progress}/>
            );
        }
    },

    render: function() {
        return (
            <div className="dialog default-dialog import-users-form">
                <DialogTitle title={this.props.title} className="dialog-title default-dialog-title"/>
                    <DialogBody body={this.props.body} className="dialog-body default-dialog-body"/>
                    <div className="form-field">
                        {this.renderWidget()}
                        <div className="form-field-help">
                            The CSV file must contain the following fields: "firstName", "lastName", "email" and "groupManagerId".
                        </div>
                    </div>
                    <Form
                        model={this.state._userUpload}
                        schema={this.props.options.schema}
                        onUpdate={this.onFormUpdate}
                        form={{
                            autoEnrollGroups: this.props.options.autoEnrollGroups,
                        }}
                    />
                    {this.renderError()}
                    <Loading text="Importing users" hasDataLoaded={!this.state._isSyncing}>
                        <div className="dialog-actions">
                            <Button disabled={this.state.files.length === 0 || this.state._isSyncing} text="UPLOAD USERS" type="primary" onClick={this.onUploadClicked}/>
                            <Button text="CANCEL" type="warning" onClick={this.onCancelClicked} disabled={this.state._isSyncing}/>
                        </div>
                    </Loading>
            </div>
        );
    }

});

export default MyAccountImportUsersDialog;
