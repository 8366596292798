import React, {useState} from 'react';
import classnames from 'classnames';

const Items = (props) => {
	var total = props.total;
	var autoFocusProp = props.autoFocus;
	var value = (props.hoveredItemNumber || props.value);

	return _.times(total, function(index) {

		var autoFocus = false;
		var icon = "star-empty";
		var type = ""

		if (index + 1 <= value) {
			icon = "star";
			type = "primary"
		}

		if (index === 0 && autoFocusProp) {
			autoFocus = true;
		}

		var input = <input
			type="radio"
			value={index + 1}
			name="rating"
			autoFocus={autoFocus}
			disabled={props.isDisabled}
			aria-hidden={props.isDisabled}
			onClick={() => {props.onHover(index + 1)}}
			onKeyDown={(event) => {props.handleRadioButtonKeyPress(event, index + 1)}}
			id={`rate${index + 1}`}
			key={`input${index + 1 }`}
		/>

		var labelIcon = <i className={`icon icon-${icon} ${type}`} />

		var label = <label
			className={`star-rating-label ${props.isDisabled ? 'is-disabled' : ''}`}
			htmlFor={`rate${index + 1}`}
			value={index + 1}
			aria-hidden={props.isDisabled}
			onClick={() => {props.onButtonClick(index + 1)}}
			onMouseEnter={() => {props.onHover(index + 1)}}
			onMouseLeave={() => {props.onHover(null)}}
			key={`label${index + 1 }`}
		>
			{labelIcon}
		</label>

		return [input, label];
	})
}

const StarRating = ({className, total, value, onChange, isDisabled, autoFocus}) => {

	const [hoveredItemNumber, setHoveredItemNumber] = useState(null);

	var getClassName = () => {
		var disabled = isDisabled ? 'is-disabled' : '';
		return classnames('star-rating', className, disabled)
	}

	var onHover = (number) => {
		if (isDisabled) {
			return;
		}
		setHoveredItemNumber(number);
	}

	var handleRadioButtonKeyPress = (e, number) => {
		if (e.keyCode == 32 || e.keyCode == 13) {
			onButtonClick(number);
		}
	}

	var onButtonClick = (itemNumber) => {
		onChange(itemNumber);
	}

	return (
		<div className={getClassName()}>
			<Items
				autoFocus={autoFocus}
				onHover={onHover}
				onButtonClick={onButtonClick}
				total={total}
				handleRadioButtonKeyPress={handleRadioButtonKeyPress}
				hoveredItemNumber={hoveredItemNumber}
				value={value}
				setHoveredItemNumber={setHoveredItemNumber} />
		</div>
	)

}

export default StarRating;