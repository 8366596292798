import React from 'react';

const SelectOptionsItem = ({onClick, value, text}) => {

	var onOptionClicked = () => {
		onClick(value);
	}

	return (
		<div className="select-options-item" onClick={onOptionClicked}>
			{text}
		</div>
	)
}

export default SelectOptionsItem;