import React from 'react';
import classnames from 'classnames';
import FlatButton from '../../buttons/components/flatButton';

const Content = (props) => {
    return _.find(props.items, {value: props.value}).component;
}

const Tabs = ({items, value, onChange}) => {

    var labels = _.map(items, (item) => {

        var nameOfClass = classnames('tabs-label', {
            'selected': (value === item.value)
        });

        return (
            <FlatButton
                key={item.label}
                className={nameOfClass}
                onClick={(event) => {
                    event.preventDefault();
                    onChange(item.value);
                }}
                text={item.label}
                icon={item.icon}
            />
        )
    })


    return (
        <div className="tabs">
            <div className="tabs-labels">
                <div className="tabs-labels-inner">
                    {labels}
                </div>
            </div>
            <div className="tabs-contents">
                {value && <Content items={items} value={value} /> || console.warn('A value needs to be passed into a tabs component')}
            </div>
        </div>
    )
}

export default Tabs;