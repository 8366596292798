import React from 'react';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import {registerType} from '../helpers';
import Form from '../components/form.js';

var FormFieldObject = createReactClass({

    renderFormFields: function() {
        var value = (this.props.value || {});

        return (
            <Form
                onUpdate={this.onUpdateField}
                schema={this.props.subSchema}
                model={value}
                form={this.props.form}
            />
        );

    },

    onUpdateField: function(attributes, hasError, fieldKey) {
        var value = (this.props.value || {}); 
        var newValue = _.extend(value, attributes);
        return this.props.updateField(newValue, hasError);
    },

    render: function() {
        
        return (


            <div className="form-field form-field-object">

                <div className="form-field-label">
                    {this.props.label}
                </div>

                <div className="form-field-help">
                    {this.props.help}
                </div>

                {this.renderFormFields()}

                <div className="form-field-error">
                    {this.props.error}
                </div>

            </div>

        );

    }

});

registerType('Object', FormFieldObject);

export default FormFieldObject;