import React from 'react';
import createReactClass from 'create-react-class';
import {DialogTitle, DialogBody, DialogActions} from './dialogComponents.js';

const DefaultDialog = createReactClass({

	handleDialogAction: function(action) {
		this.props.commitAction({
			action: action,
			payload: null
		});
	},

	render: function() {
		return (
			<div className="dialog default-dialog" aria-modal role="dialog" tabIndex="0" aria-label={this.props.title}>
				<DialogTitle title={this.props.title} className="dialog-title default-dialog-title"/>
				<DialogBody body={this.props.body} className="dialog-body default-dialog-body"/>
				<DialogActions
					onDialogAction={this.handleDialogAction}
					actions={this.props.actions} 
					className="default-dialog-actions"/>
			</div>
		);
	}

});

export default DefaultDialog;