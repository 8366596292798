import React from 'react';
import createReactClass from 'create-react-class';
import LP from 'modules/app/helpers/lp';

var LoginOAuth = createReactClass({

    renderGoogleOAuth: function() { 
        if (authentication._shouldAllowGoogleOAuth) {
            return (
                <div className="login-oauth-item">
                    <a className="login-oauth-button secondary-button login-oauth-google" onClick={this.props.onGoogleOAuthClicked}>
                        <img src={`${window.location.origin}/images/sso-google-logo.png`} className="login-oauth-button-icon-image"/>
                        <span className="login-oauth-button-text">
                            {LP('login', 'signInWith')} Google
                        </span>
                    </a>
                </div>
            );
        }
    },

    renderLinkedInOAuth: function() {
        if (authentication._shouldAllowLinkedInOAuth) {
            return (
                <div className="login-oauth-item">
                    <a className="login-oauth-button login-oauth-linkedin" onClick={this.props.onLinkedInOAuthClicked}>
                        <img src={`${window.location.origin}/images/sso-linkedin-logo.png`} className="login-oauth-button-icon-image"/>
                        <span className="login-oauth-button-text">
                            {LP('login', 'signInWith')} LinkedIn
                        </span>
                    </a>
                </div>
            );
        }
    },

    renderTwitterOAuth: function() {
        if (authentication._shouldAllowTwitterOAuth) {
            return (
                <div className="login-oauth-item">
                    <a className="login-oauth-button login-oauth-twitter" onClick={this.props.onTwitterOAuthClicked}>
                        <img src={`${window.location.origin}/images/sso-twitter-logo.png`} className="login-oauth-button-icon-image"/>
                        <span className="login-oauth-button-text">
                            {LP('login', 'signInWith')} Twitter
                        </span>
                    </a>
                </div>
            );
        }
    },

    renderFacebookOAuth: function() {
        if (authentication._shouldAllowFacebookOAuth) {
            return (
                <div className="login-oauth-item">
                    <a className="login-oauth-button login-oauth-facebook" onClick={this.props.onFacebookOAuthClicked}>
                        <img src={`${window.location.origin}/images/sso-facebook-logo.png`} className="login-oauth-button-icon-image"/>
                        <span className="login-oauth-button-text">
                            {LP('login', 'signInWith')} Facebook
                        </span>
                    </a>
                </div>
            );
        }
    },

    renderAzureOAuth: function() {
        if (authentication._shouldAllowAzureOAuth) {
            return (
                <div className="login-oauth-item">
                    <a className="login-oauth-button login-oauth-azure" onClick={this.props.onAzureOAuthClicked}>
                        <img src={`${window.location.origin}/images/sso-azure-logo.png`} className="login-oauth-button-icon-image"/>
                        <span className="login-oauth-button-text">
                            {LP('login', 'signInWith')} Azure
                        </span>
                    </a>
                </div>
            );
        }
    },

    render: function() {
        return (
            <div className="login-oauth">
                {this.renderGoogleOAuth()}
                {this.renderLinkedInOAuth()}
                {this.renderTwitterOAuth()}
                {this.renderFacebookOAuth()}
                {this.renderAzureOAuth()}
            </div>
        );
    }

});

export default LoginOAuth;