import React from 'react';
import SelectOptions from '../../selectOptions/components/selectOptions';
 
const SearchFilter = ({shouldFocusInput, onChange, filters, onFilterChanged, filter, placeholder, value}) => {

    var onSelectOptionsChange = (event) => {
        onChange(event.target.value);
    };

    return (
        <div className="search-filter">
            <SelectOptions
                options={filters}
                onChange={onFilterChanged}
                value={filter}
                className="search-filter-options"
            />
            <input
                className="search-filter-input"
                placeholder={placeholder}
                onChange={onSelectOptionsChange}
                value={value}
                autoFocus={shouldFocusInput}
            />
        </div>
    )
};

export default SearchFilter;