import React from 'react';
import createReactClass from 'create-react-class';
import {Button} from 'uiKit';
import {registerType} from '../helpers';
var Field = require('../components/field.jsx');

var FormFieldButton = createReactClass({

    onButtonClicked: function(event) {
        event.preventDefault();
        if (this.props.form && this.props.form.actions[this.props.buttonAction]) {
            this.props.form.actions[this.props.buttonAction]();
        } else {
            console.warn("Seems as though a button action is missing or doesn't match the schema")
        }
    },

    renderField: function({ ariaDescribedBy }) {
        return (
            <Button
                id={this.props.fieldId}
                aria-describedby={ariaDescribedBy} 
                onClick={this.onButtonClicked} 
                icon={this.props.buttonIcon} 
                type={this.props.buttonType} 
                text={this.props.buttonText}/>
        )
    },

    render: function() {
        return (
            <Field
                fieldId={this.props.fieldId} 
                label={this.props.label}
                help={this.props.help}
                error={this.props.error}>
                {this.renderField}
            </Field>
        );
    }

});

registerType('Button', FormFieldButton);

export default FormFieldButton;