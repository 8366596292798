import React from 'react';
import createReactClass from 'create-react-class';
import {FlatButton} from 'uiKit';
import Cookies from 'js-cookie';
import LP from 'modules/app/helpers/lp';

var CookieConsentBar = createReactClass({

    cookieConsentActionClicked: function() {
        Cookies.set('bloom_platform_has_cookie_consent', true, {expires: 365});

        this.forceUpdate();
    },

    renderComponent: function() {
        return (
            <div className="cookie-consent clearfix">
                <span className="cookie-consent-text" dangerouslySetInnerHTML={{__html: LP('global', 'cookieConsent', 'sentencecase')}}> 
                </span>
                <div className="cookie-consent-actions">                            
                    <FlatButton                                
                        icon="cross-circle"
                        type="primary"
                        onClick={this.cookieConsentActionClicked}
                    />
                </div>
            </div>
        );
    },

	render: function() {
        var hasSubmittedCookieConcent = Cookies.get('bloom_platform_has_cookie_consent');
        var generalSettings = window.generalSettings; 

        if (generalSettings._shouldShowCookieConsent) {
            if (!hasSubmittedCookieConcent || hasSubmittedCookieConcent === "false") {
                Cookies.set('bloom_platform_has_cookie_consent', false);	
                return this.renderComponent();
            } else {
                return null
            }
        } else {
            return null
        }
	}
});

export default CookieConsentBar;