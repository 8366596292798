import React from 'react';
import createReactClass from 'create-react-class';
import {DialogTitle, DialogBody} from 'modules/notifications/components/dialogComponents.js';
import Dropzone from 'react-dropzone';
import LP from 'modules/app/helpers/lp';
import AlertInfo from 'modules/app/components/alertInfo';
import {Button} from 'uiKit';
import Bluebird from 'bluebird';


var ImportLanguagePackForm = createReactClass({

    getInitialState: function() {
        return {
            _isSyncing: false,
            _error: null, 
            progress: 0,
            files: []
        }
    },

    handleDialogAction: function(action, payload) {
        this.props.commitAction({
            action: action,
            payload: payload
        });
    },

    onDrop: function(files) {
        this.setState({
            files: files
        });
    },

    onFormUpdate: function(updateObject, hasError, fieldKey) {

        var userUpload = _.extend(this.state._userUpload, updateObject);

        return new Bluebird((resolve, reject) => {
            this.setState({
                _userUpload: userUpload
            })
            resolve();
        });

    },

    onUploadClicked: function() {
        
        if (this.state.files.length === 0) {
            return this.setState({
                _error: 'Upload a CSV file.'
            })
        }

        this.setState({
            _error: null,
            _isSyncing: true
        }, function() {

            this.props.options.importLanguagePack(this.state.files)
            .then((response) => {
                this.handleDialogAction('done', response.data._languagePack);
            })
            .catch((error) => {
                this.setState({
                    _error: error.response.data.message,
                    _isSyncing: false
                });
            });

        });
    },

    onCancelClicked: function() {
        this.handleDialogAction('cancel');
    },

    renderWidget: function() {

        if (this.state.files.length) {
            return (
                <AlertInfo type="success" text="Zip looks good" icon="check"/>
            );
        }

        return (
            <Dropzone 
                multiple={false} 
                onDrop={this.onDrop}
            >
                {({getRootProps, getInputProps}) => {
                    return (
                        <div {...getRootProps()} className="drop-zone">
                            <input {...getInputProps()}/>
                            {LP("assets", "dropUpload", true)}
                        </div>
                    )
                }}
            </Dropzone>
        );
    },

    renderError: function() {
        if (this.state._error) {
            return (
                <AlertInfo type="warning" text={this.state._error}/>
            );
        }
    },

    render: function() {
        return (
            <div className="dialog default-dialog import-users-form">
                <DialogTitle title={this.props.title} className="dialog-title default-dialog-title"/>
                <DialogBody body={this.props.body} className="dialog-body default-dialog-body"/>
                <div className="form-field">
                    {this.renderWidget()}
                    <div className="form-field-help">
                        Select or drop a ZIP file that contains the language pack data. The ZIP must contain a file called language-pack.json.
                    </div>
                </div>
                {this.renderError()}
                <div className="dialog-actions">
                    <Button disabled={this.state.files.length === 0 || this.state._isSyncing} text="Import" type="primary" onClick={this.onUploadClicked}/>
                    <Button text="CANCEL" type="warning" onClick={this.onCancelClicked} disabled={this.state._isSyncing}/>
                </div>
            </div>
        );
    }

});

export default ImportLanguagePackForm;