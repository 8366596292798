import React from 'react';
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import Velocity from 'velocity-animate';
import dialogTypes from './dialogTypes';
import FocusLock from 'react-focus-lock';

const Dialog = createReactClass({
	
	componentWillAppear: function(callback) {
		this.animateIn(callback);
	},

	componentWillEnter: function(callback) {
		this.animateIn(callback);
	},

	componentWillLeave: function(callback) {
		this.animateOut(callback);
	},
	
	animateIn: function(callback) {
		var element = ReactDOM.findDOMNode(this);
		Velocity(element, {
			translateY: [0, "10%"],
			opacity: 1
		}, {
			duration: 300,
			ease: 'ease-in',
			complete: callback
		});
	},

	animateOut: function(callback) {
		var element = ReactDOM.findDOMNode(this);
		Velocity(element, {
			translateY: "10%",
			opacity: 0
		}, {
			duration: 300,
			ease: 'ease-out',
			complete: callback
		});
	},
	
	renderDialog: function() {
		if (this.props.customDialog) {
			var CustomDialogComponent = this.props.customDialog;
			return (
				<CustomDialogComponent
					title={this.props.title}
					body={this.props.body}
					actions={this.props.actions}
					options={this.props.options}
					commitAction={this.props.commitAction}
					handleChange={this.props.handleChange}
					authenticationToken={this.props.authenticationToken}
					onClick={this.props.onClick}
					error={this.props.error}
				/>
			);
		}

		var DialogComponent = dialogTypes[this.props.dialogType];

		if (!DialogComponent) {
			return;
		}

		return (
			<DialogComponent 
				title={this.props.title}
				body={this.props.body}
				actions={this.props.actions}
				options={this.props.options}
				commitAction={this.props.commitAction}
			/>
		);
	},
	
	render: function() {
		return (
			<div className="dialog-container">
				<FocusLock returnFocus={true}>
					{this.renderDialog()}
				</FocusLock>
			</div>
		);
	}
	
});

export default Dialog;