import axios from 'axios';
const FETCH_USERS = 'myKinderlyUsers/FETCH_USERS';
const FETCH_GROUPS = 'myKinderlyUsers/FETCH_GROUPS';
const ADMIN_ADD_USER_TO_SUBSCRIPTION_GROUP = 'myKinderlyUsers/ADMIN_ADD_USER_TO_SUBSCRIPTION_GROUP';
const FETCH_USER_NAME_BY_CHARGEBEE_USER_ID = 'myKinderlyUsers/FETCH_USER_NAME_BY_CHARGEBEE_USER_ID';
const UPLOAD_CSV_SUBSCRIPTION_GROUP = 'UPLOAD_CSV_SUBSCRIPTION_GROUP';

export default function reducer(state = {
    _users: {},
    _groups: {}
}, action) {
    
    switch(action.type) {
        case FETCH_USERS:
            return action.payload;
            //return _.mapKeys(action.payload._users, '_id');
        case FETCH_GROUPS:
            return action.payload;
            //return _.mapKeys(action.payload._users, '_id');
        default:
        	return state;
    }
}

export function fetchUsers(searchValue, currentPage, usersInTrial) {

    return (dispatch) => {

        return axios.get('api/myKinderlyUsers', {
            params: {
                searchValue: searchValue,
                currentPage: currentPage,
                usersInTrial: usersInTrial
            }
        })
            .then(function(response) {

                dispatch({
                    type: FETCH_USERS,
                    payload: response.data
                });

                return Promise.resolve(response);

            });

    }

}

export function fetchGroups(/* searchValue, currentPage, usersInTrial */) {

    return (dispatch) => {

        return axios.get('api/myKinderlyGroups', {
            params: {}
        })
            .then(function(response) {

                dispatch({
                    type: FETCH_GROUPS,
                    payload: response.data
                });

                return Promise.resolve(response);

            });

    }

}

export function adminAddUserToSubscriptionGroup(data) {

    return (dispatch) => {

        return axios.put('api/myKinderlyUsers/adminAddUserToSubscriptionGroup', data)
        .then(function(response) {
            
            dispatch({
                type: ADMIN_ADD_USER_TO_SUBSCRIPTION_GROUP,
                payload: response.data
            });
            
            // return response.data;
            return Promise.resolve(response);

        });

    }  

}

export function fetchUserNameByChargebeeUserId(data) {

    return (dispatch) => {

        return axios.get('api/myKinderlyUsers/fetchUserNameByChargebeeUserId', {
            params: {
                data: data
            }
        })
            .then(function(response) {

                dispatch({
                    type: FETCH_USER_NAME_BY_CHARGEBEE_USER_ID,
                    payload: response.data
                });

                return Promise.resolve(response);

            });

    }

}

export function uploadCsvSubscriptionGroup(files, options, progressCallback) {
    var data = new FormData();
    data.append('uploads', files[0]);
    data.append('options', JSON.stringify(options));

    var config = {
        progress: function(progressEvent) {
            var percentCompleted = progressEvent.loaded / progressEvent.total * 100;
            progressCallback(percentCompleted);
        }
    };

    return (dispatch) => {

        return axios.post('api/myKinderlyUsers/uploadCsvSubscriptionGroup', data, config)
        .then(function(response) {

            dispatch({
                type: UPLOAD_CSV_SUBSCRIPTION_GROUP,
                payload: response.data
            });
            return response.data;

        });

    }
}

// export function fetchUsers(searchValue, currentPage) {
//
//     return (dispatch) => {
//
//         return axios.get('api/users', {
//             params: {
//                 searchValue: searchValue,
//                 currentPage: currentPage
//             }
//         })
//             .then(function(response) {
//
//                 dispatch({
//                     type: FETCH_USERS,
//                     payload: response.data
//                 });
//
//                 return Promise.resolve(response);
//
//             });
//
//     }
//
// }
