import React from 'react';
import createReactClass from 'create-react-class';
import {DialogTitle, DialogBody, DialogActions} from 'modules/notifications/components/dialogComponents';
import {Form} from 'modules/form';

import {connect} from 'react-redux';
import {
    fetchUserNameByChargebeeUserId
} from '../duxUsers';

var UserDetailsDialog = createReactClass({

    getInitialState: function() {
        return {
            manager_chargebee_user_id: '',
            user_chargebee_user_id: '',
            hasError: false,
            managerLine: '',
            memberLine: ''
        }
    },

    componentDidUpdate(prevProps, prevState) {
        if (this.state.manager_chargebee_user_id !== prevState.manager_chargebee_user_id) {

            this.props.fetchUserNameByChargebeeUserId({manager_chargebee_user_id: this.state.manager_chargebee_user_id})
            .then(response => {
                if ( response.data.manager ) {
                    this.setState({
                        managerLine: response.data.manager.firstName + ' ' + response.data.manager.lastName
                    });
                }
            });
        }

        if (this.state.user_chargebee_user_id !== prevState.user_chargebee_user_id) {

            this.props.fetchUserNameByChargebeeUserId({user_chargebee_user_id: this.state.user_chargebee_user_id})
            .then(response => {
                if ( response.data.member ) {
                    this.setState({
                        memberLine: response.data.member.firstName + ' ' + response.data.member.lastName
                    });
                }
            });
        }
    },
    
    handleDialogAction: function(action) {
        this.props.commitAction({
            action: action,
            payload: {
                manager_chargebee_user_id: this.state.manager_chargebee_user_id,
                user_chargebee_user_id: this.state.user_chargebee_user_id,
            }
        });
    },

    onUpdate: function(update, hasErrored, fieldKey) {
        var updateObject = hasErrored || {hasError: false};
        updateObject[fieldKey] = update[fieldKey];
        this.setState(updateObject);
        return Promise.resolve(update);
    },
    
    render: function() {

        return (
            <div className="dialog">
                <DialogTitle title={this.props.title}/>

                <p>Manager: {this.state.managerLine}</p>
                <p>Member: {this.state.memberLine}</p>

                <DialogBody body={this.props.body}/>
                <Form
                    schema={this.props.options.schema}
                    onUpdate={this.onUpdate}
                    model={this.state}
                />
                <DialogActions model={this.state} actions={this.props.actions} onDialogAction={this.handleDialogAction}/>
            </div>
        );

    }
    
})

// export default UserDetailsDialog;
export default connect(function(state, props) {
    return {
        
    }
}, {
    fetchUserNameByChargebeeUserId
})(UserDetailsDialog);
