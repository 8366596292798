import React from 'react';
import AccordionItem from './accordionItem';

const Accordion = (props) => {
	
	var items = _.map(props.items, (item, index) => {
		var iconSelected = props.icon;

		if (props.iconSelected) {
			iconSelected = props.iconSelected
		};
		
		return (
			<AccordionItem 
				item={item}
				id={index}
				icon={props.icon}
				iconSelected={iconSelected}
				onChange={props.onChange}
				key={item._id}
			/>
		);
	});

	return (
		<div className="accordion">
			{items}
		</div>
	);
}

export default Accordion;