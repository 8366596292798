import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import GuideElement from '../components/guideElement';
import {changeGuideStep, deactivateGuide} from '../actions/guidesActions';
import getCurrentGuide from '../helpers/getCurrentGuide';
import getNextGuideStep from '../helpers/getNextGuideStep';
import {withRouter} from 'react-router-dom';

var GuideElementContainer = createReactClass({

    onElementClicked: function() {
        var nextGuideStep = getNextGuideStep(this.props.guides._type, this.props.guides._step);
        if (!nextGuideStep) return this.props.deactivateGuide();
        if (nextGuideStep._location) {
            this.props.history.push(nextGuideStep._location);
        }
        this.props.changeGuideStep(nextGuideStep._step);
    },

    render: function() {
        return (
            <GuideElement
                {...this.props}
                onElementClicked={this.onElementClicked}
            />
        );
    }
})

export default withRouter(connect(function(state, props) {
    return {
        guides: state.guides
    }
}, {
    changeGuideStep,
    deactivateGuide
})(GuideElementContainer));