import {
    ACTIVATE_GUIDE,
    DEACTIVATE_GUIDE,
    CHANGE_GUIDE_STEP
} from '../actions/guidesActions';

export default function(state = {
    _isActive: false,
    _type: null,
    _step: null
}, action) {
    
    switch(action.type) {
        
        case ACTIVATE_GUIDE:     
            const {type, step} = action.payload;  
            return {...state, _isActive: true, _type: type, _step: step};

        case DEACTIVATE_GUIDE:
            return {...state, _isActive: false, _type: null, _step: null};

        case CHANGE_GUIDE_STEP:
            return {...state, _step: action.payload.step};

    }

    return state;

}