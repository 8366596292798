import React from 'react';
import createReactClass from 'create-react-class';
import classNames from 'classnames';
import {DialogTitle, DialogBody, DialogActions} from './dialogComponents.js';

const IconDialog = createReactClass({

	handleDialogAction: function(action) {
		this.props.commitAction({
			action: action,
			payload: null
		});
	},

	render: function() {      
        const iconClass = classNames('icon', this.props.options.icon.iconClass, 'icon-dialog-icon')
        var iconColor;
        
        if(this.props.options.icon.color) {
            iconColor = { color : this.props.options.icon.color}
        }
		return (
			<div className="dialog icon-dialog" aria-modal role="dialog" tabIndex="0" aria-label={this.props.title}>
				<div className="icon-dialog-icon-wrap">
                    <i className={iconClass} style={iconColor}></i>
                </div>
				<DialogTitle title={this.props.title} />
				<DialogBody body={this.props.body} />               
				<DialogActions
					onDialogAction={this.handleDialogAction}
					actions={this.props.actions}/>
			</div>
		);
	}

});

export default IconDialog;