import React from 'react';
import createReactClass from 'create-react-class';
import classNames from 'classnames';

const Folder = function({ className, onClick, value, icon, color, text }) {

    return (
        <button className={classNames('folder clearfix', className)} onClick={() => onClick(value)}>
            <div className="folder-icon">
                <i className={"icon icon-" + icon} style={{color: color}}></i>
            </div>
            <div className="folder-text">{text}</div>
        </button>
    );
}

export default Folder;