import React from 'react';
import classnames from 'classnames';

const CssBorderSelector = (props) => {

	var getClassName = () => {
		if (props.top && props.right && props.bottom && props.left) {
			return {
				top: 'button css-border-selector-button css-border-selector-button-top',
				right: 'button css-border-selector-button css-border-selector-button-right',
				bottom: 'button css-border-selector-button css-border-selector-button-bottom',
				left: 'button css-border-selector-button css-border-selector-button-left',
				centre: 'button css-border-selector-button css-border-selector-button-center primary'
			}
		}

		return {
			top: classnames({'button css-border-selector-button css-border-selector-button-top primary': (props.top)},
				{'button css-border-selector-button css-border-selector-button-top': (!props.top)}),

			right: classnames({'button css-border-selector-button css-border-selector-button-right primary': (props.right)},
				{'button css-border-selector-button css-border-selector-button-right': !props.right}),

			bottom: classnames({'button css-border-selector-button css-border-selector-button-bottom primary': (props.bottom)},
				{'button css-border-selector-button css-border-selector-button-bottom': !props.bottom}),

			left: classnames({'button css-border-selector-button css-border-selector-button-left primary': (props.left)},
				{'button css-border-selector-button css-border-selector-button-left': !props.left}),

			centre: classnames({'button css-border-selector-button css-border-selector-button-center': !props.centre}),
		}
	};

	var onTopButtonClicked = () => {
		event.preventDefault();
		if (props.top && props.right && props.bottom && props.left) {
			props.onChange(true, false, false, false);
		} else {
			props.onChange(!props.top, props.right, props.bottom, props.left);
		}
	};

	var onLeftButtonClicked = () => {
		event.preventDefault();
		if (props.top && props.right && props.bottom && props.left) {
			props.onChange(false, false, false, true);
		} else {
			props.onChange(props.top, props.right, props.bottom, !props.left);
		}
	};

	var onCentreButtonClicked = () => {
		event.preventDefault();
		if (props.top && props.right && props.bottom && props.left) {
			props.onChange(false, false, false, false);
		} else {
			props.onChange(true, true, true, true)
		}
	};

	var onRightButtonClicked = () => {
		event.preventDefault();
		if (props.top && props.right && props.bottom && props.left) {
			props.onChange(false, true, false, false);
		} else {
			props.onChange(props.top, !props.right, props.bottom, props.left);
		}
	};

	var onBottomButtonClicked = () => {
		event.preventDefault();
		if (props.top && props.right && props.bottom && props.left) {
			props.onChange(false, false, true, false);
		} else {
			props.onChange(props.top, props.right, !props.bottom, props.left);
		}
	};

	return (
		<div className="css-border-selector">
			<div className="css-border-selector-top">
				<button
					className={getClassName().top}
					onClick={() => onTopButtonClicked()}>
					<span className="css-border-selector-button-icon-top"></span>
				</button>
			</div>
			<div className="css-border-selector-middle">
				<button
					className={getClassName().left}
					onClick={() => onLeftButtonClicked()}>
					<span className="css-border-selector-button-icon-left"></span>
				</button>
				<button
					className={getClassName().centre}
					onClick={() => onCentreButtonClicked()}>
					<span className="css-border-selector-button-icon-center"></span>
				</button>
				<button
					className={getClassName().right}
					onClick={() => onRightButtonClicked()}>
					<span className="css-border-selector-button-icon-right"></span>
				</button>
			</div>
			<div className="css-border-selector-bottom">
				<button
					className={getClassName().bottom}
					onClick={() => onBottomButtonClicked()}>
					<span className="css-border-selector-button-icon-bottom"></span>
				</button>
			</div>
		</div>
	)
}

export default CssBorderSelector;