import React, {useState} from 'react';
import classNames from 'classnames';
import Button from '../../buttons/components/button';

const CssValue = (props) => {
    const [isShiftKeyDown, setIsShiftKeyDown] = useState(false);

    var nameOfClass = classNames('css-value clearfix', props.className, props.isSmall ? 'is-small' : '');

    var onInputKeyDown = (event) => {
        var keyCode = event.keyCode;

        if (keyCode === 16) {
            setIsShiftKeyDown(true);
        }

        if (isShiftKeyDown && keyCode === 38) {

            props.onIncrease(10);

        } else if (isShiftKeyDown && keyCode === 40) {

            props.onDecrease(10);

        } else if (keyCode === 38) {

            props.onIncrease(1);

        } else if (keyCode === 40) {

            props.onDecrease(1);

        }

    };

    var onInputKeyUp = (event) => {
        var keyCode = event.keyCode;

        if (keyCode === 16) {
            setIsShiftKeyDown(false);
        }

    };

    var onChange = (event) => {
        props.onChange(event.target.value);
    };

    var onIncrease = (event) => {
        event.preventDefault();
        props.onIncrease(1);
    };

    var onDecrease = (event) => {
        event.preventDefault();
        props.onDecrease(1);
    };

    return (
        <div className={nameOfClass}>
            <input
                className="css-value-input"
                value={props.value}
                onChange={onChange}
                onKeyDown={() => onInputKeyDown(event)}
                onKeyUp={() => onInputKeyUp(event)} />
            <div className="css-value-unit">
                {props.unit}
            </div>
            <div className="css-value-buttons">
                <Button
                    onClick={() => onIncrease(event)}
                    icon="chevron-up"
                    type="primary"
                    isSmall={props.isSmall}
                />
                <Button
                    onClick={() => onDecrease(event)}
                    icon="chevron-down"
                    type="primary"
                    isSmall={props.isSmall}
                />
            </div>
        </div>
    );
}

export default CssValue;