import React from 'react';
import createReactClass from 'create-react-class';
import Button from '../../buttons/components/button';
import FlatButton from '../../buttons/components/flatButton';

const DetailTileItemImage = function(props) {
	return (
		<div className="detail-tile-item-image">
			<img src={props.image}/>
			<Button 
				icon={props.icon}
				className="detail-tile-item-image-button"
				onClick={props.onClick}/>
		</div>
	);
}

const DetailTileItemContent = function(props) {
	
	return (
		<div className="detail-tile-item-details">
			<div className="detail-tile-item-content-title">
				{props.title}
			</div>
			<div className="detail-tile-item-content-description">
				{props.description}
			</div>
			<div className="detail-tile-item-actions">
				<div className="detail-tile-item-actions-like">
					<FlatButton 
						text="Love"
						icon="heart"
						onClick={() => props.onLikeClicked(!props.hasBeenLiked)}
					/>
				</div>	
				<div className="detail-tile-item-actions-bookmark">
					<FlatButton 
						icon="bookmark2"
						onClick={() => props.onBookmarkClicked(!props.hasBeenBookmarked)}
					/>
				</div>
			</div>
			<div className="detail-tile-item-continue-button">	
				<Button
					icon={props.actionButtonIcon && props.actionButtonIcon} 
					type="secondary"
					text={props.actionButtonText}
					onClick={props.onActionButtonClicked}
				/>
			</div>
		</div>
	);
}

const DetailTileItem = function(props) {

	return (
		<div className="detail-tile-item">
			<div className="detail-tile-item-inner clearfix"> 
				<DetailTileItemImage {...props} />
				<DetailTileItemContent {...props} />
			</div>
		</div>
	);
}

export default DetailTileItem;