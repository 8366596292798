import React, {useState} from 'react';
import SearchBarInput from './searchBarInput';
import SearchBarResults from './searchBarResults';
import classnames from 'classnames';
import FocusLock from 'react-focus-lock';
import {FlatButton} from 'uiKit';
import LP from 'modules/app/helpers/lp';

function SearchBar({
    activeTab,
    onTabChanged,
    onSearchBarInputChanged,
    searchValue,
    shouldDisplayResults,
    isAdmin,
    hasSearchOpen,
    shouldAlwaysBeVisible,
    navigationHeight,
    searchStyles,
    searchResults,
    isSyncing,
    onResultClicked,
    filters,
    onSuggestionClicked,
    onCloseButtonClicked,
    isSmall,
    updateFilter,
    librarySettings,
    count,
    placeholders,
    recentSearches,
    onRecentSearchResultChipClicked
}) {

    const renderShadow = function() {
        if (searchValue.length > 2 || !shouldAlwaysBeVisible) {
            return (
                <div className="search-shadow" />
            );
        }
    }

    const getClassName = function() {
        return classnames('search', {
            'is-open': getIsSearchOpen(),
            'is-small': isSmall
        })
    }

    const getIsSearchOpen = function() {
        return isSmall || shouldAlwaysBeVisible || hasSearchOpen;
    }

    const getIsFocusLockDisabled = function() {
        if (shouldAlwaysBeVisible) {
            return searchValue.length <= 2
        }
        return !hasSearchOpen;
    }

    const getWidgetInnerStyles = function() {
        if (!shouldAlwaysBeVisible) {
            return {
                paddingTop: ((navigationHeight - 40) / 2) + 'px',
                paddingBottom: ((navigationHeight - 40) / 2) + 'px'
            }
        }
    }

    const getSearchBarResultsHeight = function() {
        if (!getIsSearchOpen() || searchValue.length < 3) {
            return 0;
        }

        if (shouldAlwaysBeVisible) {
            return window.innerHeight - generalSettings._navigationHeight - 40;
        }

        return window.innerHeight - generalSettings._navigationHeight;
    }

    return (
        <div className={getClassName()} aria-hidden={!getIsSearchOpen()}>
            {!isSmall && renderShadow()}
            <FocusLock disabled={getIsFocusLockDisabled()}>
                <div className="search-widget" style={searchStyles} role="search">

                    <div className="search-widget-inner" hidden={!getIsSearchOpen()} style={getWidgetInnerStyles()}>
                        {getIsSearchOpen() &&
                            <SearchBarInput
                                shouldAlwaysBeVisible={shouldAlwaysBeVisible}
                                isSmall={isSmall}
                                placeholders={placeholders}
                                searchValue={searchValue}
                                onSearchBarInputChanged={onSearchBarInputChanged}
                                isSyncing={isSyncing}
                            />}
                        <FlatButton
                            aria-label={LP('search', 'searchCloseButtonAriaLabel')}
                            hidden={!hasSearchOpen && !shouldAlwaysBeVisible}
                            onClick={onCloseButtonClicked}
                            className="search-close-button"
                            icon="cross"
                        />
                    </div>

                    <div
                        style={{height: getSearchBarResultsHeight(), display: `${isSmall ? 'none' : 'flex'}`}}
                        className={classnames("search-bar-results", (!getIsSearchOpen() || searchValue.length < 3) ? "" : "is-active")}
                    >
                        {((getIsSearchOpen() && searchValue.length > 2) && !isSmall) &&
                            <SearchBarResults
                                recentSearches={recentSearches}
                                onRecentSearchResultChipClicked={onRecentSearchResultChipClicked}
                                count={count}
                                shouldDisplayResults={shouldDisplayResults}
                                searchValue={searchValue}
                                searchResults={searchResults}
                                activeTab={activeTab}
                                onTabChanged={onTabChanged}
                                isAdmin={isAdmin}
                                onResultClicked={onResultClicked}
                                filters={filters}
                                onSuggestionClicked={onSuggestionClicked}
                                isSyncing={isSyncing}
                                updateFilter={updateFilter}
                                librarySettings={librarySettings}
                            />
                        }
                    </div>
                </div>
            </FocusLock>
        </div>
    );
}

export default SearchBar;
