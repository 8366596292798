var changeSubscriptionGroupName = {
    "group_name": {
        "type":"Text",
        "label": "New Group Name",
        "validators": [],
        "conditions": [],
        "help": ""
    }
}

module.exports = changeSubscriptionGroupName;
