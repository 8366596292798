import {registerValidator} from '../helpers';
import validator from 'validator';

registerValidator('isValidEmail', function(model, attribute, value) {
   
    if (validator.isEmail(value)) {
        return false;
    }

    return 'A valid email is required';

});