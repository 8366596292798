import React from 'react';
import createReactClass from 'create-react-class';
import {Alert} from 'uiKit';

var MyAccountOverviewAlert = createReactClass({

    render: function() {
        
        var alert = null;

        if (this.props.customer.card_status === 'no_card' && this.props.subscription.status === 'in_trial') {
            alert = "You've not setup a payment method.";
        }

        if (this.props.customer.card_status === 'no_card' && this.props.subscription.status === 'active') {
            alert = "You've not setup a valid payment method.";
        }

        if (this.props.isUserBasic || this.props.isAnotherSubscription === true) {
            alert = false;
        }

        if (alert) {
            return (
                <div className="my-account-overview-alert">
                    <div className="alert-info warning">
                        <div className="alert-info-inner">
                            <i className="icon icon-warning"/>
                            <span>{alert}</span>
                        </div>
                    </div>
                </div>
            );
        }

        return null;

    }

});

export default MyAccountOverviewAlert;
