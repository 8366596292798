import React from 'react';
import createReactClass from 'create-react-class';
import classnames from 'classnames';
import {FlatButton} from 'uiKit';
import {registerType} from 'modules/form/helpers';


var FormFieldSubscriptionToggle = createReactClass({

    getInitialState: function() {
        return {
            value: this.props.value
        }
    },

    componentWillReceiveProps: function(nextProps) {
        if (nextProps.value != this.props.value) {
            this.setState({
                value: nextProps.value
            })
        }
    },

    getClassName: function(plan) {
        return classnames("subscription-toggle-field-standard", {
            'is-selected': (plan === this.state.value)
        })
    },

    onChange: function(event) {
        var value = event.target.value;
        value = parseInt(value);
        this.setState({value: value}, () => {
            this.props.updateField(parseInt(value));
        })
    },

    onStandardClicked: function(value) {
        this.setState({value: value}, () => {
            this.props.updateField(value);
        })
    },

    onTeamClicked: function(value) {
        this.setState({value: value}, () => {
            this.props.updateField(value);
        })
    },

    renderToggles: function() {
        return _.map(this.props.options, (option) => {
            return (
                <button
                    key={option.value}
                    className={this.getClassName(option.value)}
                    onClick={() => this.onStandardClicked(option.value)}>
                    <span className="subscription-toggle-field-text">{option.text}</span>
                    <span className="subscription-toggle-field-sub-text">{option.subText}</span>
                </button>
            );
        })
    },

    render: function() {
        return (
            <div className="form-field">

{/*                <div className="form-field-label">
                    {this.props.label}
                </div>*/}

                <div className="form-field-help">
                    {this.props.help}
                </div>

                <div className="subscription-toggle-field-container">
                    <div className="subscription-toggle-field">

                        {this.renderToggles()}
                        <div className="subscription-toggle-field-label">
                            {this.props.label}
                        </div>
                    </div>
                </div>

                <div className="form-field-error">
                    {this.props.error}
                </div>

            </div>
        );
    }

});

registerType('SubscriptionToggle', FormFieldSubscriptionToggle);
