import React from 'react';
import createReactClass from 'create-react-class';
import Form from '../components/form.jsx';
import { Button, FlatButton } from 'uiKit';

var FormFieldListToFormItems = createReactClass({

    componentDidUpdate: function(prevProps) {
        
        if (prevProps.shouldShowSubItem !== this.props.shouldShowSubItem) {
            this.refs.listItems.scrollTop = 0;
        }
    },

    renderItems: function() {

        if (this.props.shouldShowAddForm) return null;

        if (this.props.selectedItemId) return null;

        return _.map(this.props.value, (item) => {
            return (
                <div 
                    key={item._id}
                    className="form-field-list-to-form-item">
                    <button 
                        className="form-field-list-to-form-item-select-button"
                        onClick={() => this.props.onItemClicked(item._id)}>
                        {`${item[this.props.itemPrefixTextAttribute]} - ${item[this.props.itemTextAttribute]}`}
                    </button>
                    <FlatButton
                        icon="trash"
                        type="warning"
                        className="form-field-list-to-form-item-delete-button"
                        onClick={() => this.props.onDeleteItemClicked(item._id)}
                    />
                </div>
            )
        });

    },

    renderSubItems: function () {

        if (this.props.shouldShowAddForm) return null;

        if (!this.props.selectedItemId || this.props.selectedSubItemKey) return null;

        return _.map(this.props.subSchema, (subSchemaValue, subSchemaKey) => {
            return (
                <div
                    key={subSchemaKey}
                    className="form-field-list-to-form-item"
                >
                    <button
                        className="form-field-list-to-form-item-select-button"
                        onClick={() => this.props.onSubItemClicked(subSchemaKey)}>
                        {subSchemaValue.label}
                    </button>
                </div>
            )
        });

    },

    renderFormItems: function() {

        if (this.props.shouldShowAddForm) return null;

        var {
            selectedItemId,
            selectedSubItemKey,
            value,
            form,
            subSchema
        } = this.props;

        if (selectedItemId && selectedSubItemKey) {

            var currentItemValue = _.find(value, { _id: selectedItemId});

            var currentItemSchema = {}
            
            currentItemSchema[selectedSubItemKey] = subSchema[selectedSubItemKey];

            return (
                <div className="form-field-list-to-form-item-form">
                    <Form
                        key={this.props.selectedSubItemKey}
                        schema={currentItemSchema}
                        model={currentItemValue}
                        form={this.props.form}
                        onUpdate={this.props.onSubItemUpdated}
                        isNested={true}
                    />
                </div>
            );

        }

    },

    renderAddForm: function() {
        if (this.props.shouldShowAddForm) {


            return (
                <div className="form-field-list-to-form-item-form">
                    <Form
                        key={'addItem'}
                        schema={{addItem: {type: 'Object', label: 'Add Item', subSchema: this.props.addSchema}}}
                        model={this.props.addItem}
                        form={this.props.form}
                        onUpdate={this.props.onAddItemUpdated}
                    />
                    <Button
                        text={this.props.addButtonText}
                        type="primary"
                        onClick={this.props.onAddItemButtonClicked}
                        disabled={this.props.isSyncing}
                    />
                </div>
            );

        }
    },

    render: function() {
        return (
            <div className="form-field-list-to-form-items" ref="listItems">
                {this.renderItems()}
                {this.renderSubItems()}
                {this.renderFormItems()}
                {this.renderAddForm()}
            </div>
        );
    }
})

export default FormFieldListToFormItems;