var csvUpdloadSchema = {
    /* "_role": {
        "type":"Select",
        "label": "Role",
        "validators": [],
        "conditions": [],
        "help": "",
        "options": [{
            "value": "learner",
            "text": "Learners"
        }, {
            "value": "teacher",
            "text": "Teachers"
        }, {
            "value": "admin",
            "text": "Admins"
        }]
    }, */
    /* "_shouldSendUserRegisterLink": {
        "type": "Boolean",
        "label": "Send the user a register link?",
        "help": "This will ignore the Default Password below and send a user a registration link instead."
    }, */
    /* "_shouldAutoEnrollGroups": {
        "type": "AutoEnrollGroupsToggle",
        "label": "Should auto enroll groups"
    }, */

    /* "_defaultPassword": {
        "type":"Password",
        "label": "Default password",
        "validators": [],
        "conditions": [],
        "help": "This is used when your users are not sent a Register Link"
    },
    "_confirmDefaultPassword": {
        "type":"Password",
        "label": "Confirm default password",
        "validators": [],
        "conditions": [],
        "help": ""
    } */
}

module.exports = csvUpdloadSchema;
