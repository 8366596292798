import React from 'react';
import createReactClass from 'create-react-class';
import {FlatButton} from 'uiKit';
import {registerType} from 'modules/form/helpers';

var FormFieldSubscriptionQuantity = createReactClass({

    getInitialState: function() {
        return {
            value: this.props.value || 1
        }
    },

    componentWillReceiveProps: function(nextProps) {
        if (nextProps.value != this.props.value) {
            this.setState({
                value: nextProps.value
            })
        }
    },

    onChange: function(event) {
        var value = event.target.value;
        value = parseInt(value);
        this.setState({value: value}, () => {
            this.props.updateField(parseInt(value));
        })
    },

    onMinusClicked: function() {
        var value = this.state.value - 1;

        if (this.state.value === 1) return;

        this.setState({value: value}, () => {
            this.props.updateField(parseInt(value));
        })
    },

    onPlusClicked: function() {
        var value = this.state.value + 1;
        this.setState({value: value}, () => {
            this.props.updateField(parseInt(value));
        })
    },

    render: function() {
        return (
            <div className="form-field">

                <div className="form-field-help">
                    {this.props.help}
                </div>

                <div className="subscription-quantity-field-container">
                    <div className="subscription-quantity-field">

                        <FlatButton
                            className="subscription-quantity-field-minus"
                            icon="users-minus"
                            type="primary"
                            onClick={this.onMinusClicked}/>
                        <input 
                            className="subscription-quantity-field-input"
                            value={this.state.value} 
                            type="text" 
                            onChange={this.onChange}
                            disabled={true}/>
                        <div className="subscription-quantity-field-label">
                            {this.props.label}
                        </div>
                        <FlatButton 
                            className="subscription-quantity-field-plus"
                            icon="users-plus"
                            type="primary"
                            onClick={this.onPlusClicked}/>
                    </div>
                </div>

                <div className="form-field-error">
                    {this.props.error}
                </div>

            </div>
        );
    }

});

registerType('SubscriptionQuantity', FormFieldSubscriptionQuantity);
