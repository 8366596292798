import DatePicker from 'react-datepicker';
import React from 'react';
import createReactClass from 'create-react-class';
import moment from 'moment';
import {registerType} from 'modules/form/helpers';
import LP from 'modules/app/helpers/lp';

const FormFieldDateAndTimePicker = createReactClass({
    
    getInitialState: function() {
        var value = this.props.value ? moment(this.props.value) : moment().add(1, 'day');
        return {
            date: value,
            hour: value.hour(),
            minute: this.getNearestMinute(value.minute()),
            error: null,
        };
    },

    getDate: function() {
        if (this.state.date && this.state.date.toDate) {
            return this.state.date.toDate();
        } else {
            return this.state.date;
        }
    },

    getNearestMinute: function(minute) {
        if (minute === 0) {
            return 0;
        }

        if (minute <= 15) {
            return 15;
        }

        if (minute <= 30) {
            return 30;
        }

        if (minute <= 45) {
            return 45;
        }

        return 0;

    },

    updateField: function() {
        var datePicker = new Date(this.state.date);
        var year = datePicker.getFullYear();
        var month = datePicker.getMonth();
        var date = datePicker.getDate();
        var hour = this.state.hour;
        var minute = this.state.minute;

        var momentDate = moment().set({'year': year, 'month': month, 'date': date, 'hour': hour, 'minute':minute, 'second': 0, 'millisecond': 0});

        this.props.updateField(momentDate.toDate().toString());
    },

    onDatePickerChanged: function(date) {

        /*if(!date.isAfter()) {
            return this.setState({error: LP('deadline', 'dueDateInPastError', 'titlecase')});
        }*/

        this.setState({
            date: date,
            error: null
        }, () => this.updateField());
    },

    onHourChanged: function(event) {
        this.setState({
            hour: parseInt(event.currentTarget.value)
        }, () => this.updateField())
    },

    onMinuteChanged: function(event) {
        this.setState({
            minute: parseInt(event.currentTarget.value)
        }, () => this.updateField())
    },

    renderHourOptions: function() {
        return _.map(_.times(24, Number), function(number) {
            return (
                <option value={number}>
                    {number}
                </option>
            ); 
        });
    },

    renderMinuteOptions: function() {
        return _.map(_.times(4, Number), function(number) {
            return (
                <option value={number * 15}>
                    {number * 15}
                </option>
            ); 
        });
    },

    render: function() {
        return (
            <div className="form-field">

                <div className="form-field-label">
                    {this.props.label}
                </div>

                <div className="form-field-help">
                    {this.props.help}
                </div>

                <DatePicker 
                    dateFormat="dd/MM/yyyy"
                    selected={this.getDate()}
                    onChange={this.onDatePickerChanged}
                />

                <select value={this.state.hour} onChange={this.onHourChanged}>
                    {this.renderHourOptions()}
                </select>

                <select value={this.state.minute} onChange={this.onMinuteChanged}>
                    {this.renderMinuteOptions()}
                </select>

                <div className="form-field-error">
                    {this.state.error}
                </div>

            </div>
        )       
    }

});

export default FormFieldDateAndTimePicker;
registerType('DateAndTimePicker', FormFieldDateAndTimePicker);


