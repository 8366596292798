import React from 'react';
import classNames from 'classnames';
import LP from 'modules/app/helpers/lp';

const CloseButton = (props) => {
	return (
		<button className="tag-item-remove" aria-label={`${LP('global', 'remove')} ${LP('global', 'tag')}`} onClick={props.onClick}>
			<i className="icon icon-cross">
			</i>
		</button>
	)
}

const Tag = ({tagColor, className, text, hasCloseButton, onClick}) => {

	var getStyle = () => {
		if (tagColor) {
			return ({
				color: tagColor
			})
		}
	}

	var nameOfClass = classNames('tag-item', className);

	return (
		<div className={nameOfClass}>
			<i className="icon icon-tag tag-item-icon" style={getStyle()} />
			<p>
				{text}
			</p>
			{hasCloseButton && <CloseButton onClick={onClick} />}
		</div>
	);
}

export default Tag;