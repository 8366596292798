import Store from 'store';

export default function(role) {
    
    var usersRole = Store.getState().auth._role;
    var permissionLevels = {
        'superAdmin': 3,
        'admin': 2,
        'teacher': 1,
        'learner': 0
    }

    // If role starts with a ! then permissions are anything below permission level
    if (role[0] === '!') {
        role = role.substr(1);
        if (permissionLevels[usersRole] < permissionLevels[role]) {
            return true;
        } else {
            return false;
        }
    }

    if (permissionLevels[usersRole] >= permissionLevels[role]) {
        return true;
    } else {
        return false;
    }

}