import React from 'react';
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import {DialogTitle, DialogBody, DialogActions} from 'modules/notifications/components/dialogComponents.js';
import {ActionBar, Pagination, Loading} from 'uiKit';
import classNames from 'classnames';
import AlertInfo from 'modules/app/components/alertInfo';
import SearchBar from 'modules/search/components/searchBar';

/*
This dialog is used to display a paginated list of items that can be selected using a checkbox. It comes with a search bar and
pager. The dialog needs to be given the action to fetch the items and the state where the items are. This is why
the dialog needs to be used a custom dialog, as it needs to be wrapped in a react-redux to pass down the items and action.
The state must be passed to the dialog as the props 'items', and the action to fetch the data must be fetchData.

See the below dialog for a complete example

const Dialog = {

    title: "Select Users",
    body: "Select Users Body Text",
    actions: [
        {
            action: "cancel",
            text: "cancel",
            buttonType: 'primary'
        },
        {
            action: "done",
            text: "Done",
            buttonType: 'primary'
        }
    ],
    options: {
        selectedItems: [] <- This is if there are already selected items, must be an array of ids
        getItemLabelText: function(item) {
            return item.title
        },
        noItemsText: "No Users available"
    },
    customDialog: connect(state => {
        return {
            items : state.users 
        }
    }, {fetchData: fetchUsers})(PaginatedDialog),
}
*/

const PaginatedCheckDialog = createReactClass({

    getInitialState: function() {

        var selectedItems = [];

        if(this.props.options && Array.isArray(this.props.options.selectedItems)) {
            selectedItems = this.props.options.selectedItems
        };

        return {
            _searchValue: '',
            _currentPage: 1,
            _totalPages: 1,
            _selectedItems: selectedItems,
            _hasDataLoaded: false,
        }
    },

    componentDidMount: function() {
        this.fetchData(1);
    },

    fetchData: function(currentPage) {

        this.props.fetchData(this.state._searchValue, currentPage)
            .then(response => {
                this.setState({
                    _hasDataLoaded: true,
                    _currentPage: response.data._currentPage,
                    _totalPages: response.data._totalPages || 1
                })
            })
            .catch(response => {
                this.setState({
                    _hasErrored: true,
                    _statusCode: response.data._statusCode,
                    errorMessage: response.data.message
                });
            })
    },

    handleDialogAction: function(action) {
        this.props.commitAction({
            action: action,
            payload: this.state._selectedItems
        });
    },

    onSearchBarChanged: function(value) {
        this.setState({
            _searchValue: value
        }, ()=> {
            this.onSearchUpdated();
        });
    },

    onSearchUpdated: _.debounce(function () {
        this.fetchData(1);
    }, 600),

    onNextPageClicked: function() {
        this.fetchData(this.state._currentPage + 1);
    },

    onPreviousPageClicked: function() {
        this.fetchData(this.state._currentPage - 1);
    },

    onItemSelectChange: function(id) {

        var isItemSelected = false;
        var selectedItems = [];

        _.each(this.state._selectedItems, (selectedItem) => {

            if(!_.isEqual(id, selectedItem)) {
                return selectedItems.push(selectedItem);
            }
            isItemSelected = true;
        });

        if(!isItemSelected) {
            selectedItems = this.state._selectedItems.concat(id);
        }

        this.setState({_selectedItems: selectedItems});
    },

    renderItems: function() {

        if(Object.keys(this.props.items).length === 0) {
            return;
        };

        var items =  _.map(this.props.items, (item, key) => {

            var isDisabled = false;

            var labelText = this.props.options.getItemLabelText(item);

            var isChecked = !!_.find(this.state._selectedItems, (id) => {
                return _.isEqual(id, item._id);
            });
            
            var className = classNames('paginated-check-dialog-list-item dialog-option', {
                "is-selected" : isChecked
            });

            if (this.props.options.disableCheckboxOnSelectedItems) {
                if (_.includes(this.props.options.selectedItems, item._id)) {
                    isDisabled = true;
                }
            }

            return (
                <div key={key} className={className}>
                    <input 
                        id={key} 
                        type="checkbox" 
                        checked={isChecked}
                        disabled={isDisabled}
                        onChange={() => this.onItemSelectChange(item._id)}/>
                    <label
                        className="paginated-check-dialog-list-item-label" 
                        htmlFor={key}>
                        {labelText}
                    </label>
                </div>
            )
        });

        return (
            <div className="paginated-check-dialog-list">
                {items}
            </div>
        );
    },

    renderNoItemsMessage: function() {
        if(Object.keys(this.props.items).length === 0 && this.state._hasDataLoaded) {
            return (
                <div className="paginated-check-dialog-no-items">
                    <AlertInfo
                        text={this.props.options.noItemsText}
                        type="info"
                    />
                </div>
            )
        }
    },

    renderLoading: function() {
        if(!this.state._hasDataLoaded) {
            return (
                <Loading />
            )
        }
    },

    render: function() {
        return (
            <div className={classNames("dialog paginated-check-dialog", this.props.options.className)}>
                <ActionBar>
                    <div className="action-bar-left">
                        <SearchBar
                            isSmall
                            className="action-bar-item"
                            searchValue={this.state._searchValue}
                            onSearchBarInputChanged={this.onSearchBarChanged}
                        />
                    </div>
                    <div className="action-bar-center">
                        <Pagination
                            className="action-bar-item"
                            currentPage={this.state._currentPage}
                            totalPages={this.state._totalPages}
                            buttonType="secondary"
                            onPreviousPageClicked={this.onPreviousPageClicked}
                            onNextPageClicked={this.onNextPageClicked}
                        />
                    </div>
                    <div className="action-bar-right">
                    </div>
                </ActionBar>
                <DialogTitle title={this.props.title} className="dialog-title paginated-check-dialog-title"/>
                <DialogBody body={this.props.body} className="dialog-body paginated-check-dialog-body"/>
                <div className="paginated-check-dialog-content dialog-options-container">
                    {this.renderLoading()}
                    {this.renderItems()}
                    {this.renderNoItemsMessage()}
                </div>
                <DialogActions
                    onDialogAction={this.handleDialogAction}
                    actions={this.props.actions} 
                    className="paginated-check-dialog-actions"/>
            </div>
        );       
    }
});

export default PaginatedCheckDialog;


