import React from 'react';
import {Loading} from 'uiKit';
import DocumentTitle from 'react-document-title';

const PageLoader = function(props) {

    var getDocumentTitle = function() {
        return props.title || window.generalSettings._htmlTitle;
    }

    if (props.hasErrored) {
        if (props.statusCode === 401) {
            return (
                <DocumentTitle title={getDocumentTitle()}>
                    <div className="page-loader-error">
                        I'm sorry, looks like you don't have permissions to view this page
                    </div>
                </DocumentTitle>
            );
        }
        return (
            <DocumentTitle title={getDocumentTitle()}>
                <div className="page-loader-error">
                    {props.errorMessage}
                </div>
            </DocumentTitle>
        );
    }

    if (props.hasLoaded) {
        return (
            <DocumentTitle title={getDocumentTitle()}>
                <section
                    role={props.role || null}
                    aria-hidden={props['aria-hidden'] || null}
                >
                    {props.children}
                </section>
            </DocumentTitle>
        );
    }

    return (
        <DocumentTitle title={getDocumentTitle()}>
            <Loading text={props.text}/>
        </DocumentTitle>
    );
}

export default PageLoader;