import React from 'react';

const NarrativeContent = (props) => {
	return (
		<div className="narrative-content">
			<div className="narrative-content-inner">
				<div className="narrative-content-item">
					<div className="narrative-content-item-title">
						<div className="narrative-content-item-title-inner" dangerouslySetInnerHTML={{__html: props.item.title}}>
						</div>
					</div>
					<div className="narrative-content-item-body">
						<div className="narrative-content-item-body-inner" dangerouslySetInnerHTML={{__html: props.item.body}}>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default NarrativeContent;