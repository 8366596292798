export const OPEN_MENU = 'OPEN_MENU';
export const CLOSE_MENU = 'CLOSE_MENU';

export function openMenu() {

    return (dispatch) => {

        dispatch({
            type: OPEN_MENU
        });
            
    }

}

export function closeMenu() {

    return (dispatch) => {

        dispatch({
            type: CLOSE_MENU
        });
            
    }

}