import React from 'react';
import createReactClass from 'create-react-class';

var FormSortableItemHandle = createReactClass({

    render: function() {
        return (
            <span className="form-sortable-item-handle">
                <i className="icon icon-move"></i>
            </span>
        );
    }

});

export default FormSortableItemHandle;