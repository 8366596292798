import axios from 'axios';
import Bluebird from 'bluebird';


export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const FETCH_LANGUAGE_CODES = 'FETCH_LANGUAGE_CODES';
export const FETCH_LANGUAGE_PACKS = 'FETCH_LANGUAGE_PACKS';
export const EXPORT_LANGUAGE_PACK = 'EXPORT_LANGUAGE_PACK';
export const IMPORT_LANGUAGE_PACK = 'IMPORT_LANGUAGE_PACK';
export const TEST_EMAIL_CREDENTIALS = 'TEST_EMAIL_CREDENTIALS';
export const SEND_TEST_EMAIL = 'SEND_TEST_EMAIL';
export const TEST_EVOLVE_INSTANCE = 'TEST_EVOLVE_INSTANCE';
export const FETCH_INTEGRATION_KEYS = 'FETCH_INTEGRATION_KEYS';
export const TOGGLE_URL_SLUGS = 'TOGGLE_URL_SLUGS';
export const UPDATE_URL_SLUG = 'UPDATE_URL_SLUG';
export const UPDATE_CUSTOM_USER_ATTRIBUTES = 'UPDATE_CUSTOM_USER_ATTRIBUTES';
export const UPDATE_CUSTOM_USER_ATTRIBUTE_FIELD = 'UPDATE_CUSTOM_USER_ATTRIBUTE_FIELD';
export const RESET_ONBOARDING_ATTRIBUTE = 'RESET_ONBOARDING_ATTRIBUTE';

export function fetchSettings() {

    return (dispatch) => {

        return axios.get('api/settings')
        .then(function(response) {
            
            dispatch({
                type: FETCH_SETTINGS,
                payload: response.data
            });

            return response;

        });
            
    }

}

export function updateSettings(settingsModel) {
    return (dispatch) => {

        return axios.put('api/settings', settingsModel)
        .then(function(response) {
            
            dispatch({
                type: UPDATE_SETTINGS,
                payload: response.data
            })

            return response;

        });

    }
}

export function updateCustomUserAttributes(attributes) {
    return (dispatch) => {

        return axios.put('api/settings/customUserAttributes', attributes)
        .then(function(response) {
            
            dispatch({
                type: UPDATE_CUSTOM_USER_ATTRIBUTES,
                payload: response.data
            })

            return response;

        });

    }
}

export function updateCustomAttributeField(updateObject) {
    return (dispatch) => {
        return axios.put('api/settings/customUserAttributeField', updateObject)
        .then(function(response) {
            
            dispatch({
                type: UPDATE_CUSTOM_USER_ATTRIBUTE_FIELD,
                payload: response.data
            })

            return response;

        });
    }
}

export function fetchUserAttributes() {
    return (dispatch) => {

        return axios.get('api/settings/getUserAttributes')
        .then(response => {
            return Bluebird.resolve(response.data._attributes);
        });

    }
}

export function fetchLanguageCodes() {
    return (dispatch) => {

        return axios.get('api/settings/languageCodes')
        .then(response => {
            dispatch({
                type: FETCH_LANGUAGE_CODES,
                payload: response.data
            })

            return response;
        });

    }
}

export function fetchLanguagePacks() {
    return (dispatch) => {

        return axios.get('api/settings/languagePacks')
            .then(response => {
                dispatch({
                    type: FETCH_LANGUAGE_PACKS,
                    payload: response.data
                })

                return response;
            });

    }
}

export function exportLanguagePack(languagePackId) {
    return (dispatch) => {

        window.open(`api/settings/languagePacks/export/${languagePackId}`);

        return {            
            type: EXPORT_LANGUAGE_PACK,
            payload: response.data
        }
    }
}

export function importLanguagePack(files) {
    var data = new FormData();
    data.append('uploads', files[0]);

    return (dispatch) => {
        return axios.post('api/settings/languagePacks/import', data)
        .then((response) => {
            
            dispatch({
                type: IMPORT_LANGUAGE_PACK,
                payload: response.data
            });

            return response;
        }); 
    }
}

export function testEmailCredentials() {
    return (dispatch) => {

        return axios.get('api/settings/emails/test')
            .then(response => {
                dispatch({
                    type: TEST_EMAIL_CREDENTIALS,
                    payload: response.data
                })

                return response;
            });

    }
}

export function sendTestEmail(sendToEmail) {
    return (dispatch) => {
        return axios.post('api/settings/emails/sendTestEmail', { sendToEmail })
            .then(response => {
                dispatch({
                    type: SEND_TEST_EMAIL,
                    payload: response.data
                }) 
                
                return response;
            });
    }
}

export function testEvolveInstance(instanceId) {
    return (dispatch) => {

        return axios.get(`api/settings/evolve/${instanceId}/test`)
            .then(response => {
                dispatch({
                    type: TEST_EVOLVE_INSTANCE,
                    payload: response.data
                })

                return response;
            });

    }
}

export function fetchIntegrationKeys(instanceId) {
    return (dispatch) => {

        return axios.get(`api/settings/integrationKeys`)
            .then(response => {
                dispatch({
                    type: FETCH_INTEGRATION_KEYS,
                    payload: response.data
                })

                return response;
            });

    }
}

export function toggleUrlSlugs(shouldUseUrlSlugs) {
    return (dispatch) => {
        return axios.put('api/settings/toggleUrlSlugs', {
            shouldUseUrlSlugs
        })
        .then(response => {
            var generalSettings = _.get(response, 'data._settings._general');
            
            if (generalSettings) {
                window.generalSettings = generalSettings;
            }

            dispatch({
                type: TOGGLE_URL_SLUGS,
                payload: response.data
            })

            return response;
        });
    }
}

export function updateUrlSlug(id, type, urlSlug) {
    return (dispatch) => {
        return axios.put(`api/settings/urlSlug/${id}`, {
            type,
            urlSlug
        })
        .then(response => {

            dispatch({
                type: UPDATE_URL_SLUG,
                payload: response.data
            })

            return response;
        });
    }
}

export function resetOnboardingAttribute(attribute) {
    return (dispatch) => {
        return axios.post(`api/settings/resetOnboardingAttribute`, {
            attribute
        })
        .then(response => {

            dispatch({
                type: RESET_ONBOARDING_ATTRIBUTE,
                payload: response.data
            })

            return response;
        });
    }
}