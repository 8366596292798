import React, {useState, useEffect} from 'react';
import NarrativeGallery from './narrativeGallery';
import NarrativeIndicators from './narrativeIndicators';
import NarrativeContent from './narrativeContent';

const Narrative = (props) => {
	const [narrativeWidth, setNarrativeWidth] = useState(0);
	const [sliderWidth, setSliderWidth] = useState(0);


	useEffect(() => {
		var narrativeWidth = document.querySelector('#narrative').clientWidth;
		var sliderWidth = narrativeWidth * props.items.length;

		setNarrativeWidth(narrativeWidth);
		setSliderWidth(sliderWidth);
	}, []);

	var onRightControlClicked = () => {
		props.onChange(stage + 1);
	};

	var onLeftControlClicked = () => {
		props.onChange(stage - 1);
	};

	return (
		<div className="narrative" id="narrative">
			<NarrativeGallery
				narrativeWidth={narrativeWidth}
				sliderWidth={sliderWidth}
				{...props}
				onRightControlClicked={onRightControlClicked}
				onLeftControlClicked={onLeftControlClicked}
			/>
			<NarrativeIndicators
				stage={props.stage}
				length={props.items.length}
			/>
			{_.map(props.items, (item, index) => {
				if (index === props.stage) {
					return (
						<NarrativeContent
							item={item}
							key={index}
						/>
					)
				}
			})}
		</div>
	)

}

export default Narrative;