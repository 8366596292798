import React from 'react';
import Button from '../../buttons/components/button';
import classNames from 'classnames';
import LP from 'modules/app/helpers/lp';

const Pagination = ({
	currentPage, totalPages, className, isSmall, toolTipLeftButton,
	toolTipLeftButtonPosition, toolTipRightButton, toolTipRightButtonPosition,
	buttonType, onPreviousPageClicked, onNextPageClicked
}) => {

	var nameOfClass = classNames('pagination', 'clearfix', className, isSmall ? 'is-small' : '');

	return (
		<div className={nameOfClass}>
			<Button
				aria-label={`${LP('global', 'previous')} ${LP('global', 'page')}`}
				className="pagination-previous-page"
				type={buttonType}
				icon="arrow-left"
				disabled={(currentPage === 1)}
				onClick={onPreviousPageClicked}
				toolTip={toolTipLeftButton || null}
				toolTipPosition={toolTipLeftButtonPosition || null}
				isSmall={isSmall} />

			<div role="text" className="page-count" aria-label={`${LP('global', 'page')} ${currentPage} ${LP('global', 'of')} ${totalPages}`}>
				<p aria-hidden>{currentPage + '/' + totalPages}</p>
			</div>

			<Button
				aria-label={`${LP('global', 'next')} ${LP('global', 'page')}`}
				className="pagination-next-page"
				type={buttonType}
				icon='arrow-right'
				disabled={(currentPage === totalPages)}
				onClick={onNextPageClicked}
				toolTip={toolTipRightButton || null}
				toolTipPosition={toolTipRightButtonPosition || null}
				isSmall={isSmall} />
		</div>
	);
}

export default Pagination;
