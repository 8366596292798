import React from 'react';
import createReactClass from 'create-react-class';
import ChargeBee from '../chargebee';
import {Button} from 'uiKit';

var MyAccountHostedPageDialog = createReactClass({

    getInitialState: function() {
        return {
            height: window.innerHeight,
            show: true,
            shouldShowSuccess: false
        }
    },

    componentDidMount: function() {

        // TODO read from local config file?
        var chargebeeSite = 'nurserybook';

        if (window.isDevelopment) {
            chargebeeSite = 'nurserybook-test';
        }
        
        window.ChargeBee.embed(this.props.options.url, chargebeeSite).load({
            /*
            * This function will be called when iframe is created.
            * addIframe callback will recieve iframe as parameter.
            * you can use this iframe to add iframe to your page.
            * Loading image in container can also be showed in this callback.
            * Note: visiblity will be none for the iframe at this moment
            */
            addIframe: (iframe) => {
                this.refs.iframeContainer.append(iframe);
                var style= 'border:none;overflow:hidden;width:100%;';
                style = style + 'height:98%;';
                style = style + 'visiblity:visible;';
                iframe.setAttribute('style', style);
                iframe.setAttribute('scrolling', 'auto');
            },

            /*
            * This function will be called once when iframe is loaded.
            * Since checkout pages are responsive you need to handle only height.
            */        
            onLoad: (iframe, width, height) => {
                var style= 'border:none;overflow:hidden;width:100%;';                            
                style = style + 'height:' + height + 'px;';
                style = style + 'visiblity:visible;';
                iframe.setAttribute('style', style);
                iframe.setAttribute('scrolling', 'auto');
            },

            /*
            * This will be triggered when any content of iframe is resized.
            */        
            onResize: (iframe, width, height) => {
                var style = 'border:none;overflow:hidden;width:100%;';
                style = style + 'height:' + height + 'px;';
                iframe.setAttribute('style',style);
                iframe.setAttribute('scrolling', 'auto');
            },

            /*
            * This will be triggered when checkout is complete.
            */        
            onSuccess: (iframe) => {
                this.props.commitAction({
                    action: 'success',
                    payload: null
                });
            },

            /*
            * This will be triggered when user clicks on cancel button. 
            */
            onCancel: (iframe) => {
                this.props.commitAction({
                    action: 'cancel',
                    payload: null
                });
            },

            /* Temporary */
            onError: (iframe, width, height) => {
                console.log('onError');
                var style= 'border:none;overflow:hidden;width:100%;';
                style = style + 'height:' + height + 'px;';
                style = style + 'visiblity:visible;';
                iframe.setAttribute('style', style);
                iframe.setAttribute('scrolling', 'auto');
            },
        });

    },

    onCloseClicked: function() {
        this.props.commitAction({
            action: 'success',
            payload: null
        });
    },

    onBackClicked: function() {
        this.props.commitAction({
            action: 'success',
            payload: null
        });
    },

    onConfirmClicked: function() {
        this.props.commitAction({
            action: 'confirm',
            payload: null
        });
    },

    render: function() {

        var height = (this.state.height - 32);
        var iFrameHeight = height - 82;

        if( this.state.show ) {

            if(this.props.options.isUserBasic) {
                return (
                    <div className="dialog" style={{width: '600px', height: height + 'px', overflow: 'auto', maxHeight: '100vh'}}>
                        <div ref='iframeContainer' style={{height: iFrameHeight + 'px', overflowY: 'auto'}}>
                        </div>
                        <div className="dialog-actions">
                            <Button text="CANCEL" type="alert" onClick={this.onBackClicked}/>
                            <Button text="CONFIRM SUBSCRIPTION" type="primary" onClick={this.onConfirmClicked}/>
                        </div>
                    </div>
                );

            } else {
                return (
                    <div className="dialog" style={{width: '600px', height: height + 'px', overflow: 'auto', maxHeight: '100vh'}}>
                        <div ref='iframeContainer' style={{height: iFrameHeight + 'px', overflowY: 'auto'}}>
                        </div>
                        <Button text="Close" type="primary" onClick={this.onCloseClicked}/>
                    </div>
                );
            }
        }
    }

});

export default MyAccountHostedPageDialog;
