import React, { useState } from 'react';
import createReactClass from 'create-react-class';
import Button from './button';
import ButtonOptionItem from './buttonOptionItem';

const ButtonOptions = function(props) {
	
	const [isOpen, toggleDropDown] = useState(false);
	
	return (
		<div className={`button-options ${props.className}`}>
			<Button
				className="button-options-button" 
				type={props.type}
				onClick={props.onClick}
				text={props.text}
				isSmall={props.isSmall} 
			/>
			<Button
				className="button-options-selector"
				icon={(props.optionsPosition === "top") ? "chevron-up" : isOpen ? "chevron-up" : "chevron-down"}
				onClick={() => {
					toggleDropDown(!isOpen);
				}}
				type={props.type}
				isSmall={props.isSmall}
			/>
			{isOpen && 
				<div className="button-options-list">
					{_.map(props.options, (option, index) => {
						return (
							<ButtonOptionItem
                                icon={option.icon}
								text={option.title}
								body={option.body}
								key={index}
								value={option.value}
								onClick={() => {
									toggleDropDown();
									props.onChange(option.value);
								}}
							/>
						);
					})}
				</div>
			}
		</div>
	);
}

export default ButtonOptions;