import React from 'react';
import classNames from 'classnames';

const LoadingSpinner = function({text, className}) {
	return (
		<div className={classNames('loading', className)}>
			<div className="loading-inner">
				<div className="loading-icon">
					<div className="loading-icon-spinner">
					</div>
				</div>
				<p className="loading-text">
					{text}
				</p>
			</div>
		</div>
	);
}

const Loading = function({ hasDataLoaded, children, className, text }) {
	
	return hasDataLoaded ? children : <LoadingSpinner text={text} className={className} />
}

export default Loading;