import React from 'react';
import createReactClass from 'create-react-class';
import {registerType} from '../helpers';
import {getComponent, getComponents} from 'bloom';
import {SortableContainer, SortableElement, SortableHandle, arrayMove} from 'react-sortable-hoc';

const DragHandle = SortableHandle(() => <span className="form-field-component-items-list-item-handle"><i className="icon icon-move"></i></span>);

const SortableItem = SortableElement(({item, onItemClicked}) => {
    var icon = "icon";
    
    if (!item) return;
    if (item._isEnabled) {
        icon += " icon-check-square";
    } else {
        icon += " icon-square";
    }
    return (
        <li className="form-field-component-items-list-item">
            <DragHandle />
            <span className="form-field-component-items-list-item-name">{item.displayName}</span>
            <span className="form-field-component-items-list-item-check" onClick={() => onItemClicked(item)}><i className={icon}/></span>
        </li>
    );
});

const SortableList = SortableContainer(({items, onItemClicked}) => {
    return (
        <ul className="form-field-component-items-list">
            {items.map((item, index) =>
                <SortableItem key={`item-${index}`} index={index} item={item} onItemClicked={onItemClicked} />
            )}
        </ul>
    );
});

var FormFieldComponentItems = createReactClass({
    
    getInitialState: function() {
        return {
            items: this.getItems()
        }
    },

    getItems: function() {

        var savedComponents = _.map(this.props.value, (item) => {
            var component = getComponent(item.name, this.props.location);
            if (component) {
                component._isEnabled = item._isEnabled;
                return component;
            }
        });

        var components = getComponents(this.props.location);
        
        _.each(components, function(component) {
            if (!_.includes(savedComponents, component)) {
                component._isEnabled = false;
                savedComponents.push(component);
            }
        });

        // Remove any undefined ones
        var availableComponents = [];
        _.each(savedComponents, function(savedComponent) {
            if (savedComponent) {
                availableComponents.push(savedComponent);
            }
        });

        return availableComponents;

    },

    onSortEnd: function({oldIndex, newIndex}) {
        this.setState({
            items: arrayMove(this.state.items, oldIndex, newIndex)
        }, function() {
            this.saveItems();
        });
    },

    onItemClicked: function(item) {

        item._isEnabled = !item._isEnabled;
        this.saveItems();

    },

    saveItems: function() {
        var value = _.map(this.state.items, function(item) {

            return {
                name: item.name,
                _isEnabled: item._isEnabled
            }
        });

        return this.props.updateField(value);
    },

    render: function() {
        return (
            <div className="form-field clearfix">
                
                <div className="form-field-label">
                    {this.props.label}
                </div>

                <div className="form-field-help">
                    {this.props.help}
                </div>

                <SortableList 
                    items={this.state.items} 
                    onSortEnd={this.onSortEnd} 
                    useDragHandle={true}
                    onItemClicked={this.onItemClicked}/>

            </div>
        )
    }

});

registerType('ComponentItems', FormFieldComponentItems);

export default FormFieldComponentItems;