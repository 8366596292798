import React from 'react';
import Button from '../../buttons/components/button';
import FlatButton from '../../buttons/components/flatButton';

const Title = (props) => {
	return (
		<div className="tile-item-actions-title" onClick={props.onClick}>
			{props.title}
		</div>
	)
}

const Image = (props) => {
	return (
		<div className="tile-item-image">
			<img src={props.image} />
			<Button
				icon={props.icon}
				className="tile-item-image-button"
				onClick={props.onClick} />
		</div>
	)
}

const Like = (props) => {
	var onLikeClicked = () => {
		props.onLikeClicked(!props.hasBeenLiked)
	}

	return (
		<div className="tile-item-actions-like">
			<FlatButton
				text="Love"
				icon="heart"
				onClick={onLikeClicked}
			/>
		</div>
	)
}

const Bookmark = (props) => {
	var onBookmarkClicked = () => {
		props.onBookmarkClicked(!props.hasBeenBookmarked)
	}

	return (
		<div className="tile-item-actions-bookmark">
			<FlatButton
				icon="bookmark2"
				onClick={onBookmarkClicked}
			/>
		</div>
	)
}

const TileItem = ({title, onClick, image, icon, onLikeClicked, onBookmarkClicked, hasBeenBookmarked, hasBeenLiked}) => {

	return (
		<div className="tile-item">
			<Image
				image={image}
				icon={icon}
				onClick={onClick}
			/>
			<div className="tile-item-actions">
				<Title
					onClick={onClick}
					title={title}
				/>
				<Like onLikeClicked={onLikeClicked} hasBeenLiked={hasBeenLiked}/>
				<Bookmark onBookmarkClicked={onBookmarkClicked} hasBeenBookmarked={hasBeenBookmarked}/>
			</div>
		</div>
	);
}

export default TileItem;