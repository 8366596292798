import React from 'react';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import {SortableElement} from 'react-sortable-hoc';
import FormSortableItem from './formSortableItem.js';
var FormSortableItemSortable = SortableElement(FormSortableItem);


var FormSortableContainer = createReactClass({

    getItems: function() {
        return _.map(this.props.items, (item, index) => {
            var isSelected = false;
            if (this.props.selectedItemIndex === index) {
                isSelected = true;
            }
            return (
                <FormSortableItemSortable 
                    key={'item-' + index} 
                    index={index}
                    sortIndex={index} 
                    item={item}
                    itemTextAttribute={this.props.itemTextAttribute}
                    defaultPrefix={this.props.defaultPrefix}
                    onClick={this.props.onClick}
                    isSelected={isSelected}/>
            );
        });
    },

    render: function() {
        return (
            <ul className="form-sortable">
                {this.getItems()}
            </ul>
        );
    }

});

export default FormSortableContainer;