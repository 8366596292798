import React from 'react';
import createReactClass from 'create-react-class';
import classnames from 'classnames';

var MyAccountOverviewItem = createReactClass({

    getClassName: function() {
        return classnames("my-account-overview-item", {
            "has-icon": (this.props.icon)
        });
    },

    renderIcon: function() {
        if (this.props.icon) {
            return <i className={"icon icon-" + this.props.icon}/>
        }
    },

    render: function() {
        return (
            <div className={this.getClassName()}>
                {this.renderIcon()}
                <div className="my-account-overview-item-label">
                    {this.props.label}
                </div>
                <div className="my-account-overview-item-value">
                    {this.props.value}
                </div>
            </div>
        );
    }

});

export default MyAccountOverviewItem;
