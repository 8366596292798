import React from 'react';
import createReactClass from 'create-react-class';
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';
import FormSortableContainer from './formSortableContainer.jsx';
const FormSortableContainerSortable = SortableContainer(FormSortableContainer);

var FormSortable = createReactClass({

    getInitialState: function() {
        return {
            items: this.props.items
        }
    },

    componentWillReceiveProps: function(nextProps) {
        this.setState({
            items: nextProps.items
        })
    },

    onSortEnd: function(data) {
        this.setState({
            items: arrayMove(this.state.items, data.oldIndex, data.newIndex)
        }, function() {
            this.props.onSort(this.state.items, data);
        })
    },

    render: function() {
        return (
            <FormSortableContainerSortable
                onSortEnd={this.onSortEnd}
                onSortMove={this.onSortMove}
                onClick={this.props.onClick}
                items={this.state.items}
                itemTextAttribute={this.props.itemTextAttribute}
                defaultPrefix={this.props.defaultPrefix}
                helperClass="form-sortable-helper"
                useDragHandle={true}
                selectedItemIndex={this.props.selectedItemIndex}
            />
        );
    }

});

export default SortableContainer(FormSortable);