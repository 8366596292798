import React from 'react';
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import Velocity from 'velocity-animate';
import {Button} from 'uiKit';

const Toast = createReactClass({

	componentWillAppear: function(callback) {
		this.animateIn(callback);
	},

	componentWillEnter: function(callback) {
		this.animateIn(callback);
	},

	componentWillLeave: function(callback) {
		this.animateOut(callback);
	},
	
	animateIn: function(callback) {
		var element = ReactDOM.findDOMNode(this);
		Velocity(element, {
			translateY: [0, "100%"]
		}, {
			duration: 300,
			complete: callback
		});
	},

	animateOut: function(callback) {
		var element = ReactDOM.findDOMNode(this);
		Velocity(element, {
			translateY: "100%"
		}, {
			duration: 300,
			complete: callback
		});
	},

	renderActionButton: function() {
		if (this.props.action) {
			return (
				<Button
					className="button primary"
					onClick={this.props.commitAction}
					text={this.props.action.buttonText}/>
			);
		}
	},

	render: function() {
		return (
			<div className="toast-container">
				<div className={classNames('toast', {'action-toast' : this.props.action})}>
					<div className="toast-title">{this.props.title}</div>
					{this.renderActionButton()}
				</div>
			</div>
		);
	}
});

export default Toast;