import React from 'react';
import createReactClass from 'create-react-class';

var FormFieldSelectOption = createReactClass({

    render: function() {
        return (
            <option value={this.props.option.value}>
                {this.props.option.text}
            </option>
        );
    }

});

export default FormFieldSelectOption;