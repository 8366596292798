import React from 'react';
import createReactClass from 'create-react-class';
import {FlatButton} from 'uiKit';

var MyAccountOverviewTitle = createReactClass({

    render: function() {
        return (
            <div className="my-account-overview-title">
                <span>{this.props.text}</span>
            </div>
        );
    }

});

export default MyAccountOverviewTitle;
