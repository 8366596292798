export default function(languagePackSection, languagePackString, transform, variables) {
    
    if (window.languagePack[languagePackSection] === undefined) {
        return console.warn(`This language pack section does not exist: ${languagePackSection}`);
    }
    
    if (window.languagePack[languagePackSection][languagePackString] === undefined || !window.languagePack[languagePackSection][languagePackString]) {
        return console.warn(`This language string does not exist in: ${languagePackSection}, ${languagePackString}`);
    }

    var languageString = window.languagePack[languagePackSection][languagePackString];

    _.each(variables, function(variable, variableKey) {
        var search = "{{" + variableKey + "}}";
        languageString = languageString.replace(new RegExp(search, "g"), variable);
    })

    if (transform === 'titlecase') {
        languageString = languageString.toLowerCase().replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
    }

    if (transform === 'sentencecase') {
        var regx = /(^\w{1}|\.\s*\w{1})/gi;
        languageString = languageString.replace(regx, function(toReplace) {
            return toReplace.toUpperCase();
        });
    }

    if (transform === 'uppercase') {
        languageString = languageString.toUpperCase();
    }

    return languageString;
}