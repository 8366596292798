import React from 'react';
import classNames from 'classnames';

const Alert = function({className, type, icon, text}) {
	return (
		<div className={classNames('alert', className, type)}>
			<div className="alert-inner">
				<i className={`icon icon-${icon}`}></i>
				<span>{text}</span>
			</div>
		</div>
	);
};

export default Alert;