import React, {useState} from 'react';
import Button from '../../buttons/components/button';
import classNames from 'classnames';
import ClickOutside from "react-simple-click-outside";
import SelectOptionsItem from './selectOptionsItem';

const Options = (props) => {

	var options = _.map(props.options, (option, index) => {
		return (
			<SelectOptionsItem
				value={option._value}
				text={option.text}
				onClick={props.onOptionClicked}
				key={index}
			/>
		);
	});

	var nameOfClass = props.iconPosition === "right" ? "select-options-list is-right-direction" : "select-options-list"

	return (
		<div className={nameOfClass}>
			{options}
		</div>
	);
}

const SelectOptions = ({value, initialText, options, className, iconPosition, type, onChange}) => {
	const [isOpen, setIsOpen] = useState(false);
	var nameOfClass = classNames('select-options', className);

	var getDropDownText = () => {
		return value ? _.find(options, {_value: value}).text : initialText;
	};

	var getButtonIcon = () => {
		return isOpen ? 'chevron-up' : 'chevron-down';
	}

	var toggleDropDown = () => {
		setIsOpen(!isOpen);
	}

	var onDropDownClicked = () => {
		toggleDropDown();
	}

	var onOptionClicked = (value) => {
		toggleDropDown();
		onChange(value);
	}

	var handleClickOutside = () => {
		if (!isOpen) {
			return;
		}
		toggleDropDown();
	}

	return (
		<ClickOutside close={handleClickOutside}>
			<div className={nameOfClass}>
				<Button
					type={type || "secondary"}
					text={getDropDownText()}
					onClick={onDropDownClicked}
					icon={getButtonIcon()}
					iconPosition={iconPosition || 'left'}
				/>
				{isOpen && <Options
					options={options}
					onOptionClicked={onOptionClicked}
					iconPosition={iconPosition}
				/>}
			</div>
		</ClickOutside>
	);
}

export default SelectOptions;