import React from 'react';
import createReactClass from 'create-react-class';
import classnames from 'classnames';
import {registerType} from '../helpers';
import AlertInfo from 'modules/app/components/alertInfo';

var FormFieldDisplayModel = createReactClass({

    renderTitle: function() {
        var value = this.props.value || {};
        return (
            <AlertInfo 
                text={value.title || "This group has been deleted and this activity cannot be used. Please delete this activity."}
                type="info"
            />
        );
    },

    render: function() {
        
        return (
            <div className="form-field form-field-display-model">

                <label className="form-field-label" htmlFor={this.props.fieldKey}>
                    {this.props.label}
                </label>

                <div className="form-field-help">
                    {this.props.help}
                </div>

                {this.renderTitle()}

                <div className="form-field-error">
                    {this.props.error}
                </div>

            </div>
        );

    }

});

registerType('DisplayModel', FormFieldDisplayModel);

export default FormFieldDisplayModel;