import React from 'react';
import createReactClass from 'create-react-class';
import RegisterNewUser from '../components/registerNewUser';
import LP from 'modules/app/helpers/lp';
import axios from 'axios';

// TODO
import registerNewUserSchema from '../../../../app/schemas/users/registerNewUserSchema';

var RegisterNewUserContainer = createReactClass({

    getInitialState: function() {
        return {
            user: {
                firstName: '',
                lastName: '',
                email: ''
            },
            hasError: true
        }
    },

    onUpdateField: function(updateObject, hasError, fieldKey, formState) {
    
        var hasFormError = formState.hasError;

        var newState = _.extend({}, this.state);

        newState.hasError = hasFormError;
        newState.user = _.extend(newState.user, updateObject);

        this.setState(newState);

        return Promise.resolve();

    },

    onFormLoaded: function(formState) {
        if (formState.hasError) {
            this.setState({
                hasError: true
            });
        }
    },

    onRegisterClicked: function() {
        axios.post('api/users/register', this.state.user)
        .then((response) => {
            this.setState({hasRegistered: true});
            return this.props.onUserAdded(this.state.user);
        })
        .catch((error) => {
            if (!error.response) return console.error(error);
            this.setState({hasError: true, errorMessage: error.response.data.message});
        });
    },

    render: function() {

        return (
            <RegisterNewUser
                user={this.state.user}
                schema={registerNewUserSchema}
                onUpdateField={this.onUpdateField}
                onRegisterClicked={this.onRegisterClicked}
                hasError={this.state.hasError}
                hasRegistered={this.state.hasRegistered}
                errorMessage={this.state.errorMessage}
                onFormLoaded={this.onFormLoaded}
                onCancelDialog={this.props.onCancelDialog}
            />
        );
    }

});

export default RegisterNewUserContainer;
