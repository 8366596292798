import React from 'react';
import ReactSlider from 'react-slider';

const RangeSlider = ({value, defaultValue, min, max, step, onChange, disabled}) => {
    return (
        <ReactSlider
            value={value}
            defaultValue={defaultValue}
            min={min}
            max={max}
            step={step}
            onChange={onChange}
            withBars
            className="rangeslider"
            handleClassName="rangeslider__handle"
            barClassName="rangeslider__bar"
            disabled={!!disabled}
        />
    )
}

export default RangeSlider;