import React from 'react';
import createReactClass from 'create-react-class';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import classnames from 'classnames'
import {Button, Card, Alert} from 'uiKit';
import LP from 'modules/app/helpers/lp';
import AlertInfo from 'modules/app/components/alertInfo';
import Columns from 'modules/app/components/columns';
import AuthenticationTokenDialogContainer from '../containers/authenticationTokenDialogContainer';
import AuthenticationTokenDialog from './authenticationTokenDialog';
import LoginPasswordReset from './loginPasswordReset';
import LoginOAuth from './loginOAuth';
import LoginOpenRegister from './loginOpenRegister';
import ReactGA from 'react-ga';
import hasAtLeastOneSSOOptionEnabled from '../helpers/hasAtLeastOneSSOOptionEnabled';

var Login = createReactClass({

    componentDidMount: function() {
        this._emailInput.focus();
        
        if (window.generalSettings._shouldUseGoogleAnalytics && window.generalSettings._googleAnalyticsTrackingId) {
            ReactGA.initialize(window.generalSettings._googleAnalyticsTrackingId);
            ReactGA.set({ page: location.pathname });
            ReactGA.pageview(location.pathname);
        }
    },

    renderError: function() {
        if (this.props.error && !this.props._isLogginIn) {
            return (
                <AlertInfo text={this.props.error} type="warning"/>
            )
        } 
    },

    getButtonText: function() {
        var lpString = this.props._isLogginIn ? 'loggingInButtonText' : 'loginButtonText'
        return LP('login', lpString, 'titlecase');
    },

    getCardClassName: function() {
        var googleOAauthEnabled = authentication._shouldAllowGoogleOAuth;
        var twitterOAauthEnabled = authentication._shouldAllowTwitterOAuth;
        var facebookOAauthEnabled = authentication._shouldAllowFacebookOAuth;
        var linkedinOAauthEnabled = authentication._shouldAllowLinkedInOAuth;
        var azureOAauthEnabled = authentication._shouldAllowAzureOAuth;

        return classnames('login clearfix', {
            'has-sso': hasAtLeastOneSSOOptionEnabled()
        });
    },

    getContainerBackgroundImage: function() {
        var logo;

        if (window.theme && window.theme._login && window.theme._login['@login-background-image']) {
            logo = window.theme._login['@login-background-image'].replace('/images/', '/login/');

            return 'url("../' + logo + '")'

        } else {
            return null;
        }
    },

    renderSSO: function() {

        if (hasAtLeastOneSSOOptionEnabled()) {
            return (
                <LoginOAuth
                    onGoogleOAuthClicked={this.props.onGoogleOAuthClicked}
                    onLinkedInOAuthClicked={this.props.onLinkedInOAuthClicked}
                    onTwitterOAuthClicked={this.props.onTwitterOAuthClicked}
                    onFacebookOAuthClicked={this.props.onFacebookOAuthClicked}
                    onAzureOAuthClicked={this.props.onAzureOAuthClicked}
                />
            );
        }

    },

    renderEmailSuffix: function() {
        
        if(authentication._shouldShowEmailSuffix && authentication._emailSuffix && this.props.email) {

            var email = this.props.email;

            if(email.indexOf('@') === -1) {
                email += ('@' + authentication._emailSuffix);
            }

            return (
                <div className="login-email-suffix">
                    {email}
                </div>
            );
        }
    },

    renderLoginDivider: function() {

        if (authentication._shouldAllowOpenRegistrations) {

            return (
                <div className="login-divider">
                    <hr />
                    <div className="login-divider-or">
                        <span className="login-divider-text">
                            OR
                        </span>
                    </div>
                </div>
            );
        };
    },

    renderLoginForm: function(length) {
        var className = "login-forms";

        if (length === 0) {
            var className = "login-forms oauth-is-not-enabled"
        };

        return (
            <form className={className} aria-label={LP('login', 'signInFormAriaLabel')}>
                <div className="form-field">
                    <label className="form-field-label" htmlFor="sign-in-email">
                        {LP('user', 'email', 'titlecase')}:
                    </label>
                    <input
                        id="sign-in-email"
                        aria-required
                        ref={(c) => this._emailInput = c} 
                        type="text"
                        value={this.props.email} 
                        onChange={this.props.onEmailChanged}
                        onKeyPress={this.props.onKeyPress}
                    />
                    {this.renderEmailSuffix()}
                </div>

                <div className="form-field">
                    <label className="form-field-label" htmlFor="sign-in-password">
                        {LP('user', 'password', 'titlecase')}:
                    </label>
                    <input 
                        id="sign-in-password"
                        aria-required
                        type="password"
                        value={this.props.password} 
                        onChange={this.props.onPasswordChanged}
                        onKeyPress={this.props.onKeyPress}
                    />
                </div>

                {this.renderError()}

                <div className="form-buttons">
                    <Button
                        disabled={this.props._isLogginIn} 
                        type="primary large full-width"
                        onClick={this.props.onSignInClicked}
                        text={this.getButtonText()}
                    />
                </div>
                <LoginPasswordReset/>
            </form>
        )
    },

    renderColumns: function() {
        if (hasAtLeastOneSSOOptionEnabled()) {
            return <Columns columns={[this.renderLoginForm(), this.renderSSO()]}/>
        } else {
            return this.renderLoginForm(length);
        }
    },

    renderLogo: function() {
        var logo = window.generalSettings._logo;
        var shouldShowLogoOnLogin = window.generalSettings._shouldShowLogoOnLogin;
        var themeLoginLogo = window.theme ? window.theme._login["@login-card-logo"] : null;

        if (themeLoginLogo) {
            logo = themeLoginLogo.replace('/images/', '/logo/');;
        } else if (!themeLoginLogo && logo && shouldShowLogoOnLogin) {
            logo = logo.replace('/images/', '/logo/');
        }
        
        return (
            <div className="login-logo" style={{height: window.generalSettings._navigationHeight + 'px'}}>
                <div className="login-logo-inner">
                    <a href='/'>
                        {this.renderLogoGraphic(logo)}
                    </a>
                </div>
            </div>
        );
    },

    renderLogoGraphic: function(logo) {
        if (logo) {
            return (
                <img
                    style={{height: (window.generalSettings._navigationHeight - 18) + 'px'}}
                    className="login-logo-graphic"
                    src={`${window.location.origin}/${logo}`}
                />
            )
        }
    },

    render: function() {
        
        return (
            <div className="login-container" style={{backgroundImage: this.getContainerBackgroundImage(), height: `${window.innerHeight}px`}}>
                <Card className={this.getCardClassName()}>
                    {this.renderLogo()}
                    <h1 className="login-title" style={{"paddingTop": window.generalSettings._navigationHeight + 'px'}}>
                        {LP('login', 'loginTitle', 'sentencecase')}
                    </h1>
                    <h2 className="login-sub-title" dangerouslySetInnerHTML={{ __html: LP('login', 'loginSubtitle', 'sentencecase') }} />
                    {this.renderColumns()}
                    {this.renderLoginDivider()}
                    <LoginOpenRegister
                        navigateToOpenRegister={this.props.navigateToOpenRegister}
                    />
                </Card>
                {this.props.displayAuthTokenDialog && 
                    <AuthenticationTokenDialogContainer 
                        {...this.props}
                        type="dialog"
                        customDialog={AuthenticationTokenDialog}
                        title={LP('login', 'authenticationToken', 'titlecase')}
                    />
                }
            </div>
        );
    }

});

export default Login;