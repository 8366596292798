import React from 'react';
import createReactClass from 'create-react-class';
import Classnames from 'classnames';

var Columns = createReactClass({

	getClassName: function() {
		return Classnames("columns", this.props.className);
	},

	renderColumns: function() {
		var length = this.props.columns.length;

		return _.map(this.props.columns, (component, index) => {
			return this.renderColumn(component, index + 1, length);			
		});

	},

	renderColumn: function(component, column, length) {
		return (
			<div key={column} className={"columns-" + column + "-" + length}>
				{component}
			</div>
		);
	},

	render: function() {
		return (
			<div className={this.getClassName()}>
				{this.renderColumns()}
			</div> 
		);
	}

});

export default Columns;