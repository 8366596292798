import axios from 'axios';
import Bluebird from 'bluebird';

export const FETCH_DASHBOARD_DATA = 'FETCH_DASHBOARD_DATA';

export function fetchDashboardData() {

    return (dispatch) => {

        return axios.get('api/dashboard')
        .then(function(response) {
            
            dispatch({
                type: FETCH_DASHBOARD_DATA,
                payload: response.data
            });
            return response.data;

        })

    }

}

