import _ from 'lodash';
var sockets;
var hasInitialize = false;

export function initializeSockets(callback) {
    
    if (hasInitialize) return;

    if (window.location.pathname.length > 3) {
        sockets = io.connect('/', {path: window.location.pathname + 'socket.io'}); 
    } else {
        sockets = io.connect(window.location.host); 
    }
    
    sockets.on('connect', function() {
        hasInitialize = true;
        callback();
    });

    sockets.on('disconnect', function() {
        hasInitialize = false;
    });

}

export function getSockets() {
    return sockets;
}