import React from 'react';
import createReactClass from 'create-react-class';
import PopupContent from './popupContent';
import PopupActions from './popupActions';

var Popup = createReactClass({

    render: function() {
        return (
            <div className="popup">
                <PopupContent
                    stage={this.props.stage}
                    popups={this.props.popups}
                    isNextButtonEnabled={this.props.isNextButtonEnabled}
                    enableNextButton={this.props.enableNextButton}
                    disableNextButton={this.props.disableNextButton}
                    storePopupData={this.props.storePopupData}
                />
                <PopupActions
                    stage={this.props.stage}
                    popups={this.props.popups}
                    isNextButtonEnabled={this.props.isNextButtonEnabled}
                    onNextButtonClicked={this.props.onNextButtonClicked}
                    onPreviousButtonClicked={this.props.onPreviousButtonClicked}
                />
            </div>
        );
    }

})

export default Popup;