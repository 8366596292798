import React from 'react';

const NarrativeGalleryItem = (props) => {
	return (
		<div className="narrative-slider-item" style={{width: props.width + 'px'}}>
			<div className="narrative-slider-graphic">
				<img src={props.item._graphic.src} />
			</div>
		</div>
	)
}

export default NarrativeGalleryItem;