import React from 'react';
import classnames from 'classnames';
import createReactClass from 'create-react-class';

const Field = createReactClass({

    renderField: function() {
        const { children, fieldId, error } = this.props;
        return typeof children === 'function' ? children({
            ariaLabelledBy: error ? `${fieldId}-label ${fieldId}-error` : `${fieldId}-label`, 
            ariaDescribedBy: `${fieldId}-help`,
        }) : children;
    },

    render: function() {
        const { props } = this;

        return (
            <div className={classnames('form-field', props.className)}>
    
                <label 
                    id={`${props.fieldId}-label`}
                    htmlFor={props.fieldId} 
                    className="form-field-label">
                    {props.label}
                </label>

                <div 
                    id={`${props.fieldId}-help`} 
                    htmlFor={props.fieldId} 
                    className="form-field-help">
                    {props.help}
                </div>

                {this.renderField()}

                <div 
                    id={`${props.fieldId}-error`}
                    className="form-field-error" 
                    role="alert">{props.error}
                </div>
            </div>
        )
    }

});

module.exports = Field;