import React from 'react';
import createReactClass from 'create-react-class';
import moment from 'moment';
import {Button} from 'uiKit';
import MyAccountOverviewTitle from './myAccountOverviewTitle';
import MyAccountOverviewItem from './myAccountOverviewItem';

var STATUS_MAPPING = {
    'in_trial': 'In trial',
    'active': 'Active',
    'cancelled': 'Inactive',
    'non_renewing': 'Soon to be cancelled',
    'future': 'Subscription will start soon'
}

var MyAccountOverviewBasic = createReactClass({

    renderPlan: function() {
        return (
            <MyAccountOverviewItem
                label="Plan"
                value={
                    this.props.subscriptions[this.props.subscription.plan_id] !== undefined
                        ? this.props.subscriptions[this.props.subscription.plan_id].title
                        : this.props.subscription.plan_id
                }
            />
        );
    },

    renderStatus: function() {
        return (
            <MyAccountOverviewItem
                label="Status"
                value={STATUS_MAPPING[this.props.subscription.status]}
            />
        );
    },

    renderTrialStartDate: function() {
        if (this.props.subscription.status === 'in_trial') {
            return (
                <MyAccountOverviewItem
                    label="Trial start date"
                    value={moment.unix(this.props.subscription.trial_start).fromNow()}
                />
            );
        }
    },

    renderTrialEndDate: function() {
        if (this.props.subscription.status === 'in_trial') {
            return (
                <MyAccountOverviewItem
                    label="Trial end date"
                    value={moment.unix(this.props.subscription.trial_end).fromNow()}
                />
            );
        }
    },

    render: function() {

        if(!this.props.isUserBasic || this.props.isAnotherSubscription === true) {
            return(
                <div />
            );
        }

        if ( !this.props.isMemberOfSubscriptionGroup.isMember || (this.props.isMemberOfSubscriptionGroup.isMember && this.props.isMemberOfSubscriptionGroup.groupManager._customAttributes._kinderlySubscriptionStatus != 'active') ) {
            return (
                <div className="my-account-overview-section">
                    <MyAccountOverviewTitle text="Subscription Status"/>
                    <div className="my-account-overview-content">
                        {this.renderStatus()}
                        {this.renderTrialStartDate()}
                        {this.renderTrialEndDate()}
                        <Button text="Subscribe now" type="primary" onClick={this.props.onSubscribeClicked}/>
                    </div>
                </div>
            );
        } else {
            const groupManager = this.props.isMemberOfSubscriptionGroup.groupManager.firstName + ' ' + this.props.isMemberOfSubscriptionGroup.groupManager.lastName;
            const groupName = this.props.subscription.subscriptionGroupName;

            return (
                <div className="my-account-overview-section">
                    <MyAccountOverviewTitle text="Subscription Status"/>
                    <div className="my-account-overview-content">

                        <MyAccountOverviewItem
                            label="Status"
                            value="In subscription group"
                        />

                        <MyAccountOverviewItem
                            label="Group Name"
                            value={groupName}
                        />

                        <MyAccountOverviewItem
                            label="Group Manager"
                            value={groupManager}
                        />

                    </div>
                </div>  
            )
        }
    }

});

export default MyAccountOverviewBasic;
