import React from 'react';
import createReactClass from 'create-react-class';
import classNames from 'classnames';

const CheckListItem = function(props) {
    return (
        <li className={classNames('check-list-item', { "isChecked" : props.isChecked } )}>
            <div className="check-list-item-input">
                <input type="checkbox" checked={props.isChecked} onChange={(event) => props.onCheck(event.target.checked)}/>
            </div>                             
            <div className="check-list-item-content">
                {props.children}
            </div>
        </li>
    );
}

export default CheckListItem;