import DefaultDialog from './defaultDialog.js';
import SelectDialog from './selectDialog.js';
import CheckDialog from './checkDialog.js';
import RadioDialog from './radioDialog.js';
import IconDialog from './iconDialog.js';

export default {
	default: DefaultDialog,
	select: SelectDialog,
	check: CheckDialog,
	radio: RadioDialog,
	icon: IconDialog
};