import React from 'react';
import createReactClass from 'create-react-class';
import {DialogTitle, DialogBody, DialogActions} from 'modules/notifications/components/dialogComponents';
import {Form} from 'modules/form';

var MyAccountChangeSubscriptionGroupNameDialog = createReactClass({

    getInitialState: function() {

        return {
            group_name: this.props.options.groupName,
            hasError: false
        }

    },
    
    handleDialogAction: function(action) {
        this.props.commitAction({
            action: action,
            payload: {
                group_name: this.state.group_name,
            }
        });
    },

    onUpdate: function(update, hasErrored, fieldKey) {
        var updateObject = hasErrored || {hasError: false};
        updateObject[fieldKey] = update[fieldKey];
        this.setState(updateObject);
        return Promise.resolve(update);
    },
    
    render: function() {

        return (
            <div className="dialog">
                <DialogTitle title={this.props.title}/>
                <DialogBody body={this.props.body}/>
                <Form
                    schema={this.props.options.schema}
                    onUpdate={this.onUpdate}
                    model={this.state}
                />
                <DialogActions model={this.state} actions={this.props.actions} onDialogAction={this.handleDialogAction}/>
            </div>
        );

    }
    
})

export default MyAccountChangeSubscriptionGroupNameDialog;
