import axios from 'axios';
import Bluebird from 'bluebird';
import store from 'store';
import _ from 'lodash';

export const UPDATE_SCREEN_SIZE = 'UPDATE_SCREEN_SIZE';
export const UPDATE_HEADER = 'UPDATE_HEADER';
export const UPDATE_HEADER_HEIGHT = 'UPDATE_HEADER_HEIGHT';
export const SET_HERO_IMAGE = 'SET_HERO_IMAGE';
export const SHOW_FOOTER = 'SHOW_FOOTER';
export const HIDE_FOOTER = 'HIDE_FOOTER';

const SCREEN_SIZE_LARGE = 1024;
const SCREEN_SIZE_MEDIUM = 760;
const SCREEN_SIZE_SMALL = 520;

export function updateScreenSize(screenWidth, screenHeight) {

    var screenSize = 'large';

    if (screenWidth > SCREEN_SIZE_LARGE) {
        screenSize = 'xLarge';
    }
    
    if (screenWidth <= SCREEN_SIZE_MEDIUM) {
        screenSize = 'medium';
    }

    if (screenWidth <= SCREEN_SIZE_SMALL) {
        screenSize = 'small';
    }

    return {
        type: UPDATE_SCREEN_SIZE,
        payload: {
            _screenWidth: screenWidth,
            _screenHeight: screenHeight,
            _screenSize: screenSize
        }
        
    };

}

export function updateHeader(options) {
    return dispatch => {

         dispatch({
            type: UPDATE_HEADER,
            payload: options
        });

        return Bluebird.resolve();
    }
}

export function updateHeaderHeight(headerHeight) {
    return dispatch => {

        dispatch({
           type: UPDATE_HEADER_HEIGHT,
           payload: {headerHeight}
       });

       return Bluebird.resolve();
   }
}

export function setHeroImage(options) {
    return dispatch => {

        dispatch({
            type : SET_HERO_IMAGE,
            payload: options
        });

        return Bluebird.resolve();
    }
}

export function showFooter(options) {
    return dispatch => {

         dispatch({
            type: SHOW_FOOTER,
            payload: options
        });

        return Bluebird.resolve();
    }
}

export function hideFooter(options) {
    return dispatch => {

         dispatch({
            type: HIDE_FOOTER,
            payload: options
        });

        return Bluebird.resolve();
    }
}

window.addEventListener('resize', () => {
    store.dispatch(updateScreenSize(window.innerWidth, window.innerHeight));
});
