import React from 'react';
import createReactClass from 'create-react-class';

var TabsLabel = createReactClass({
    render: function() {
        const {className, onClick, icon, text, isSelected, value, onKeyDown} = this.props;
        
        return (
            <button
                className={`button-flat ${className}`}
                onClick={onClick}
                onKeyDown={onKeyDown}
                role="tab"
                aria-selected={isSelected}
                aria-controls={isSelected ? `${value}-tabpanel` : null}
                id={`${value}-tab`}
                tabIndex={isSelected ? 0 : -1}>
                <i className={'icon icon-' + icon + ' tab-label-icon'}></i>
                <span className="flat-button-text">	{text} </span>
            </button>
        );
    }
})

export default TabsLabel;