var registerNewUserSchema = {
    "firstName": {
        "type":"Text",
        "label": "First name",
        "validators": ["required"],
        "labelLpSection": "user",
        "labelLpString": "firstName",
        "labelLpTransform": "sentencecase",
        "conditions": [],
        "help": ""
    },
    "lastName": {
        "type":"Text",
        "label": "Last name",
        "validators": ["required"],
        "labelLpSection": "user",
        "labelLpString": "lastName",
        "labelLpTransform": "sentencecase",
        "conditions": [],
        "help": ""
    },
    "email": {
        "type":"Text",
        "label": "Email",
        "validators": ["required", "isValidEmail"],
        "labelLpSection": "user",
        "labelLpString": "email",
        "labelLpTransform": "sentencecase",
        "conditions": [],
        "help": ""
    }
}

module.exports = registerNewUserSchema;