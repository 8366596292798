import axios from 'axios';
import Bluebird from 'bluebird';
import store from 'store';
import _ from 'lodash';

export const ACTIVATE_GUIDE = 'ACTIVATE_GUIDE';
export const DEACTIVATE_GUIDE = 'DEACTIVATE_GUIDE';
export const CHANGE_GUIDE_STEP = 'CHANGE_GUIDE_STEP';

export function activateGuide(options) {
    return dispatch => {

         dispatch({
            type: ACTIVATE_GUIDE,
            payload: options
        });

        return Bluebird.resolve();
    }
}

export function deactivateGuide(options) {
    return dispatch => {

        dispatch({
           type: DEACTIVATE_GUIDE,
           payload: options
       });

       return Bluebird.resolve();
   }
}

export function changeGuideStep(step) {
    return dispatch => {

         dispatch({
            type: CHANGE_GUIDE_STEP,
            payload: {
                step
            }
        });

        return Bluebird.resolve();
    }
}