import React from 'react';
import createReactClass from 'create-react-class';
import classNames from 'classnames';
import LP from 'modules/app/helpers/lp';

var NavigationLink = createReactClass({

    getClassNames: function() {
        var navigationLinkClassName;
        if (this.props.isFlatButton) {
            navigationLinkClassName = `button-flat ${this.props.type ? this.props.type : ''} ${this.props.className ? this.props.className : ''}`
        }
        else if (this.props.isAnchor) {
            navigationLinkClassName = `${this.props.className ? this.props.className : ''}`
        } else {
            navigationLinkClassName = `button ${this.props.type ? this.props.type : ''} ${this.props.className ? this.props.className : ''}`
        }

        return classNames('navigation-link', navigationLinkClassName, {
            "is-small" : this.props.isSmall,
            "disabled": this.props.disabled,
            "tool-tip-button": this.props.toolTip
        });
    },

    getHref: function() {
        if (!this.props.href || this.props.disabled) {
            return null;
        }

        return this.props.href
    },

    getOnClick: function() {
        if (!this.props.onClick || this.props.disabled) {
            return null;
        }

        return this.props.onClick
    },

    getAriaLabel: function() {
        var ariaLabel = this.props.text;
        if (this.props['aria-label']) {
            ariaLabel = this.props['aria-label'];
        }
        if (this.props.disabled) {
            ariaLabel += `. ${LP('courses', 'courseIsCurrentlyUnavailableAriaLabel')}`
        }
        if (this.props.opensInNewWindow) {
            ariaLabel += `. ${LP('global', 'linkOpensInNewWindow', 'sentencecase')}.`;
        }
        return ariaLabel;
    },
    
    onKeyPress: function(event) {
        if (event.keyCode === 0) {
            return this.props.onClick(event);
        }
    },

    renderLeftIcon() {
        if (this.props.iconPosition === 'left' || !this.props.iconPosition) {
            return this.renderIcon('left');
        }
    },

    renderRightIcon() {
        if (this.props.iconPosition === 'right') {
            return this.renderIcon('right');
        }
    },

    renderIcon: function(position) {
        if (this.props.icon) {
            if (position) {
                return <i className={classNames(`icon icon-${this.props.icon}`, `button-icon-position-${position}`)}/>
            }
            return <i className={`icon icon-${this.props.icon}`}/>
        }
    },
    
    renderText: function() {
        if (this.props.text) {
            return <p aria-hidden>{this.props.text}</p>;
        }
    },

    render: function() {
        return (
            <a
                aria-label={this.getAriaLabel()}
                tabIndex={this.props.tabIndex || 0}
                href={this.getHref()}
                onClick={this.props.disabled ? null : this.props.onClick}
                onKeyPress={this.onKeyPress}
                className={this.getClassNames()}
            >
                {this.props.children}
                {this.renderLeftIcon()}
                {this.renderText()}
                {this.renderRightIcon()}
            </a>
        );
    }

});

export default NavigationLink;