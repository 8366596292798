import axios from 'axios';
import Bluebird from 'bluebird';


export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USER = 'FETCH_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const CREATE_USER = 'CREATE_USER';
export const ADD_USER_TO_GROUP = 'ADD_USER_TO_GROUP';
export const ADD_USERS_TO_GROUP = 'ADD_USERS_TO_GROUP';
export const ADD_USER_TO_GROUPS = 'ADD_USER_TO_GROUPS';
export const REMOVE_USER_FROM_GROUP = 'REMOVE_USER_FROM_GROUP';
export const REMOVE_USER_FROM_GROUPS = 'REMOVE_USER_FROM_GROUP';
export const REMOVE_USERS_FROM_GROUP = 'REMOVE_USERS_FROM_GROUP';
export const FETCH_USER_SCHEMA = 'FETCH_USER_SCHEMA';
export const RESET_USER = 'RESET_USER';
export const DELETE_USER = 'DELETE_USER';
export const REMOVE_USERS = 'REMOVE_USERS';
export const REMOVE_ALL_USERS = 'REMOVE_ALL_USERS';
export const FETCH_AVAILABLE_USERS_BY_GROUP_ID = 'FETCH_AVAILABLE_USERS_BY_GROUP_ID';
export const FETCH_AVAILABLE_USERS_MULTIPLE_GROUPS = 'FETCH_AVAILABLE_USERS_MULTIPLE_GROUPS';
export const DOWNLOAD_USERS_CSV = 'DOWNLOAD_USERS_CSV';
export const UPLOAD_CSV = 'UPLOAD_CSV';
export const UPDATE_CSV = 'UPDATE_CSV';
export const DELETE_CSV = 'DELETE_CSV';
export const ARCHIVE_CSV = 'ARCHIVE_CSV';
export const UPDATE_USERS_LANGUAGE_PACK = 'UPDATE_USERS_LANGUAGE_PACK';
export const UPDATE_USERS_ROLE = 'UPDATE_USERS_ROLE';
export const FETCH_AVAILABLE_USERS = 'FETCH_AVAILABLE_USERS';
export const UPDATE_USERS_RELEASE_VERSION = 'UPDATE_USERS_RELEASE_VERSION';
export const RESEND_ALL_REGISTRATION_EMAILS = 'RESEND_ALL_REGISTRATION_EMAILS';
export const RESEND_REGISTRATION_EMAIL = 'RESEND_REGISTRATION_EMAIL';
export const REMOVE_LANGUAGE_PACK_FROM_USERS = 'REMOVE_LANGUAGE_PACK_FROM_USERS';

export function fetchUsers(searchValue, currentPage) {
    
    return (dispatch) => {

        return axios.get('api/users', {
            params: {
                searchValue: searchValue,
                currentPage: currentPage
            }
        })
        .then(function(response) {

            dispatch({
                type: FETCH_USERS,
                payload: response.data
            });

            return Bluebird.resolve(response);

        });
            
    }

}

export function fetchUsersByGroupId(groupId, searchValue, currentPage) {
    return (dispatch) => {

        return axios.get('api/users/byGroupId/' + groupId, {
            params: {
                searchValue: searchValue,
                currentPage: currentPage
            }
        })
        .then(function(response) {

            dispatch({
                type: FETCH_USERS,
                payload: response.data
            });

            return response;

        });
            
    }
}

export function fetchUser(userId) {

    return (dispatch) => {

        return axios.get('api/users/' + userId)
        .then(function(response) {

            dispatch({
                type: FETCH_USER,
                payload: response.data
            });

            return response;

        });
            
    }

}

export function fetchAvailableUsersByGroupId(groupId, searchValue, currentPage) {
    return (dispatch) => {

        return axios.get('api/users/availableByGroupId/' + groupId, {
            params : {
                searchValue: searchValue,
                currentPage: currentPage
            }
        })
        .then(function(response) {

            dispatch({
                type: FETCH_AVAILABLE_USERS_BY_GROUP_ID,
                payload: response.data
            });

            return response;

        });
            
    }
}

export function fetchUsersByMultipleGroups(groupIds, searchValue, currentPage) {
    
    return (dispatch) => {

        return axios.get('api/users/availableByMultipleGroups/', {
            params : {
                searchValue: searchValue,
                currentPage: currentPage,
                groupIds: groupIds
            }
        })
        .then(function(response) {

            dispatch({
                type: FETCH_AVAILABLE_USERS_MULTIPLE_GROUPS,
                payload: response.data
            });

            return response;

        });
            
    }
}

export function updateUser(userId, userModel) {


    return (dispatch) => {

        return axios.put('api/users/' + userId, userModel)
        .then(function(response) {

            dispatch({
                type: UPDATE_USER,
                payload: response.data
            });

            return response;

        });
            
    }

}

export function updateUserPassword(userId, password) {


    return (dispatch) => {

        return axios.patch('api/users/' + userId + '/changePassword', {password: password})       
        .then(function(response) {
            dispatch({
                type: UPDATE_USER,
                payload: response.data
            });
            return response;
        });

    }

}

export function updateUserArchive(userId, isArchived) {
    return (dispatch) => {

        return axios.put('api/users/' + userId + '/updateArchive', {_hasBeenArchived: isArchived})
        .then(function(response) {

            dispatch({
                type: UPDATE_USER,
                payload: response.data
            });

            return response;

        });
            
    }
}

export function createUser(userModel) {

    return (dispatch) => {

        return axios.post('api/users', userModel)
        .then(function(response) {

            dispatch({
                type: CREATE_USER,
                payload: response.data
            });

            return response;

        });
            
    }

}

export function addUserToGroup(userId, groupId) {

    return (dispatch) => {

        return axios.put('api/users/' + userId + '/addToGroup/' + groupId)
        .then(function(response) {
    
            dispatch({
                type: ADD_USER_TO_GROUP,
                payload: response.data
            });

            return response.data;

        });

    }

}

export function addUserToGroups(userId, groupIds) {
    
    return (dispatch) => {

        return axios.put('api/users/' + userId + '/addToGroups', {groupIds: groupIds})
        .then(function(response) {
    
            dispatch({
                type: ADD_USER_TO_GROUPS,
                payload: response.data
            });

            return response.data;

        });

    }
}

export function removeUserFromGroup(userId, groupId) {

    return (dispatch) => {

        return axios.put('api/users/' + userId + '/removeFromGroup/' + groupId)
        .then(function(response) {
    
            dispatch({
                type: REMOVE_USER_FROM_GROUP,
                payload: response.data
            });

            return response.data;

        });

    }

}

export function removeUserFromGroups(userId, groupIds) {
    
    return (dispatch) => {

        return axios.put('api/users/' + userId + '/removeFromGroups', {groupIds: groupIds})
        .then(function(response) {
    
            dispatch({
                type: REMOVE_USER_FROM_GROUPS,
                payload: response.data
            });

            return response.data;

        });

    }
}

export function addUsersToGroup(groupId, userIds) {

    return (dispatch) => {

        return axios.put('api/users/addToGroup/' + groupId, {
            userIds: userIds
        })
        .then(function(response) {
    
            dispatch({
                type: ADD_USERS_TO_GROUP,
                payload: response.data
            });

            return response.data;

        });

    }

}

export function removeUsersFromGroup(userIds, groupId) {
    return (dispatch) => {

        return axios.put('api/users/removeFromGroup/' + groupId, {
            userIds: userIds
        })
        .then(function(response) {
    
            dispatch({
                type: REMOVE_USERS_FROM_GROUP,
                payload: response.data
            });

            return response.data;

        });

    }
}

export function deleteUser(userId) {

    return (dispatch) => {

        return axios.delete('api/users/' + userId)
        .then(function(response) {

            dispatch({
                type: DELETE_USER,
                payload: response.data
            });

            return response;

        });
            
    }

}

export function fetchUserSchema(schemaType) {

    return (dispatch) => {

        dispatch({
            type: RESET_USER
        });

        return axios.get('api/users/schema/' + schemaType)
        .then(function(response) {

            dispatch({
                type: FETCH_USER_SCHEMA,
                payload: response.data
            });

            return response;

        });
            
    }

}

export function fetchUsersByCourseId(courseId, searchValue, currentPage) {

    return (dispatch) => {

        return axios.get('api/users/byCourseId/' + courseId, {
            params: {
                searchValue: searchValue,
                currentPage: currentPage
            }
        })
        .then(function(response) {

            dispatch({
                type: FETCH_USERS,
                payload: response.data
            });

            return Bluebird.resolve(response);

        });
            
    }


}

export function uploadCsv(files, options, progressCallback) {
    var data = new FormData();
    data.append('uploads', files[0]);
    data.append('options', JSON.stringify(options));

    var config = {
        progress: function(progressEvent) {
            var percentCompleted = progressEvent.loaded / progressEvent.total * 100;
            progressCallback(percentCompleted);
        }
    };

    return (dispatch) => {

        return axios.post('api/users/uploadCsv', data, config)
        .then(function(response) {

            dispatch({
                type: UPLOAD_CSV,
                payload: response.data
            });
            return response.data;

        });

    }
}

export function updateCsv(files, options, progressCallback) {
    var data = new FormData();
    data.append('uploads', files[0]);
    data.append('options', JSON.stringify(options));

    var config = {
        progress: function(progressEvent) {
            var percentCompleted = progressEvent.loaded / progressEvent.total * 100;
            progressCallback(percentCompleted);
        }
    };

    return (dispatch) => {

        return axios.post('api/users/updateCsv', data, config)
        .then(function(response) {

            dispatch({
                type: UPDATE_CSV,
                payload: response.data
            });
            return response.data;

        });

    }
}

export function deleteCsv(files, options, progressCallback) {
    var data = new FormData();
    data.append('uploads', files[0]);
    data.append('options', JSON.stringify(options));

    var config = {
        progress: function(progressEvent) {
            var percentCompleted = progressEvent.loaded / progressEvent.total * 100;
            progressCallback(percentCompleted);
        }
    };

    return (dispatch) => {

        return axios.post('api/users/deleteCsv', data, config)
        .then(function(response) {

            dispatch({
                type: DELETE_CSV,
                payload: response.data
            });
            return response.data;

        });

    }
}

export function archiveCsv(files, options, progressCallback) {
    var data = new FormData();
    data.append('uploads', files[0]);
    data.append('options', JSON.stringify(options));

    var config = {
        progress: function(progressEvent) {
            var percentCompleted = progressEvent.loaded / progressEvent.total * 100;
            progressCallback(percentCompleted);
        }
    };

    return (dispatch) => {

        return axios.post('api/users/archiveCsv', data, config)
        .then(function(response) {

            dispatch({
                type: ARCHIVE_CSV,
                payload: response.data
            });
            return response.data;

        });

    }
}

export function isExistingUser(user) {
    return (dispatch) => {

        return axios.post('api/users/isExistingUser', user)
        .then(function(response) {

            return response;

        });
            
    }
}

export function loginAsUser(userId) {
    return (dispatch) => {

        return axios.post('api/users/loginAsUser/' + userId)
        .then(function(response) {

            return response;

        });
            
    }
}

export function updateUsersLanguagePack(userId, languagePack) {


    return (dispatch) => {

        return axios.put(`api/users/${userId}/updateLanguagePack` , {_languagePack: languagePack})
            .then(function (response) {
                
                dispatch({
                    type: UPDATE_USERS_LANGUAGE_PACK,
                    payload: response.data
                });

                return response;

            });

    }

}

export function updateUsersRole(role) {
    
    return (dispatch) => {

        return axios.patch(`api/users/updateRole` , {_role: role})
            .then(function (response) {
                
                dispatch({
                    type: UPDATE_USERS_ROLE,
                    payload: response.data
                });

                return response;

            });
    }
}

export function updateUsersReleaseVersion() {

    return (dispatch) => {

        return axios.patch(`api/users/updateReleaseVersion`)
            .then(function (response) {
                
                dispatch({
                    type: UPDATE_USERS_RELEASE_VERSION,
                    payload: response.data
                });

                return response;

            });
    }

}

export function resendAllRegistrationEmails() {

    return (dispatch) => {

        return axios.post(`api/users/resendAllRegistrationEmails`)
        .then(function (response) {
            
            dispatch({
                type: RESEND_ALL_REGISTRATION_EMAILS,
                payload: response.data
            });

            return response;

        });
    }

}

export function resendRegistrationEmail(userIds) {

    return (dispatch) => {

        return axios.post(`api/users/resendRegistrationEmail`, {
            userIds
        })
        .then(function (response) {
            
            dispatch({
                type: RESEND_REGISTRATION_EMAIL,
                payload: response.data
            });

            return response;

        });
    }

}

export function removeLanguagePackFromUsers(languagePackId) {
    
    return (dispatch) => {
        return axios.put(`api/users/removeLanguagePackFromUsers`, {
            languagePack: languagePackId
        })
        .then(function(response) {
            dispatch({
                type: REMOVE_LANGUAGE_PACK_FROM_USERS,
                payload: response.data
            });

            return response;
        });
    }
}

export function downloadUsersCSV(groups, columns) {
    return (dispatch) => {
        return axios.post('api/users/createUsersCSV', { 
            groups: groups,
            columns: columns
        }).then((response) => {
            window.open(`api/users/downloadUsersCSV?filename=${response.data}`);
        });
    }
}