import React from 'react';
import createReactClass from 'create-react-class';
import moment from 'moment';
import {Button} from 'uiKit';
import MyAccountOverviewTitle from './myAccountOverviewTitle';
import MyAccountOverviewItem from './myAccountOverviewItem'

var CARD_STATUS_MAPPING = {
    'no_card': 'No card setup',
    'valid': 'Valid card setup',
    'expiring': 'Card is due to expire'
}

var MyAccountOverviewPayment = createReactClass({

    renderCardStatus: function() {

        var icon = "shield-alert";

        if (this.props.customer.card_status === 'valid') {
            icon = "shield-check";
        }

        switch(this.props.customer.card_status) {
            case 'no_card':
                return (
                    <div>
                        <MyAccountOverviewItem
                            icon={icon}
                            label="Card status"
                            value={CARD_STATUS_MAPPING[this.props.customer.card_status]}
                        />
                        <Button 
                            text="Add Payment Card" 
                            type="primary"
                            disabled={this.props.isLoadingPaymentMethod}
                            onClick={this.props.onUpdatePaymentMethodClicked}
                        />
                    </div>
                );
            case 'valid':
            case 'expiring':
                return (
                    <div>
                        <MyAccountOverviewItem
                            icon={icon}
                            label="Card status"
                            value={CARD_STATUS_MAPPING[this.props.customer.card_status]}
                        />
                        <Button
                            text="Update Payment Card" 
                            type="primary"
                            disabled={this.props.isLoadingPaymentMethod}
                            onClick={this.props.onUpdatePaymentMethodClicked}
                        />
                    </div>
                );
        }

    },

    render: function() {

        if(this.props.isUserBasic || this.props.isAnotherSubscription === true) {
            return(
                <div />
            );
        }

        return (
            <div className="my-account-overview-section">
                <MyAccountOverviewTitle text="Card Details"/>
                <div className="my-account-overview-content">
                    {this.renderCardStatus()}
                </div>
            </div>
        );
    }

});

export default MyAccountOverviewPayment;
