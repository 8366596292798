import React from 'react';
import createReactClass from 'create-react-class';
import classNames from 'classnames';

const Card = function(props) {
	return (
		<div className={classNames('card', props.className)}>
			<div className="card-inner">
				{props.children}
			</div>
		</div>
	);
}


export default Card;