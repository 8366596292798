import React from 'react';
import createReactClass from 'create-react-class';
import classnames from 'classnames';

/*
Props
text: String
*/

var Chip = createReactClass({

    renderCloseButton: function() {
		if (this.props.hasCloseButton === true) {
			return (
				<button className="tag-item-remove" onClick={this.props.onCloseButtonClicked}>
					<i className="icon icon-cross">
					</i>
				</button>
			)
		}
	},

    render: function() {
        return (
            <li role="listitem" className={classnames("chip", this.props.className)} aria-label={this.props.ariaLabel} role={this.props.role} onClick={() => this.props.onClick(this.props.value)}>
                <div className="chip-content">
                    <span>{this.props.text}</span>
                    {this.renderCloseButton()}
                </div>
            </li>
        );
    }

});

export default Chip;