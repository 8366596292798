var updateSubscriptionsSchema = {
    "subscriptionEditors": {
        "type":"SubscriptionQuantity",
        "label": "Editors",
        "validators": [],
        "conditions": [],
        "help": ""
    }
}

module.exports = updateSubscriptionsSchema;