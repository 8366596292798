var updateUserDetailsSchema = {
    "first_name": {
        "type":"Text",
        "label": "First Name",
        "validators": ["required"],
        "conditions": [],
        "help": ""
    },
    "last_name": {
        "type":"Text",
        "label": "Last Name",
        "validators": ["required"],
        "conditions": [],
        "help": ""
    },
    "email": {
        "type":"Text",
        "label": "Email",
        "validators": ["required", "isValidEmail"],
        "conditions": [],
        "help": ""
    }
}

module.exports = updateUserDetailsSchema;