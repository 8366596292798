import React from 'react';
import createReactClass from 'create-react-class';
import {registerType} from '../helpers';
import {FlatButton} from 'uiKit';

var FormFieldNumberVerify = createReactClass({

    getInitialState: function() {
        return {
            valueError: '',
            isDisabled: true,
            value: this.props.value
        }
    },

    renderActions: function() {
        return (
            <div className="form-field-number-verify-actions">
                {this.renderEditAction()}
                {this.renderSaveAction()}
            </div>
        )
    },

    renderEditAction: function() {
        if (this.state.isDisabled) {
            return (
                <FlatButton
                    icon="pencil4"
                    text="edit"
                    onClick={this.onEditClicked}
                />
            );
        }
    },

    renderSaveAction: function() {
        if (!this.state.isDisabled) {
            return (
                <FlatButton
                    icon="floppy-disk"
                    text="save"
                    onClick={this.verifyValue}
                />
            );
        }
    },
    
    onEditClicked: function() {
        this.setState({
            isDisabled: false
        })
    },

    onChange: function(event) {
        var value = parseInt(event.target.value);
        var tooBig = value > this.props.maxValue ? true : false;
        var tooSmall = value < this.props.minValue ? true : false;
        var valueError = tooBig ? `You cannot choose a number larger than ${this.props.maxValue}` : tooSmall ? `You cannot choose a number smaller than ${this.props.minValue}` : ''
        
        if (isNaN(value)) {
            return;
        }

        this.setState({
            value: value,
            valueError: valueError
        })

        if (value < this.props.maxValue && value > this.props.minValue) {
            this.updateField();
        }
    },

    verifyValue: function() {
        var value = this.state.value;
        var tooBig = value > this.props.maxValue ? true : false;
        var tooSmall = value < this.props.minValue ? true : false;
        var valueError = tooBig ? `You cannot choose a number larger than ${this.props.maxValue}` : tooSmall ? `You cannot choose a number smaller than ${this.props.minValue}` : ''

        this.setState({
            value: tooBig ? this.props.maxValue : tooSmall ? this.props.minValue : value,
            valueError: valueError
        })

        var isDisabled = tooBig || tooSmall ? false : true;

        this.setState({
            isDisabled: isDisabled
        })
    },

    getMinValue: function() {
        if (this.props.minValue) {
            if (!isNaN(this.props.minValue)) {
                return this.props.minValue;
            }
        } else if (this.props.minValueModelAttribute) {
            if (this.props.model[this.props.minValueModelAttribute]) {
                return this.props.model[this.props.minValueModelAttribute].length;
            }
        }
    },

    getMaxValue: function() {
        if (this.props.maxValue) {
            if (!isNaN(this.props.maxValue)) {
                return this.props.maxValue;
            }
        } else if (this.props.maxValueModelAttribute) {
            if (this.props.model[this.props.maxValueModelAttribute]) {
                return this.props.model[this.props.maxValueModelAttribute].length;
            }
        }
    },

    updateField: _.throttle(function() {
        this.props.updateField(this.state.value);
    }, 500, {leading: false}),

    render: function() {
        
        return (

            <div className="form-field form-field-number-verify">

                <div className="form-field-label">
                    {this.props.label}
                </div>

                <div className="form-field-help">
                    {this.props.help}
                </div>

                <input 
                    id={this.props.fieldKey}
                    className="form-field-input"
                    type="number"
                    min={this.getMinValue()}
                    max={this.getMaxValue()}
                    value={this.state.value}
                    onChange={this.onChange}
                    onBlur={this.props.onBlur}
                    onFocus={this.props.onFocus}
                    disabled={this.state.isDisabled}
                />
                {this.renderActions()}

                <div className="form-field-error">
                    {this.state.valueError}
                </div>

            </div>

        );

    }

});

registerType('NumberVerify', FormFieldNumberVerify);

export default FormFieldNumberVerify;