import React from 'react';
import classNames from 'classnames';

const ButtonOptionItem = function(props) {
	return (
		<div className="button-options-list-item" onClick={() => props.onClick(props.value)}>
            <i className={classNames('button-icon-position-left button-options-list-item-icon', `icon icon-${props.icon}`)}></i>
			<div className="button-options-list-item-title">
				{props.text}
			</div>
			<span className="button-options-list-item-body">
				{props.body}
			</span>
		</div>
	);
}

export default ButtonOptionItem;