import React from 'react';

const NarrativeIndicators = (props) => {
	var stage = props.stage;
	return (
		<div className="narrative-slider-indicators">
			{_.times(props.length, function(index) {
				var className = "narrative-slider-indicator-item";

				if (stage === index) {
					className = "narrative-slider-indicator-item selected"
				}

				return (
					<div className={className} key={index}>
					</div>
				);
			}
			)}
		</div>
	)
};

export default NarrativeIndicators;