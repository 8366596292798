import React from 'react';
import createReactClass from 'create-react-class';
import classNames from 'classnames';
import {registerType} from '../helpers';
import Field from '../components/field.jsx';
import { Tag } from 'uiKit';
import { motion, AnimatePresence } from "framer-motion"

var FormFieldInputTags = createReactClass({

    getInitialState: function() {
        return {
            value: '',
            isFocused: false
        }
    },
    
    getAutoCompleteMapping: function(value) {
        switch (value) {
            case 'firstName':
                return 'given-name';
            case 'lastName':
                return 'family-name';
            case 'email':
                return 'email';
        }
    },

    onInputFocus: function() {
        this.setState({ isFocused: true });

        if (this.props.onFocus) this.props.onFocus();
    },

    onInputBlur: function() {
        this.setState({ isFocused: false });

        if (this.props.onBlur) this.props.onBlur();
    },

    onChange: function(event) {
        var value = event.target.value;

        this.setState({
            value: value
        });
    },

    onKeyDownPressed: function(event) {    
        if (event.key === 'Enter') {
    
            event.preventDefault();

            this.updateField();
        }
    },

    onCloseButtonClicked: function(index) {
        this.props.value.splice(index, 1);

        _.each(this.props.value, function(item, index) {
            item.itemIndex = index;
        })

        return this.props.updateField(this.props.value);
    },
    
    updateField: function() {
        var items = this.props.value || [];
        
        items.push({ itemIndex: items.length, text: this.state.value });
        
        this.props.updateField(items);

        this.setState({ value: '' });
    },

    renderField: function({ ariaLabelledBy, ariaDescribedBy }) {
        return (
            <div className="form-field-input-tags">
                <div className="form-field-input-tags-input-container">
                    <input 
                        id={this.props.fieldId}
                        className="form-field-input-tags-input"
                        type="text"
                        autoComplete={this.getAutoCompleteMapping(this.props.fieldKey)}
                        aria-labelledby={ariaLabelledBy}
                        aria-describedby={ariaDescribedBy}
                        aria-required={this.props.isRequired}
                        aria-invalid={!!this.props.error}
                        value={this.state.value}
                        onChange={this.onChange}
                        onBlur={() => this.onInputBlur()}
                        onFocus={() => this.onInputFocus()}
                        onKeyDown={(event) => this.onKeyDownPressed(event)}
                    />
                    <AnimatePresence>
                        {this.state.value.length > 1 && (
                            <motion.button
                                animate={{
                                    scale: [1, 1.2, 1.2, 1, 1],
                                    rotate: [0, 0, 270, 270, 0],
                                    borderRadius: ["20%", "20%", "50%", "50%", "20%"],
                                    x: -6
                                }}
                                transition={{ duration: 0.5 }}
                                exit={{ scale: [1, 0.5, 0] }}
                                className="form-field-input-tags-input-add"
                                onClick={() => this.updateField()}>
                                <i className="icon icon-plus-square"></i>
                            </motion.button>
                        )}
                    </AnimatePresence>
                </div>
                <div className={classNames("form-field-input-tags-chips", { "is-focused": this.state.isFocused })}>
                    {_.map(this.props.value, (item) => (
                        <Tag text={item.text} hasCloseButton onClick={() => this.onCloseButtonClicked(item.itemIndex)} />
                    ))}
                </div>
            </div>
        )
    },

    render: function() {
        
        return (
            <Field
                fieldId={this.props.fieldId}
                label={this.props.label}
                help={this.props.help}
                error={this.props.error}>
                {this.renderField}
            </Field>
        );
    }

});

registerType('InputTags', FormFieldInputTags);

export default FormFieldInputTags;