import axios from 'axios';
import {getSockets} from 'sockets';
import Bluebird from 'bluebird';

export const OPEN_SEARCH = 'OPEN_SEARCH';
export const CLOSE_SEARCH = 'CLOSE_SEARCH';
export const FETCH_SEARCH = 'FETCH_SEARCH';
export const FETCH_TOP_SEARCHES = 'FETCH_TOP_SEARCHES';
export const FETCH_RECENT_SEARCHES = 'FETCH_RECENT_SEARCHES';

export function openSearch() {

    return (dispatch) => {

        dispatch({
            type: OPEN_SEARCH
        });
            
    }

}

export function closeSearch() {

    return (dispatch) => {

        dispatch({
            type: CLOSE_SEARCH
        });
            
    }

}

export function fetchRecentSearches() {
    return (dispatch) => {

        return axios.get('api/search/recentSearches')
        .then(function(response) {

            dispatch({
                type: FETCH_RECENT_SEARCHES,
                payload: response.data
            });

            return response;

        });
            
    }
}

export function fetchTopSearches() {
    
    return (dispatch) => {

        return axios.get('api/search/topSearches')
        .then(function(response) {

            dispatch({
                type: FETCH_TOP_SEARCHES,
                payload: response.data
            });

            return response;

        });
            
    }
}

export function fetchSearch(searchText, searchType, filters, location) {
    
    return (dispatch) => {

        return axios.get('api/search', {
            params: {
                location: location,
                searchValue: searchText,
                searchType: searchType,
                filters: filters
            }
        })
        .then(function(response) {

            dispatch({
                type: FETCH_SEARCH,
                payload: response.data
            });

            return response;

        });
            
    }
}