import React from 'react';
import createReactClass from 'create-react-class';
import classnames from 'classnames';
import {registerType} from '../helpers';
import AlertInfo from 'modules/app/components/alertInfo';

var FormFieldAlert = createReactClass({

    render: function() {        
        return (
            <div className="form-field form-field-alert">
                <AlertInfo 
                    text={this.props.label}
                    type={this.props.alertType}
                    icon={this.props.icon}
                />
            </div>
        );
    }

});

registerType('Alert', FormFieldAlert);

export default FormFieldAlert;