import React from 'react';
import createReactClass from 'create-react-class';
import {connect} from 'react-redux';
import {Button} from 'uiKit';
import {registerType} from '../helpers';
import AlertInfo from 'modules/app/components/alertInfo';
import {testEmailCredentials} from 'modules/settings/actions/settingsActions';

var FormFieldHasValidEmailCredentials = createReactClass({

    getInitialState: function() {
        return {
            isSyncing: false
        }
    },

    onButtonClicked: function(event) {
        event.preventDefault();
        this.setState({
            isSyncing: true
        }, () => {
            this.props.testEmailCredentials()
            .then(() => {
                this.setState({
                    isSyncing: false
                });
            })
        });

    },

    getStatusIcon: function() {
        var icon = 'cross-circle';

        if (this.props.value) {
            icon = 'checkmark-circle';
        }

        return icon;
        
    },

    getStatusText: function() {
        var text = this.props.model._credentialsErrorMessage;
        if (this.props.value) {
            text = 'Credentials are valid';
        }
        
        if (!this.props.model._shouldUseCustomEmailSettings) {
            text += ' - Currently using default email settings';
        }

        return text;

    },

    getStatusType: function() {
        var status = 'warning';
        if (this.props.value) {
            status = 'info';
        }
        return status;
    },

    render: function() {
        return (
            <div className="form-field form-field-has-valid-email-credentials">

                <label className="form-field-label">
                    {this.props.label}
                </label>

                <div className="form-field-help">
                    {this.props.help}
                </div>

                <AlertInfo type={this.getStatusType()} text={this.getStatusText()} icon={this.getStatusIcon()}/>

                <Button 
                    onClick={this.onButtonClicked} 
                    icon={this.props.buttonIcon} 
                    type={this.props.buttonType} 
                    text={this.props.buttonText}
                    disabled={this.state.isSyncing}/>

                <div className="form-field-error">
                    {this.props.error}
                </div>

            </div>
        );
    }

});

registerType('HasValidEmailCredentials', connect(function() {
    return {}
}, {
    testEmailCredentials
})(FormFieldHasValidEmailCredentials));

export default FormFieldHasValidEmailCredentials;