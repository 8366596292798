import React from 'react';
import createReactClass from 'create-react-class';
import classnames from 'classnames';
import {Form} from 'modules/form';
import {Button, Card} from 'uiKit';
import AlertInfo from 'modules/app/components/alertInfo';
import LP from 'modules/app/helpers/lp';
import LoginOAuth from 'modules/login/components/loginOAuth';
import Columns from 'modules/app/components/columns';
import ReactGA from 'react-ga';

var RegisterNewUser = createReactClass({

    getClassName: function() {
        return classnames('register', {
            'has-sso': (authentication._shouldAllowOpenRegistrations)
        });
    },

    renderBody: function() {

        if (this.props.hasRegistered) {
            return (
                <Card className="register-new-user-card">
                    {/* TODO */}
                    User added to group. A registration link has been sent to his email.
                </Card>
            );
        }

        var lpString = LP('users', 'registerBody');

        if (lpString) {
            return (
                <div className="register-body" dangerouslySetInnerHTML={{__html: lpString}} />
            )
        }
    },

    renderForm: function(length) {
        var className = "register-new-user-forms";
        
        if (length === 0) {
            var className = "register-new-user-forms oauth-is-not-enabled"
        };

        return (
            <div className="register-new-user-forms">
                <Form
                    model={this.props.user}
                    schema={this.props.schema}
                    onUpdate={this.props.onUpdateField}
                    form={{actions:this.props.formActions}}
                    shouldValidateOnMount={false}
                    onLoad={this.props.onFormLoaded}
                />

                {this.renderAlert()}

                <div className="dialog-actions">
                    <Button 
                        onClick={this.props.onCancelDialog} 
                        className="button alert" 
                        text="CANCEL"
                    />

                    <Button 
                        disabled={this.props.hasError} 
                        className="button primary" 
                        onClick={this.props.onRegisterClicked} 
                        text="ADD"
                    />
                </div>
            </div>
        );
    },

    renderAlert: function() {
        if (this.props.hasError && this.props.errorMessage) {
            return (
                <AlertInfo
                    text={this.props.errorMessage}
                    type="warning"
                    icon="alert"
                />
            );
        }
    },

    renderSSO: function() {
        return (
            <LoginOAuth
            />
        )
    },

    // TODO
    renderColumns: function(length) {
        
        if (this.props.hasRegistered) {
            return null;
        }

        if (authentication._shouldAllowOpenRegistrations && length > 0) {
            return (
                <Columns columns={[this.renderForm(), this.renderSSO()]}/>
            );
        } else {
            return this.renderForm(length);
        }
    },

    render: function() {
        var authArray = [
            {
                type: "GoogleOauth",
                isEnabled: authentication._shouldAllowGoogleOAuth
            },
            {
                type: "LinkedInOauth",
                isEnabled: authentication._shouldAllowLinkedInOAuth,
            },
            {
                type: "TwitterOauth",
                isEnabled: authentication._shouldAllowTwitterOAuth,
            },
            {
                type: "FacebookOauth",
                isEnabled: authentication._shouldAllowFacebookOAuth,
            }
        ];
        
        var filteredAuthArray = _.filter(authArray, (oauth) => {
            return oauth.isEnabled === true;
        });

        var length = filteredAuthArray.length;

        var className = "register-new-user";
        
        if (length === 0) {
            var className = "register-new-user oauth-is-not-enabled"
        };

        var logo = window.generalSettings._logo;
        var shouldShowLogoOnLogin = window.generalSettings._shouldShowLogoOnLogin;
        var styles = {};        

        if (logo && shouldShowLogoOnLogin) {
            styles = {"paddingTop": window.generalSettings._navigationHeight + 'px'}; 
        } else {
            styles = null;
        }

        return (
            <div className="register-container">
                <Card className={this.getClassName()}>
                    <div className={className}>
                        <div className="register-title" style={styles}>
                            Add User To Group
                        </div>
                        {this.renderBody()}
                        {this.renderColumns(length)}
                    </div>
                </Card>
            </div>
        );
    }

});

export default RegisterNewUser;
