var Forms = {};

import FormComponent from './components/form.js';
import FormFieldComponent from './components/formField.js';
import FormSortableComponent from './components/formSortable.js';
import Helpers from './helpers';

Forms.Form = FormComponent
Forms.FormField = FormFieldComponent;
Forms.FormSortable = FormSortableComponent;
Forms.Helpers = Helpers;

if (window.MediumEditor) {
    window.MediumEditor.extensions.anchor.prototype.formSaveLabel = '<i class="icon icon-check"></i>';
    window.MediumEditor.extensions.anchor.prototype.formCloseLabel = '<i class="icon icon-cross"></i>';
}

// Fields
require('./fields/formFieldArray.js');
require('./fields/formFieldBoolean.js');
require('./fields/formFieldBorder.js');
require('./fields/formFieldButton.js');
require('./fields/formFieldColorPicker.js');
require('./fields/formFieldCssValue.js');
require('./fields/formFieldNumber.js');
require('./fields/formFieldObject.js');
require('./fields/formFieldPassword.js');
require('./fields/formFieldPicker.js');
require('./fields/formFieldSelect.js');
require('./fields/formFieldSpacing.js');
require('./fields/formFieldText.js');
require('./fields/formFieldTextArea.js');
require('./fields/formFieldTextAreaPlain.js');
require('./fields/formFieldTextAlign.js');

// Validators
require('./validators/required');
require('./validators/number');

export default Forms;