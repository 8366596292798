import React, {useState} from 'react';
import Button from '../../buttons/components/button';
import classNames from 'classnames';

const Content = (props) => {
    
    var closeDropDown = () => {
        props.setIsOpen(true);
    };

    return (
        <div className="drop-down-content">
            {React.cloneElement(props.component, {
                closeDropDown: closeDropDown
            })}
        </div>
    )
};

const DropDown = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    var onDropDownClicked = () => {
        if (event) {
            event.preventDefault();
        }
        setIsOpen(!isOpen);
    };

    var nameOfClass = classNames('drop-down', props.className);

    return (
        <div className={nameOfClass}>
            <Button onClick={onDropDownClicked} icon={props.icon} text={props.text} />
            {isOpen && <Content {...props} setIsOpen={setIsOpen} />}
        </div>
    );
}

export default DropDown;