import React from 'react';
import createReactClass from 'create-react-class';
import classNames from 'classnames';
import _ from 'lodash';
import classnames from 'classnames';

var SearchBarFilterItem = createReactClass({

    getInitialState: function() {
        return {
            shouldRenderFilterItem: true
        }
    },

    componentDidUpdate: function(prevProps) {
        if (this.props.activeTab !== prevProps.activeTab) {
            this.setState({
                shouldRenderFilterItem: false
            })
        } else {
            if (!this.state.shouldRenderFilterItem && !this.props.isSyncing) {
                this.setState({
                    shouldRenderFilterItem: true
                })
            }
        }
    },

    handleKeyDown: function(event) {
        if (event.keyCode === 13 || event.keyCode === 32) {
            this.props.onFilterMenuItemClick(event);
        }
    },

    renderFilterMenuItems: function() {
        var {filterMenuItems, filterTitle} = this.props;
        return _.map(filterMenuItems, (filterMenuItem) => {
            return (
                <li
                    key={filterMenuItem.text}
                    value={filterMenuItem.value}
                    filtertype={filterTitle}
                    className={classNames("search-bar-filter-item-list-option", filterMenuItem.isActive ? 'is-active' : '')}
                    onClick={(event) => {this.props.onFilterMenuItemClick(event)}}
                    onKeyDown={(event) => {this.handleKeyDown(event)}}
                >
                    <i
                        className={classNames('search-bar-filter-item-list-option-icon icon', filterMenuItem.isActive ? 'icon-check-square' : 'icon-square')}
                        role="checkbox"
                        tabIndex="0"
                        aria-labelledby={filterMenuItem.value}
                        aria-checked={filterMenuItem.isActive}
                        checked={filterMenuItem.isActive}
                    />
                    <label id={filterMenuItem.value}>
                        {filterMenuItem.text}
                    </label>
                </li>
            )
        })
    },

    render: function() {
        if (this.props.filterMenuItems.length === 0) {
            return null;
        }

        return (
            this.state.shouldRenderFilterItem && <div key={this.props.filterTitle} className={classnames("search-bar-filter", (this.state.shouldRenderFilterItem) ? 'fade-in' : '')}>
                <p className="search-bar-filter-item-title">{this.props.filterTitle.replace(/_/g, "").replace(/([A-Z])/g, ' $1')}</p>
                <ul className="search-bar-filter-item-list">
                    {this.renderFilterMenuItems()}
                </ul>
            </div>
        )
    }
})
export default SearchBarFilterItem;