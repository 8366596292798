import React from 'react';
import createReactClass from 'create-react-class';
import {DialogTitle, DialogBody, DialogActions} from './dialogComponents.js';

var RadioDialog = createReactClass({
    
    getInitialState: function() {
        var _radioButtons = this.props.options.radioButtons;
        var selectedValue = null;
        for(var radioButton in _radioButtons) {
            if(_radioButtons.hasOwnProperty(radioButton)) {
                if(_radioButtons[radioButton].selected === true) {
                    selectedValue = radioButton;
                    break;
                }
            }
        }
        return{
            _selectedValue : selectedValue
        }    
    },
    
    //DOM EVENTS
    //=========
     handleDialogAction: function(action) {
		this.props.commitAction({
			action: action,
			payload: this.state._selectedValue
		});
	},
    
    handleRadioButtonValueChange: function (event) {
        this.setState({_selectedValue : event.target.value})
    },
    
    // RENDER
    //=======
    renderRadioButtons: function () {
        var _radioButtons = this.props.options.radioButtons;       
        var _renderRadioButtons = [];

        for(var radioButton in _radioButtons) {
           if(_radioButtons.hasOwnProperty(radioButton)) {
               _renderRadioButtons.push(
                    <div key={radioButton} className="dialog-option">
                        <input
                            id={radioButton}                        
                            type="radio"
                            value={radioButton} 
                            name="options" 
                            checked={radioButton === this.state._selectedValue}
                            onChange={this.handleRadioButtonValueChange}/>
                        <label htmlFor={radioButton}>{_radioButtons[radioButton].label}</label>
                    </div>
                ); 
           }                       
        }
        
        return _renderRadioButtons;
    },
    
	render: function() {
		return (
			<div className="dialog radio-dialog" aria-modal role="dialog" tabIndex="0" aria-label={this.props.title}>
				<DialogTitle title={this.props.title}/>
				<DialogBody body={this.props.body}/>
                <div className="dialog-options-container">
                    {this.renderRadioButtons()}
                </div>
				<DialogActions 
                    onDialogAction={this.handleDialogAction} 
					actions={this.props.actions}/>
			</div>
		);
	}

});

export default RadioDialog;