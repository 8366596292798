import React from 'react';
import createReactClass from 'create-react-class';
import {DialogTitle, DialogBody, DialogActions} from './dialogComponents.js';

var SelectDialog = createReactClass({
    
    getInitialState : function () {
        var _selectListValues = this.props.options.selectListValues;
        var selectedValue = null;
        for(var option in _selectListValues) {
            if(_selectListValues.hasOwnProperty(option)) {
                if(_selectListValues[option].selected === true) {
                    selectedValue = option;
                    break;
                }
            }
        }
        return{
            _selectedValue : selectedValue
        }  
    },
    
    // DOM Events
    //==========
    handleDialogAction: function(action) {
		this.props.commitAction({
			action: action,
			payload: this.state._selectedValue
		});
	},
    
    handleSelectListValueChange: function(event) {
        this.setState({_selectedValue : event.target.value})
    },
    
    
    // Render
    //=======
    renderOptions: function () {
        var _selectListValues = this.props.options.selectListValues;
        var _renderOptions = [];
        for(var option in _selectListValues) {
            if(_selectListValues.hasOwnProperty(option)) {
                _renderOptions.push(
                   <option key={option} value={option}>                   
                        {_selectListValues[option].displayName || option}
                    </option> 
                )
            }
        }
        
        return _renderOptions;
    },
    
	render: function() {
		return(
			<div className="dialog select-dialog" aria-modal role="dialog" tabIndex="0" aria-label={this.props.title}>
                <DialogTitle title={this.props.title}/>
                <DialogBody body={this.props.body}/>
                <div className="dialog-options-container">
                    <select className="dialog-select" value={this.state._selectedValue} onChange={this.handleSelectListValueChange}>
                        {this.renderOptions()}
                    </select>
                </div>
                <DialogActions 
                    onDialogAction={this.handleDialogAction} 
					actions={this.props.actions}/>
			</div>
		);
	}
})

export default SelectDialog;