import React from 'react';
import createReactClass from 'create-react-class';
import LP from 'modules/app/helpers/lp';

var LoginPasswordReset = createReactClass({

    render: function() {

        const defaultHref = `${window.location.origin}/passwordReset`;
        const groupQuery = window.location.pathname.split('/')[2];
        const href = groupQuery ? `${defaultHref}/${groupQuery}` : defaultHref;

        return (
            <div className="login-password-reset">
                <p aria-hidden>{LP('login', 'forgotPasswordText', 'titlecase')}</p>
                <a
                    href={href}
                    aria-label={`${LP('login', 'forgotPasswordText', 'titlecase')} ${LP('login', 'forgotPasswordLinkText', 'titlecase')}`}>
                    {LP('login', 'forgotPasswordLinkText', 'titlecase')}
                </a>
            </div>
        );
    }

});

export default LoginPasswordReset;