import React from 'react';
import createReactClass from 'create-react-class';
import {connect} from 'react-redux';
import Store from 'store';
import Popup from '../components/popup';
import {
    enableNextButton,
    disableNextButton,
    moveToNextPopup,
    moveToPreviousPopup,
    storePopupData
} from '../actions/popupActions';

var PopupContainer = createReactClass({

    enableNextButton: function() {
        this.props.enableNextButton();
    },

    disableNextButton: function () {
        this.props.disableNextButton();
    },

    onNextButtonClicked: function(event) {
        event.target.blur();
        
        var { popup, options, commitAction, moveToNextPopup } = this.props;
        
        var currentPopup = options.popups[popup._stage];
        var state = Store.getState();
        var popupModel = popup._data[`${popup._stage}`];

        currentPopup.onNextButtonClicked(state, popupModel)
        .then(() => {

            if (popup._stage === options.popups.length - 1) {
                commitAction({
                    action: {}
                });
                return;
            }
            moveToNextPopup();

        })

    },

    onPreviousButtonClicked: function(event) {
        event.target.blur();
        this.props.moveToPreviousPopup();
    },

    storePopupData: function(data) {
        this.props.storePopupData(data);
    },

    render: function() {
        
        var {popup, options} = this.props;

        return (
            <Popup 
                {...options} 
                stage={popup._stage}
                isNextButtonEnabled={popup._isNextButtonEnabled}
                enableNextButton={this.enableNextButton}
                disableNextButton={this.disableNextButton}
                onNextButtonClicked={this.onNextButtonClicked}
                onPreviousButtonClicked={this.onPreviousButtonClicked}
                storePopupData={this.storePopupData}
            />
        );

    }
})

export default connect(function(state) {
    return {
        popup: state.popup
    }   
}, {
    enableNextButton,
    disableNextButton,
    moveToNextPopup,
    moveToPreviousPopup,
    storePopupData
})(PopupContainer);