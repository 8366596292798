import store from 'store';

export default function() {
    var state = store.getState();

    var height = window.generalSettings._navigationHeight;
    if (window.generalSettings._shouldAlwaysShowSearch) {
        height = window.generalSettings._navigationHeight + 40;
    }

    var header = state.app._header;
    var headerHeight = state.app._headerHeight;

    if (header && header.isFixed) {
        height += headerHeight;
    }
    return height;
}