import React from 'react';

const Chip = function(props) {
    return (
        <div className={`chip ${props.type}`}>
            {props.text}
        </div>
    );
}

export default Chip;