import React from 'react';
import createReactClass from 'create-react-class';
import {registerType} from '../helpers';
import Field from '../components/field.jsx';

var FormFieldText = createReactClass({

    getInitialState: function() {
        return {
            value: this.props.value || ''
        }
    },
    
    componentDidUpdate: function(prevProps, prevState) {
        if (prevState.value !== this.state.value) {
            this.props.updateField(this.state.value);
        }
        return;
    },
    
    getAutoCompleteMapping: function(value) {
        switch (value) {
            case 'firstName':
                return 'given-name';
            case 'lastName':
                return 'family-name';
            case 'email':
                return 'email';
        }
    },

    onChange: function(event) {
        var value = event.target.value;

        this.setState({
            value: value
        });

        this.updateField()

    },

    updateField: _.debounce(function() {
        this.props.updateField(this.state.value);
    }, 300, {leading: false}),

    renderField: function({ ariaLabelledBy, ariaDescribedBy }) {
        return (
            <input 
                id={this.props.fieldId}
                className="form-field-input"
                type="text"
                autoComplete={this.getAutoCompleteMapping(this.props.fieldKey)}
                aria-labelledby={ariaLabelledBy}
                aria-describedby={ariaDescribedBy}
                aria-required={this.props.isRequired}
                aria-invalid={!!this.props.error}
                value={this.state.value}
                onChange={this.onChange}
                onBlur={this.props.onBlur}
                onFocus={this.props.onFocus} />
        )
    },

    render: function() {
        
        return (
            <Field
                fieldId={this.props.fieldId}
                label={this.props.label}
                help={this.props.help}
                error={this.props.error}>
                {this.renderField}
            </Field>
        );
    }

});

registerType('Text', FormFieldText);

export default FormFieldText;