import React from 'react';
import createReactClass from 'create-react-class';
import {registerType} from 'modules/form/helpers';
import {Button} from 'uiKit';

var FormFieldFileImport = createReactClass({

    getInitialState: function() {
        return {
            file: null
        }
    },

    onFileUploaded: function(file) {
        this.setState({
            file: file
        });
    },

    onSubmitClicked: function() {
        if (this.state.file) {
            this.props.onUpdate(this.state.file);
        }
    },

    render: function() {
        
        var isDisabled = this.state.file === null ? true : false;

        return (
            <div className="form-field-file-import form-field">

                <div className="form-field-file-import-content">
                    <div className="form-field-divider-title">
                        {this.props.label}
                    </div> 

                    <div className="form-field-help">
                        {this.props.help}
                    </div>
                    
                </div>

                <div className="form-field-file-import-action">
                    <input 
                        type="file"
                        onChange={(event) => this.onFileUploaded(event.target.files)}
                    />

                    <Button
                        className="form-field-file-import-submit-button" 
                        text="Submit"
                        type="primary"
                        onClick={this.onSubmitClicked}
                        disabled={isDisabled}
                    />

                </div>

            </div>
        );
    }
})

registerType('FileImport', FormFieldFileImport);
export default FormFieldFileImport;
