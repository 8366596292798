import React from 'react';
import createReactClass from 'create-react-class';
var classNames = require('classnames');
var setAriaLiveRegion = require('bloom').setAriaLiveRegion;

var AlertInfo = createReactClass({

	componentDidMount: function() {
		setAriaLiveRegion({ ariaLiveType: "assertive", ariaLiveText: "" });
	},

	getClassName: function() {
		if(!this.props.icon) {
			return classNames('alert-info no-icon' + ' ' + this.props.type + ' ' + this.props.className);
		} else {
			return classNames('alert-info' + ' ' + this.props.type + ' ' + this.props.className);
		}
	},

	getIconClassName: function() {
		return 'icon icon-' + this.props.icon;
	},

	render: function() {
		return (
			<div role="alert" className={this.getClassName()}>
				<div className="alert-info-inner" role="text" aria-label={this.props.text}>
					<i className={this.getIconClassName()}></i>
					<span onClick={this.props.onClick} dangerouslySetInnerHTML={{__html: this.props.text }} aria-hidden/>
				</div>
			</div>
		);
	}
});

module.exports = AlertInfo;