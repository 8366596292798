var adminAddUserToGroupSchema = {
    "manager_chargebee_user_id": {
        "type":"Text",
        "label": "Manager - Chargebee User ID",
        "validators": ["required"],
        "conditions": [],
        "help": ""
    },
    "user_chargebee_user_id": {
        "type":"Text",
        "label": "User - Chargebee User ID",
        "validators": ["required"],
        "conditions": [],
        "help": ""
    }
}

module.exports = adminAddUserToGroupSchema;
