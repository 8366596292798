import React, {Fragment} from 'react';
import createReactClass from 'create-react-class';
import SearchBarResultItem from './searchBarResultItem';
import Chip from 'modules/app/components/chip';
import AlertInfo from 'modules/app/components/alertInfo';
import {FlatButton} from 'uiKit';
import Tabs from 'modules/app/components/tabs';
import SearchBarFilters from './searchBarFilters';
import LP from 'modules/app/helpers/lp';

var SearchBarResults = createReactClass({

    getInitialState: function() {
        return {
            showFilters: false
        }
    },

    getTabItems: function() {

        var tabItems = [];

        var {librarySettings, isAdmin} = this.props;

        tabItems.push({
            icon: 'papers',
            label: LP('global', 'all', 'titlecase'),
            value: 'all',
            component: <div></div>
        })

        if (librarySettings._shouldDisplayCourses) {
            tabItems.push({
                icon: 'document2',
                label: LP('courses', 'courses', 'titlecase'),
                value: 'courses',
                component: <div></div>
            });
        }

        if (librarySettings._shouldDisplayTrails) {
            tabItems.push({
                icon: 'book2',
                label: LP('trails', 'trails', 'titlecase'),
                value: 'trails',
                component: <div></div>
            });
        }

        if (librarySettings._shouldDisplaySubjects) {
            tabItems.push({
                icon: 'library',
                label: LP('subjects', 'subjects', 'titlecase'),
                value: 'subjects',
                component: <div></div>
            });
        }

        if (isAdmin) {
            tabItems.push({
                icon: 'users',
                label: 'Groups',
                value: 'groups',
                component: <div></div>
            });
            tabItems.push({
                icon: 'user',
                label: 'Users',
                value: 'users',
                component: <div></div>
            })
        }

        return tabItems;

    },

    renderTabs: function() {
        return (
            <Tabs
                items={this.getTabItems()}
                value={this.props.activeTab}
                onChange={this.props.onTabChanged}
            />
        );
    },

    renderRecentSearches: function() {
        if (this.props.recentSearches.length === 0) {
            return null;
        }
        
        return (
            <div className="search-bar-result-recent-searches-section">
                <p className="search-bar-results-recent-searches-title">{LP('search', 'recentSearches', 'sentencecase')}:</p>
                <ul className="search-bar-results-recent-searches-list">
                    {_.map(this.props.recentSearches, search => {
                        return (
                            <Chip key={search.searchValue} role="button" text={search.searchValue} onClick={() => {this.props.onRecentSearchResultChipClicked(search.searchValue)}} />
                        )
                    })}
                </ul>
            </div>
        )
    },

    renderSearchResults: function() {

        if (!this.props.searchResults) {
            return;
        }

        if (this.props.searchResults.length === 0 && !this.props.isSyncing) {
            return (
                <Fragment>
                    <div className='search-bar-results-none'>
                        <AlertInfo
                            text="No results found"
                            type="info"
                        />
                    </div>
                </Fragment>
            );
        } else {
            return (
                <div style={{overflowY: 'scroll', height: '95%'}}>
                    <ul className='search-bar-results-list'>
                        {_.map(this.props.searchResults, (result, index) => {
                            return (
                                <SearchBarResultItem
                                    activeTab={this.props.activeTab}
                                    isAdmin={this.props.isAdmin}
                                    setRenderLoadingFalse={this.setRenderFalse}
                                    shouldShowResult={!this.props.isSyncing}
                                    key={result._id}
                                    index={index}
                                    result={result}
                                    onResultClicked={this.props.onResultClicked}
                                />

                            );
                        })}
                    </ul>
                </div>
            );
        }
    },

    handleFilterButtonClick: function() {
        this.setState({
            showFilters: !this.state.showFilters
        })
    },

    render: function() {
        return (
            <Fragment>
                <div className='search-bar-results-content'>
                    <div className="search-bar-results-count">
                        {(this.props.count || 0) + ' results'}
                    </div>
                    <i
                        role={window.innerWidth >= 762 ? "heading" : "button"}
                        className="search-bar-results-list-filters-icon icon icon-equalizer"
                        aria-label={LP('search', 'searchFilterAriaLabel')}
                        onClick={() => {this.handleFilterButtonClick()}}
                    />
                    <SearchBarFilters
                        displayOnMobile={this.state.showFilters}
                        isSyncing={this.props.isSyncing}
                        filters={this.props.filters}
                        activeTab={this.props.activeTab}
                        onFilterClick={this.props.updateFilter}
                        onUpdateFilter={this.props.updateFilter}
                    />
                </div>
                <div className='search-bar-results-content results'>
                    {this.renderRecentSearches()}
                    {this.renderTabs()}
                    {this.renderSearchResults()}
                </div>
            </Fragment>
        );
    }
})
export default SearchBarResults;