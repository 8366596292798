import React, { Fragment } from 'react';
import createReactClass from 'create-react-class';
import {FlatButton} from 'uiKit';

var FormFieldListToFormHeader = createReactClass({
    
    renderBackButton: function() {
        if (this.props.selectedItemId) {
            return (
                <FlatButton
                    className="form-field-list-to-form-back-button" 
                    text="Back" 
                    icon="arrow-left-circle" 
                    type="primary"
                    onClick={this.props.onBackButtonClicked}/>
            )
        }
    },

    renderBreadCrumbs: function() {
        if (this.props.selectedItemId) {
            return (
                <span className="form-field-list-to-form-bread-crumbs">
                    {this.renderSelectedItemBreadCrumb()}
                    {this.renderSelectedSubItemBreadCrumb()}
                </span>
            )
        }
    },

    renderSelectedItemBreadCrumb: function() {
        if (this.props.selectedItemId) {
            var item = _.find(this.props.value, { _id: this.props.selectedItemId });
            var text = item[this.props.itemTextAttribute];
            return (
                <FlatButton type="primary" disabled={(!this.props.selectedSubItemKey)} text={text} onClick={this.props.onBackButtonClicked} />
            )
        }
    },

    renderSelectedSubItemBreadCrumb: function() {
        if (this.props.selectedSubItemKey) {
            var item = this.props.subSchema[this.props.selectedSubItemKey];
            return (
                <span>
                    <span> > </span>
                    <FlatButton disabled={true} text={item.label}/>
                </span>
            )
        }
    },

    renderAddButton: function() {
        if (!this.props.selectedItemId) {
            if (!this.props.shouldShowAddForm) {

                return (
                    <Fragment>
                        <FlatButton 
                            className="form-field-list-to-form-add-button" 
                            text={'Import language pack'}
                            icon="upload"
                            type="primary"
                            onClick={this.props.onImportClicked}
                        />
                        <FlatButton
                            className="form-field-list-to-form-add-button" 
                            text={this.props.addButtonText}
                            icon="plus-circle"
                            type="primary"
                            onClick={this.props.onAddButtonClicked}
                        />
                    </Fragment>
                );

            } else {

                return (
                    <FlatButton
                        className="form-field-list-to-form-add-button"
                        text={'Cancel'}
                        type="primary"
                        onClick={this.props.onCancelButtonClicked}
                    />
                );

            }
        } else if (this.props.selectedItemId) {
            return (
                <FlatButton
                    className="form-field-list-to-form-add-button" 
                    text={'Export language pack'}
                    icon="download2"
                    type="primary"
                    onClick={this.props.onExportButtonClicked}
                />
            );
        }
        
    },

    render: function() {

        return (
            <div className="form-field-list-to-form-header">
                {this.renderBackButton()}
                {this.renderBreadCrumbs()}
                {this.renderAddButton()}
            </div>
        );
    }
})

export default FormFieldListToFormHeader;