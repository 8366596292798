import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
    Loading
} from 'uiKit';
import {
    PageLoader
} from 'modules/app';
import {updateHeader} from 'modules/app/actions/appActions';
import LP from 'modules/app/helpers/lp';
import handleContainerError from 'modules/app/helpers/handleContainerError';
import {
    fetchUsers,
    adminAddUserToSubscriptionGroup,
    fetchUserNameByChargebeeUserId,
    uploadCsvSubscriptionGroup
} from '../duxUsers';
import UsersOverview from '../components/usersOverview';
import {addDialog, addToast} from 'modules/notifications/actions/notifications';
import MyAccountAdminAddUserToSubscriptionGroupDialog from '../components/myAccountAdminAddUserToSubscriptionGroupDialog';
import adminAddUserToGroupSchema from '../schemas/adminAddUserToGroupSchema';
import csvUploadSchema from '../schemas/csvUploadSchema';
import showErrorDialog from '../helpers/showErrorDialog';
import myAccountImportUsersDialog from '../components/myAccountImportUsersDialog';

const PageContainer = createReactClass({

    contextTypes: {
        router: PropTypes.object
    },

    getInitialState: function() {
        return {
            _hasDataLoaded: false,
            _isSyncing: true,
            _isLoadingUsers: false,
            _statusCode: null,
            errorMessage: '',
            _currentPage: 1,
            _totalPages: 1,
            _usersInTrial: false,
            _usersFilter: ''
        }
    },

    componentDidMount: function() {
        // TODO golive
        console.log( 'KLCv20221019' );

        this.updateHeader();
        this.fetchData(this.state._currentPage, this.state._usersInTrial);
    },

    onPreviousPageClicked: function() {
        this.fetchData(this.state._currentPage - 1, this.state._usersInTrial);
    },

    onNextPageClicked: function() {
        this.fetchData(this.state._currentPage + 1, this.state._usersInTrial);
    },

    onFetchData: function(newUsersInTrial) {
        this.fetchData(this.state._currentPage, newUsersInTrial);
    },

    fetchData: function(currentPage, usersInTrial) {
        this.setState({
            _isSyncing: true
        });
        return this.props.fetchUsers("", currentPage, usersInTrial)
        .then((response) => {
            this.setState({
                _isSyncing: false,
                _hasDataLoaded: true,
                _currentPage: response.data._currentPage,
                _totalPages: response.data._totalPages,
                _usersInTrial: response.data._usersInTrial === 'true'
            });
        })
        .catch((response) => {
            handleContainerError(response, this);
        });
    },

    updateHeader: function() {
         this.props.updateHeader({
             breadcrumbs: [
                 {
                     text: 'Users List'
                 }
             ]
        });
    },

    onImportUsersToSubscriptionGroupClicked: function() {
        var importUsersDialog = {
            dialogType: 'default',
            title: 'Import Users',
            body: '',
            options: {
                uploadCsv: this.props.uploadCsvSubscriptionGroup,
                schema: csvUploadSchema,
                autoEnrollGroups: this.state._autoEnrollGroups
            },
            customDialog: myAccountImportUsersDialog,
            actions: [
                {
                    action: 'upload',
                    buttonText: 'UPLOAD',
                    buttonType: 'primary'
                },
                {
                    action: 'cancel',
                    buttonText: 'CANCEL',
                    buttonType: 'alert'
                }
            ]
        };

        //TODO - Error Handling
        this.props.addDialog(importUsersDialog)
        .then(response => {

            var actionToast = {
                title: 'No users were added'
            };
    
            if (response.action === 'done') {
                this.fetchData(1);
                const actionToast = {
                    title: 'Users have been added'
                };

                this.props.addToast(actionToast);
            }
            
        });
    },

    // onAdminAddUserToSubscriptionGroupClicked: function() {
        
    //     var customDialog = {      
    //         customDialog: MyAccountAdminAddUserToSubscriptionGroupDialog,
    //         title: 'Update Account Details',
    //         body: '',
    //         options: {
    //             schema: adminAddUserToGroupSchema     
    //         },
    //         actions: [
    //         {
    //             action: 'cancel',
    //             buttonText: 'CANCEL',
    //             buttonType: 'alert',
    //         },
    //         {
    //             action: 'success',
    //             buttonText: 'ADD',
    //             buttonType: 'primary',
    //             getIsDisabled: function (props) {
    //                 if (props.model.hasError) return true;
    //             }
    //         }
    //     ]
    //     }

    //     var successDialog = {
    //         dialogType: 'default',
    //         title: 'User in group',
    //         body: 'User has been added to the Subscription Group successfully.',
    //         actions: [
    //             {
    //                 action: 'ok',
    //                 buttonText: 'OK',
    //                 buttonType: 'info'
    //             }
    //         ]
    //     };

    //     this.props.addDialog(customDialog)
    //     .then((response) => {
    //         if (response.action === 'success') {
    //             return this.setState({
    //                 _isSyncing: true
    //             }, function() {
    //                 return this.props.adminAddUserToSubscriptionGroup(response.payload)
    //                     .then(this.fetchData(1))
    //                     .then(() => {
    //                         return this.props.addDialog(successDialog)
    //                     })
    //                     .catch(showErrorDialog)
    //                     .finally(() => {
    //                         this.setState({
    //                             _isSyncing: false
    //                         });
    //                 });
    //             });
    //         }
    //     });
    // },

    render: function() {

        return (
            <PageLoader 
                hasLoaded={this.state._hasDataLoaded} 
                hasErrored={this.state._hasErrored}
                statusCode={this.state._statusCode}
                errorMessage={this.state.errorMessage}
                text="Loading"
                >
                <UsersOverview
                    users={this.props.users}
                    isSyncing={this.state._isSyncing}
                    onPreviousPageClicked={this.onPreviousPageClicked}
                    onNextPageClicked={this.onNextPageClicked}
                    onFetchData={this.onFetchData}
                    currentPage={this.state._currentPage}
                    totalPages={this.state._totalPages}
                    usersInTrial={this.state._usersInTrial}
                    // onAdminAddUserToSubscriptionGroupClicked={this.onAdminAddUserToSubscriptionGroupClicked}
                    onImportUsersToSubscriptionGroupClicked={this.onImportUsersToSubscriptionGroupClicked}
                />
            </PageLoader>
        );
    }

});

export default connect(function(state, props) {
    return {
        users: state.myKinderlyUsers._users
    }
}, {
    uploadCsvSubscriptionGroup,
    updateHeader,
    fetchUsers,
    fetchUserNameByChargebeeUserId,
    addToast,
    addDialog,
    adminAddUserToSubscriptionGroup,
})(PageContainer);
