import React from 'react';
import createReactClass from 'create-react-class';
import FormField from './formField.jsx';
import {getValidator} from '../helpers';

var Form = createReactClass({

    getInitialState: function() {

        var state = {
            hasError: false,
            fields: {}
        };

        _.each(this.props.schema, function(schemaItem, key) {
            state.fields[key] = {
                hasError: true
            }
        });

        return state;

    },

    componentDidMount: function() {
        this.hasLoaded = false;
        if (this.props.shouldValidateOnMount) {
            this.validateFormFields();
        }
    },

    componentWillReceiveProps: function(nextProps) {
        if (this.props.schema != nextProps.schema) {
            if (this.props.shouldValidateOnMount) {
                this.validateFormFields(nextProps.schema);
            }
        }
    },

    validateFormFields: function(schema) {

        if (schema) {
            _.each(schema, (schemaItem, key) => {
                var model = this.props.model || {};
                var value = model[key];
                
                this.validateValue(value, key, schemaItem.validators, model);
            });
        }

        this.validateForm(this.state.fields);

    },

    validateValue: function(value, key, validators, model) {
        
        var hasError = false;

        _.each(validators, (validator) => {
            if (hasError) return;
            hasError = getValidator(validator)(model, key, value);
        });

        if (hasError) {
            
            var fieldObject = {[key]: {hasError: true, errorMessage: hasError}};
            var newState = _.extend(this.state.fields, fieldObject);
            this.setState({fields: newState});

        }

    },

    validateForm: function(newState) {
        
        var hasErrorField = _.find(newState, function(field, fieldKey) {
            if (field.hasError) {
                return field;
            }
        });

        var hasError = true;

        if (!hasErrorField) {
            hasError = false;
        }

        this.setState({hasError: hasError}, function() {
            if (!this.hasLoaded) {
                if (this.props.onLoad) this.props.onLoad(this.state);            
                this.hasLoaded = true;
            }
        });

    },

    onUpdate: function(attributes, hasError, fieldKey) {

        var newFieldObject = {};
        newFieldObject[fieldKey] = {hasError: !!hasError, errorMessage: hasError};

        var newState = _.extend(this.state.fields, newFieldObject);

        this.setState({fields: newState});

        this.validateForm(newState);

        return this.props.onUpdate(attributes, hasError, fieldKey, this.state);

    },

    renderTitle: function() {        
        if (this.props.title) {
            return (
                <h1 className="form-title">
                    {this.props.title}
                </h1>
            )
        }

        return null;

    },

    renderFields: function() {
    
        return _.map(this.props.schema, (schemaItem, key) => {
            var model = this.props.model || {};
            var value = model[key];

            return (
                <FormField
                    key={key}
                    {...schemaItem}
                    fieldKey={key}
                    fieldId={this.props.fieldId ? `${this.props.fieldId}.${key}` : key}
                    value={value}
                    model={this.props.model}
                    form={this.props.form}
                    onUpdate={this.onUpdate}
                    shouldValidateOnMount={this.props.shouldValidateOnMount}
                />
            );
        });
    },

    render: function() {

        var Element = (this.props.isNested) ? 'div' : 'form';

        return (
            <Element className="form" onSubmit={(event) => {
                event.preventDefault();
                return false;
            }}>
                {this.renderTitle()}
                {this.renderFields()}
            </Element>
        );
    }

});

export default Form;