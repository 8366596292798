import React from 'react';
import createReactClass from 'create-react-class';
import {registerType} from '../helpers';
import Field from '../components/field.jsx';
import ToggleButton from 'modules/app/components/toggleButton'

const ENTER_KEY = 13;
const SPACE_KEY = 32;

var FormFieldToggleButton = createReactClass({

    onChange: function(value) {
        this.props.updateField(value);
    },

    onKeyPress: function({ which }) {
        if (which === ENTER_KEY || which === SPACE_KEY) {
            this.onChange();
        }
    },

    renderField: function({ ariaDescribedBy }) {        
        if (!this.props.options) {
            console.warn(`Form field - ${this.props.fieldId} does not have any options passed in`);
        }

        return (
            <ToggleButton 
                toggles={this.props.options}
                value={this.props.value}
                onClick={this.onChange}
            />
        );
    },

    render: function() {
        return (
            <Field 
                fieldId={this.props.fieldId}
                label={this.props.label}
                help={this.props.help}
                error={this.props.error}>
                {this.renderField}
            </Field>
        );
    }

});

registerType('ToggleButtonField', FormFieldToggleButton);

export default FormFieldToggleButton;