import React from 'react';
import createReactClass from 'create-react-class';
import {DialogTitle, DialogBody, DialogActions} from './dialogComponents.js';

const CheckDialog = createReactClass({
    
    getInitialState: function() {
        var state = {};
        for(var checkBox in this.props.options.checkBoxes) {
            if(this.props.options.checkBoxes.hasOwnProperty(checkBox)) {
               state[checkBox] = this.props.options.checkBoxes[checkBox].selected || false 
            }           
        }
        return state
    },
    
    // DOM EVENTS
    //==========
    handleDialogAction: function(action) {
		this.props.commitAction({
			action: action,
			payload: this.state
		});
	},
    
    handleCheckBoxValueChange: function (event) {
        this.setState({[event.target.name] : event.target.checked})
    },
    
    // RENDER
    //========
    
    renderCheckBoxes: function () {
        var _checkBoxes = this.props.options.checkBoxes;       
        var _renderCheckBoxes = [];

        for(var checkBox in _checkBoxes) {
           if(_checkBoxes.hasOwnProperty(checkBox)) {
               _renderCheckBoxes.push(
                    <div key={checkBox} className="dialog-option">
                        <input
                            id={checkBox}                        
                            type="checkbox" 
                            name={checkBox} 
                            checked={this.state[checkBox]}
                            onChange={this.handleCheckBoxValueChange}/>
                        <label htmlFor={checkBox}>{_checkBoxes[checkBox].label}</label>
                    </div>
                ); 
           }                      
        }
        
        return _renderCheckBoxes;
    },
    
	render: function() {
		return (
			<div className="dialog check-dialog" aria-modal role="dialog" tabIndex="0" aria-label={this.props.title}>
				<DialogTitle title={this.props.title}/>
				<DialogBody body={this.props.body}/>
                <div className="dialog-options-container">
                    {this.renderCheckBoxes()}
                </div>
				<DialogActions 
                    onDialogAction={this.handleDialogAction}
                    actions={this.props.actions}/>
			</div>
		);
	}

});

export default CheckDialog;