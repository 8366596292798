import LP from 'modules/app/helpers/lp';
import {registerValidator} from '../helpers';

var required = function(model, attribute, value, label) {
    if (value || value && value.length) {
        return false;
    } else {
        return LP('global', 'fieldRequired', null, { field: label });
    }
}

registerValidator('required', required);

export default required;