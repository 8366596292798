import React from 'react';
import createReactClass from 'create-react-class';
import {DialogTitle, DialogBody} from 'modules/notifications/components/dialogComponents.js';
import LP from 'modules/app/helpers/lp';
import {Button} from 'uiKit';
import AlertInfo from 'modules/app/components/alertInfo';

const AuthenticationTokenDialog = createReactClass({

    
    getStyle: function() {
        return this.props.error ? {marginTop: '22px'} : null;
    },
    
    onChange: function(event) {
        this.props.handleChange(event);
    },

    onKeyPress: function(event) {
        if (event.which === 13) {
            event.preventDefault();
            this.props.onClick(event);
        }
    },
    
    renderValidationError: function() {
        if (this.props.error) {
            return (
                <AlertInfo type="warning" text={this.props.error} icon="warning" />
            )
        }
    },

    render: function() {
        return (
            <div className="dialog default-dialog">
                <DialogTitle title={this.props.title} className="dialog-title default-dialog-title" />
                <DialogBody body={this.props.body} className="dialog-body default-dialog-body" />
                <form>
                    <div className="form-field">
                        <label htmlFor="authentication-token" className="form-field-label">
                            {LP('login', 'authenticationTokenPrompt', 'sentencecase')}
                        </label>
                        <input
                            autoFocus
                            name="authentication-token"
                            type="text"
                            value={this.props.authenticationToken}
                            onChange={this.onChange}
                            onKeyPress={this.onKeyPress}
                        />
                    </div>
                    {this.renderValidationError()}
                </form>
                <Button
                    onClick={this.props.onClick}
                    className="authentication-token-popup-submit-button"
                    style={this.getStyle()}
                    type="primary"
                    text={LP('global', 'continue', 'titlecase')}
                />
            </div>
        )
    }

})

export default AuthenticationTokenDialog;