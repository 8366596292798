import React from 'react';
import createReactClass from 'create-react-class';
import Promise from 'bluebird';
import {connect} from 'react-redux';
import { registerType } from 'modules/form/helpers';
import LP from 'modules/app/helpers/lp';
import FormFieldListToFormHeader from './formFieldListToFormHeader';
import FormFieldListToFormItems from './formFieldListToFormItems';
import { addDialog, addToast } from 'modules/notifications/actions/notifications';
import { removeLanguagePackFromUsers } from 'modules/users/actions/usersActions';
import { exportLanguagePack, importLanguagePack } from 'modules/settings/actions/settingsActions';
import ImportLanguagePackForm from 'modules/settings/components/importLanguagePackForm';

var FormFieldListToForm = createReactClass({

    getInitialState: function() {
        return {
            selectedItemId: null,
            selectedSubItemKey: null,
            shouldShowAddForm: false,
            shouldShowSubItem: false,
            isSyncing: false
        }
    },

    onItemClicked: function(itemId) {
        this.setState({selectedItemId: itemId})
    },

    onSubItemClicked: function(schemaKey) {
        this.setState({ 
            selectedSubItemKey: schemaKey,
            shouldShowSubItem: true
         });
    },

    onBackButtonClicked: function() {
        if (this.state.selectedSubItemKey) {
            this.setState({ 
                selectedSubItemKey: null,
                shouldShowSubItem: false 
            });
        } else {
            this.setState({ 
                selectedItemId: null, 
                shouldShowSubItem: false
            });
        }
    },

    onAddButtonClicked: function() {
        this.setState({
            addItem: {},
            shouldShowAddForm: true
        })
    },

    onCancelButtonClicked: function() {
        this.setState({
            shouldShowAddForm: false
        })
    },

    onSubItemUpdated: function(updateObject) {
        var currentValue = _.find(this.props.value, {_id: this.state.selectedItemId});
        currentValue[this.state.selectedSubItemKey] = updateObject[this.state.selectedSubItemKey];
        return this.props.updateField(this.props.value);
    },

    onAddItemUpdated: function(updateObject) {
        var addItem = _.extend({}, this.state.addItem, updateObject.addItem);
        this.setState({addItem});
        return Promise.resolve();
    },

    onAddItemButtonClicked: function() {
        this.setState({isSyncing: true});
        var items = this.props.value || [];
        var newItem = _.extend({}, this.state.addItem, { itemIndex: items.length });
        items.push(newItem);

        return this.props.updateField(items).then(() => {
            this.setState({
                selectedItemId: null,
                selectedSubItemKey: null,
                shouldShowAddForm: false,
                addItem: {},
                isSyncing: false
            });
        })
    },

    onExportButtonClicked: function() {
        const item = _.find(this.props.value, { _id: this.state.selectedItemId });
        const text = item[this.props.itemTextAttribute];
        const dialog = {
            dialogType: 'default',
            title: `Export Language pack`,
            body: `Are you sure you want to export the ${text} language pack?`,
            ariaLiveText: 'Export theme dialog',
            actions:[{
                action: 'export',
                buttonText: 'EXPORT',
                buttonType: 'primary'
            }, {
                action: 'cancel',
                buttonText: 'CANCEL',
                buttonType: 'secondary'
            }]
        };

        this.props.addDialog(dialog)
        .then(result => {
            if(result.action === 'export') {
                this.props.exportLanguagePack(this.state.selectedItemId);
            }
        })
    },

    onImportClicked: function() {
        var importDialog = {
            dialogType: 'default',
            title: 'Import Language Pack',
            body: '',
            options: {
                importLanguagePack: this.props.importLanguagePack
            },
            customDialog: ImportLanguagePackForm,
            actions: [
                {
                    action: 'upload',
                    buttonText: 'UPLOAD',
                    buttonType: 'primary'
                },
                {
                    action: 'cancel',
                    buttonText: 'CANCEL',
                    buttonType: 'alert'
                }
            ]
        };
        
        this.props.addDialog(importDialog)
        .then(response => {
            if (response.action === 'done') {
                this.props.value.push(response.payload);
                return this.props.updateField(this.props.value).then(() => {
                    return this.props.addToast({
                        title: "Successfully imported language pack"
                    });
                });
            }
        });
    },

    onDeleteItemClicked: function (itemId) {

        var languagePack = _.find(this.props.value, {_id: itemId});

        if (languagePack._language === this.props.defaultLanguage) {
            const warningDialog = {
                dialogType: 'icon',
                title: `Cannot Delete Language Pack`,
                body: 'You cannot delete the default Language Pack. If you want to delete this pack you will need to select another Language Pack to be the default first.',
                ariaLiveText: 'Cannot delete language pack warning dialog',
                options: {
                    icon: {
                        iconClass: 'icon icon-warning',
                        color: '#ed5565'
                    }
                },
                actions: [{
                    action: 'ok',
                    buttonText: 'OK',
                    buttonType: 'primary'
                }]
            };
    
            return this.props.addDialog(warningDialog)
        }

        const warningDialog = {
            dialogType: 'icon',
            title: `Delete Language Pack`,
            body: 'Deleting this language pack can remove the language selected by users. This will remove the language pack setting from any user who has selected it. Are you sure you want to delete this language pack?',
            ariaLiveText: 'Delete language pack dialog',
            options: {
                icon: {
                    iconClass: 'icon icon-warning',
                    color: '#ed5565'
                }
            },
            actions: [{
                action: 'delete',
                buttonText: 'DELETE',
                buttonType: 'alert'
            }, {
                action: 'cancel',
                buttonText: 'CANCEL',
                buttonType: 'secondary'
            }]
        };

        this.props.addDialog(warningDialog)
        .then(result => {
            if (result.action === 'delete') {

                this.props.removeLanguagePackFromUsers(itemId)
                .then(() => {
                    _.remove(this.props.value, {_id: itemId});
                    
                    // Once removing one - update the itemIndex
                    _.each(this.props.value, function (item, index) {
                        item.itemIndex = index;
                    })
    
                    return this.props.updateField(this.props.value);
                })
            }

        });
    },
    
    render: function() {

        return (
            <div className="form-field">

                <div className="form-field-label">
                    {this.props.label}
                </div>

                <div className="form-field-help">
                    {this.props.help}
                </div>

                <div className="form-field-list-to-form">
                    <FormFieldListToFormHeader 
                        {...this.props} 
                        selectedItemId={this.state.selectedItemId}
                        selectedSubItemKey={this.state.selectedSubItemKey}
                        shouldShowAddForm={this.state.shouldShowAddForm}
                        onBackButtonClicked={this.onBackButtonClicked}
                        onAddButtonClicked={this.onAddButtonClicked}
                        onCancelButtonClicked={this.onCancelButtonClicked}
                        onExportButtonClicked={this.onExportButtonClicked}
                        onImportClicked={this.onImportClicked}
                    />  
                    <FormFieldListToFormItems 
                        {...this.props} 
                        selectedItemId={this.state.selectedItemId}
                        selectedSubItemKey={this.state.selectedSubItemKey}
                        onItemClicked={this.onItemClicked}
                        onSubItemClicked={this.onSubItemClicked}
                        onSubItemUpdated={this.onSubItemUpdated}
                        shouldShowAddForm={this.state.shouldShowAddForm}
                        shouldShowSubItem={this.state.shouldShowSubItem}
                        onAddItemUpdated={this.onAddItemUpdated}
                        onAddItemButtonClicked={this.onAddItemButtonClicked}
                        isSyncing={this.state.isSyncing}
                        onDeleteItemClicked={this.onDeleteItemClicked}
                    />  
                </div>

                <div className="form-field-error">
                    {this.props.error}
                </div>
            
            </div>
        );
    }
})

registerType('ListToForm', connect(function(state) {
    return {
        defaultLanguage: state.settings._translations._defaultLanguage
    }
}, { addDialog, addToast, removeLanguagePackFromUsers, exportLanguagePack, importLanguagePack })(FormFieldListToForm));