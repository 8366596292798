import ReactDOM from 'react-dom';
import React from 'react';
import createReactClass from 'create-react-class';
import classnames from 'classnames';
import {
    FlatButton
} from 'uiKit';
import {GuideElement} from 'modules/guides';
import getNavigationHeight from '../helpers/getNavigationHeight';
import TabsLabel from './tabsLabel';

/*
Props
items: {
    icon: String,
    label: String,
    component: ReactComponent
}
value: String - Selected label
onChange: Function - passes back the changed value/label
isVertical: Boolean - Places the tabs as a side panel
*/

const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const SPACE = 32;
const ENTER = 13;
const HOME = 36;
const END = 35;

var Tabs = createReactClass({

    componentDidUpdate: function(prevProps) {
        const {isVertical, value} = this.props;
        if ((value !== prevProps.value) && isVertical) {
            this.refs["tabsContent"].scrollTop = 0;
        }
    },

    onFocusTab: function(index) {
        const {items} = this.props;
        const item = items[index];
        if (item && this.refs[item.value]) {
            const tab = ReactDOM.findDOMNode(this.refs[item.value]);
            if (tab) {
                tab.focus();
            }
        }
    },

    onKeyDown: function({which}, index, value) {
        const {items} = this.props;
        switch (which) {
            case LEFT_ARROW:
                let prevIndex = index - 1;
                prevIndex = items[prevIndex] ? prevIndex : items.length - 1;
                this.onFocusTab(prevIndex);
                break;
            case RIGHT_ARROW:
                let nextIndex = index + 1;
                nextIndex = items[nextIndex] ? nextIndex : 0;
                this.onFocusTab(nextIndex);
                break;
            case SPACE:
                event.preventDefault();
                this.props.onChange(value);
                break;
            case ENTER:
                event.preventDefault();
                this.props.onChange(value);
                break;
            case HOME:
                event.preventDefault();
                this.onFocusTab(0);
                this.props.onChange(items[0].value);
                break;
            case END:
                event.preventDefault();
                this.onFocusTab(items.length - 1);
                this.props.onChange(items[items.length - 1].value);
                break;
        }
    },

    getClassName: function() {
        return classnames('tabs', {
            "is-vertical": this.props.isVertical,
            "is-horizontal": !this.props.isVertical
        })
    },

    getLabels: function() {
        return _.map(this.props.items, (item, index) => {

            var className = classnames('tabs-label', {
                'selected': (this.props.value === item.value)
            });

            var isSelected = this.props.value === item.value;

            var Button = (
                <TabsLabel
                    className={className}
                    text={item.label}
                    icon={item.icon}
                    isSelected={isSelected}
                    value={item.value}
                    ref={item.value}
                    key={item.label}
                    onClick={(event) => {
                        event.preventDefault()
                        this.props.onChange(item.value);
                    }}
                    onKeyDown={(event) => {
                        this.onKeyDown(event, index, item.value)
                    }}
                />
            );

            if (item.guideElementSteps) {
                return (
                    <GuideElement key={item.label} steps={item.guideElementSteps}>
                        {Button}
                    </GuideElement>
                )
            }

            return Button;
        })
    },

    getStyles: function() {
        if (this.props.isVertical) {
            return {height: 'calc(100vh - ' + getNavigationHeight() + 'px)'};
        } else {
            return;
        }
    },

    getContent: function() {
        if (!this.props.value) {
            console.warn('A value needs to be passed into a tabs component');
        } else {
            var content = _.find(this.props.items, {value: this.props.value});
            if (content) {
                return content.component;
            }
        }
    },

    render: function() {
        return (
            <div className={this.getClassName()} style={this.getStyles()}>
                {this.props.items.length > 1 && <div className="tabs-labels" >
                    <div className="tabs-labels-inner" role="tablist" style={this.getStyles()}>
                        {this.getLabels()}
                    </div>
                </div>}
                <div
                    className="tabs-contents"
                    ref="tabsContent"
                    role="tabpanel"
                    id={`${this.props.value}-tabpanel`}
                    aria-labelledby={`${this.props.value}-tab`}
                    tabIndex="0">
                    {this.getContent()}
                </div>
            </div>
        );
    }

});

export default Tabs;