import React from 'react';
import createReactClass from 'create-react-class';
import classNames from 'classnames';

const CollectionItem = function(props) {

	return (
		<li role="listitem" className={classNames('collection-item', props.className)}>
			<div className='collection-item-content'>
				{props.contentComponent}
			</div>
			{props.actionsComponent && (
				<div className="collection-item-actions">
					{props.actionsComponent}
				</div>
			)}
		</li>
	);

}

export default CollectionItem;