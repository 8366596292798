import React from 'react';
import createReactClass from 'create-react-class';
import {getType, getValidator, testConditions} from '../helpers';
import FormFieldCondition from '../fields/formFieldCondition.jsx';

var FormField = createReactClass({

    getInitialState: function() {
        return {
            _isSaving: false,
            _condition: null
        }
    },

    componentWillMount: function() {
        this.testConditions(this.props);
        this._isRequired = _.includes(this.props.validators || [], 'required');

        if (this.props.shouldValidateOnMount) {
            this.validateValue(this.props.value);
        }
    },

    componentWillReceiveProps: function(nextProps) {
        this.testConditions(nextProps);
    },

    testConditions: function(props) {

        testConditions(props.conditions, props, (condition) => {  
            
            this.setState({_condition: condition})
        });
    },

    validateValue: function(value) {
        var error = '';
        _.each(this.props.validators, (validator) => {
            if (error) return;

            error = getValidator(validator)(this.props.model, this.props.fieldKey, value, this.props.label);
        });

        this.setState({
            error: error
        });

        return error;

    },

    onUpdate: function(value) {
        var hasError = this.validateValue(value);
        var updateObject = {};
        updateObject[this.props.fieldKey] = value;

        this.setState({
            _isSaving: true
        });

        return this.props.onUpdate(updateObject, hasError, this.props.fieldKey).then(() => {
            if (this.isMounted()) {
                this.setState({
                    _isSaving: false
                });
            }
        });
        
    },

    onUpdateMultipleFields: function(values) {
        var hasError = [];

        _.each(values, (value) => {
            if (this.validateValue(value).length) {
                hasError.push(this.validateValue(value));
            }
        })
        this.setState({
            _isSaving: true
        });

        return this.props.onUpdate(values, hasError, this.props.fieldKey).then(() => {
            if (this.isMounted()) {
                this.setState({
                    _isSaving: false
                });
            }
        });
    },

    renderCondition: function() {
        
        if(this.state._condition) {
            return (
                <FormFieldCondition {...this.props} condition={this.state._condition}/>
            )
        }
    },

    renderFieldComponent: function() {
        if(this.state._condition) {
            return null;
        }

        var FieldComponent = getType(this.props.type);
        
        if (!FieldComponent) {
            console.warn("Looks like you're trying to render a form field (" + this.props.type +") that doesn't exist.", "Try looking at the schema for " + this.props.fieldKey);
            return;
        }

        return (
            <FieldComponent
                {...this.props}
                updateMultipleFields={this.onUpdateMultipleFields}
                updateField={this.onUpdate}
                isSaving={this.state._isSaving}
                error={this.state.error}
                isRequired={this._isRequired}
            />
        );
    },

    render: function() {
    
        return (
            <div>
                {this.renderCondition()}
                {this.renderFieldComponent()}
            </div>
        );
    }

});

export default FormField;