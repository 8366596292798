import React from 'react';
import createReactClass from 'create-react-class';
import moment from 'moment';
import {Button} from 'uiKit';
import MyAccountOverviewTitle from './myAccountOverviewTitle'
import MyAccountOverviewItem from './myAccountOverviewItem'

var MyAccountOverviewInvoices = createReactClass({

    // renderInvoices: function(schema) {
    //     // return _.map(schema, (schemaValue, schemaKey) => {
    //     //
    //     //     return (
    //     //         <MyAccountOverviewItem
    //     //             key={schemaKey}
    //     //             label={schemaValue}
    //     //             value={schemaValue}
    //     //         />
    //     //     );
    //     //
    //     // });
    //     return _.map(schema, (schemaValue, schemaKey) => {
    //
    //         return (
    //             <MyAccountOverviewItem
    //                 key={schemaKey}
    //                 label={schemaValue}
    //                 value={schemaValue}
    //             />
    //         );
    //
    //     });
    // },

    renderItems: function() {

        var _invoices;
        let invoicesList;

        //_invoices = _.each(this.props.invoices.list, function(schemaItemValue, schemaItemKey, index) {
            //_invoices[schemaItemKey] = schemaItemValue.invoice;

            //console.log(schemaItemValue.invoice);

            // return (
            //     <p>£ {schemaItemValue.invoice.total/100}</p>
            // );

            // var invoiceLine = 'x';

            //_invoices += invoiceLine;

            //invoicesList += (invoiceLine) => {

                // return (
                //     <MyAccountOverviewItem
                //         key="invoice"
                //         label="Invoice"
                //         value={invoiceLine}
                //     />
                // );
            //}
        //});

        //console.log(_invoices);

        if(this.props.invoices.list.length)
            return (
                <div className="clearfix">
                    <div className="my-account-overview-section-column">
                        {
                            this.props.invoices.list.map((invoice, index) => {

                                var invoiceLine = moment.unix(invoice.invoice.date).format("Y-MM-DD") + " | £" + invoice.invoice.total/100 + " (" + invoice.invoice.status + ")";

                                return (
                                    <div className="clearfix" key={index}>
                                        <div className="my-account-overview-section-left-column">
                                            <MyAccountOverviewItem
                                                key={index}
                                                label={invoice.invoice.line_items[0].description}
                                                value={invoiceLine}
                                            />
                                        </div>
                                        <div className="my-account-overview-section-right-column">
                                            <Button
                                                key={invoice.invoice.id}
                                                className="my-account-invoice-pdf-download-button"
                                                text="Download"
                                                type="primary"
                                                disabled={this.props.isLoadingInvoicePdf || "paid" !== invoice.invoice.status}
                                                onClick={() => this.props.onGetInvoicePdfClicked(invoice.invoice.id)}
                                            />
                                        </div>
                                    </div>
                                );
                            })

                        }
                    </div>
                </div>
            );
        else
            return (
                <div className="clearfix">
                    <div className="my-account-overview-section-column">
                        No invoices yet.
                    </div>
                </div>
            );
    },

    renderContent: function() {
        /*if (!this.props.customer.billing_address) {
            return (
                <div className="alert-info info">
                    <div className="alert-info-inner">
                        <i className="icon icon-bullhorn"/>
                        <span>Currently using information from Payment Contact Details</span>
                    </div>
                </div>
            );
        } else {*/
            return (
                <div>
                    {this.renderItems()}
                </div>
            );
        //}
    },

    render: function() {

        if(this.props.isUserBasic || this.props.isAnotherSubscription === true) {
            return(
                <div />
            );
        }

        return (
            <div className="my-account-overview-section">
                <MyAccountOverviewTitle text="Invoices"/>
                <div className="my-account-overview-content">
                    {this.renderContent()}
                </div>
            </div>
        );
    }

});

export default MyAccountOverviewInvoices;
