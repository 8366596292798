import React from 'react';
import classNames from 'classnames';

const AlertInfo = function({ type, className, text, icon }) {
	
	const alertClassName = classNames({
		'alert-info': icon,
		'alert-info no-icon':  !icon
	}, type);
	
	return (
		<div className={alertClassName}>
			<div className="alert-info-inner">
				<i className={`icon icon-${icon}`}></i>
				<span>{text}</span>
			</div>
		</div>
	);
}



export default AlertInfo;