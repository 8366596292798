import React from 'react';
import createReactClass from 'create-react-class';
import {Button} from 'uiKit';
import MyAccountOverviewTitle from './myAccountOverviewTitle'
import MyAccountOverviewItem from './myAccountOverviewItem'

var MyAccountOverviewProfile = createReactClass({

    renderColumnItems: function(schema) {
        return _.map(schema, (schemaValue, schemaKey) => {

            var hasValue = (this.props.customer.billing_address[schemaKey]);

            if (hasValue) {

                return (
                    <MyAccountOverviewItem
                        key={schemaKey}
                        label={schemaValue.label}
                        value={this.props.customer.billing_address[schemaKey]}
                    />
                );

            }

        });
    },

    renderItems: function() {

        var lengthOfFields = Object.keys(this.props.updateInvoiceSchema).length;
        var fieldLengthLeft = Math.ceil(lengthOfFields/2);
        var leftColumnSchema = {};
        var rightColumnSchema = {};
        var currentIndex = 0;

        _.each(this.props.updateInvoiceSchema, function(schemaItemValue, schemaItemKey, index) {
            if (currentIndex < fieldLengthLeft) {
                leftColumnSchema[schemaItemKey] = schemaItemValue;
            } else {
                rightColumnSchema[schemaItemKey] = schemaItemValue;
            }
            currentIndex ++;
        });

        return (
            <div className="clearfix">
                <div className="my-account-overview-section-left-column">
                    {this.renderColumnItems(leftColumnSchema)}
                </div>
                <div className="my-account-overview-section-right-column">
                    {this.renderColumnItems(rightColumnSchema)}
                </div>
            </div>
        );

        
    },

    renderContent: function() {
        if (!this.props.customer.billing_address) {
            return (
                <div />
            );
        } else {
            return (
                <div>
                    {this.renderItems()}
                </div>
            );
        }
    },

    render: function() {

        if(this.props.isUserBasic || this.props.isAnotherSubscription === true) {
            return(
                <div />
            );
        }

        return (
            <div className="my-account-overview-section">
                <MyAccountOverviewTitle text="Invoice Details"/>
                <div className="my-account-overview-content">
                    {this.renderContent()}
                    <Button text="Update Invoice Details" type="primary" onClick={this.props.onUpdateInvoiceClicked}/>
                </div>
            </div>
        );
    }

});

export default MyAccountOverviewProfile;
