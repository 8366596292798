var addUserToGroupSchema = {
    "email": {
        "type":"Text",
        "label": "Email",
        "validators": ["required", "isValidEmail"],
        "conditions": [],
        "help": ""
    }
}

module.exports = addUserToGroupSchema;
