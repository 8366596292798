import React from 'react';
import axios from 'axios';
import LP from 'modules/app/helpers/lp';
import createReactClass from 'create-react-class';
import Toast from 'modules/notifications/components/toast.js';
import Dialog from 'modules/notifications/components/dialog.js';
import DialogOverlay from 'modules/notifications/components/dialogOverlay';
import TransitionGroup from 'react-addons-transition-group';

var AuthenticationTokenDialogContainer = createReactClass({

	getInitialState: function() {
		return {
			authenticationToken: '',
			authenticationTokenError: ''
		}
	},

	componentWillReceiveProps(nextProps) {
		const nextNotification = nextProps.currentNotification;
		const {currentNotification} = this.props;
		if (nextNotification) {
			if (nextNotification.onTimeout && !_.isEqual(nextNotification, currentNotification)) {
				this.handlePrepareTimedToastTimeout(nextNotification);
			}
			else if (!nextNotification.onTimeout && !_.isEqual(nextNotification, currentNotification)) {
				this.handleClearTimedToastTimeout();
			}
		}
	},

	handlePrepareTimedToastTimeout: function(toast) {
		var duration = 5000;
		if (toast.options && toast.options.duration) {
			duration = toast.options.duration;
		}
		this.toastTimeout = setTimeout(() => {
			toast.onTimeout();
			this.props.removeToast()
				.then(() => this.handleClearTimedToastTimeout());
		}, duration);
	},

	handleClearTimedToastTimeout: function() {
		if (this.toastTimeout) {
			clearTimeout(this.toastTimeout);
			this.toastTimeout = undefined;
		}
	},

	handleAuthTokenChanged: function(event) {
        this.setState({
            authenticationToken: event.target.value,
            authenticationTokenError: ''
        })
    },

	updateAuthenticationTokenVerificationStatus: function(status) {
		if (status === false) {
            this.setState({
                authenticationTokenError: status === false ? LP('login', 'incorrectAuthenticationTokenWarning', 'sentencecase') : null,
            });          
		}
	
        this.props.onAuthenticationTokenVerificationStatusChanged(status);   
    },

	onAuthenticationTokenSubmitClick: function() {
        axios.post(`${window.location.origin}/api/isAuthenticationTokenCorrect`, {
            authenticationToken: this.state.authenticationToken
        }).then((response) => {
			this.updateAuthenticationTokenVerificationStatus(response.data._authenticationTokenCorrect);
		}).catch((error) => {
			this.setState({
				authenticationTokenError: error
			});
		})
    },

	renderCurrentNotification: function() {
		if (this.props.type === 'dialog') {
			return this.renderDialog();
		}
		if (this.props.type === 'toast') {
			return this.renderToast();
		}
	},

	renderDialog: function() {
		return (
			<Dialog
				dialogType={this.props.dialogType}
				title={this.props.title}
				body={this.props.body}
				handleChange={this.handleAuthTokenChanged}
				customDialog={this.props.customDialog}
				extra={this.props.extra || null}
				options={this.props.options}
				actions={this.props.actions}
				commitAction={this.props.commitAction}
				authenticationToken={this.state.authenticationToken}
				error={this.state.authenticationTokenError}
				onClick={this.onAuthenticationTokenSubmitClick}
			/>
		);
	},

	renderToast: function() {
		return (
			<Toast
				key={this.props.currentNotification._id}
				title={this.props.currentNotification.title}
				action={this.props.currentNotification.action}
				commitAction={this.props.currentNotification.commitAction}
			/>
		);
	},

	renderOverlay: function() {
		if (this.props.type === 'dialog') {
			return (
				<DialogOverlay key="overlay" />
			);
		}
	},

	render: function() {
		return (
			<div className="notification-container">
				<TransitionGroup>
					{this.renderOverlay()}
					{this.renderCurrentNotification()}
				</TransitionGroup>
			</div>
		);
	}

});

export default AuthenticationTokenDialogContainer;