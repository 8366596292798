import React from 'react';
import createReactClass from 'create-react-class';
import {SortableHandle} from 'react-sortable-hoc';
import FormSortableItemHandle from './formSortableItemHandle.js';
var FormSortableItemHandleSortable = SortableHandle(FormSortableItemHandle);
import classnames from 'classnames';

var FormSortableItem = createReactClass({

    getClassName: function() {
        return classnames('form-sortable-item', {
            'is-selected': this.props.isSelected
        })
    },

    getItemText: function() {
        if (this.props.itemTextAttribute) {
            if (this.props.item[this.props.itemTextAttribute]) {
                return this.props.item[this.props.itemTextAttribute];
            } else {
                return this.props.defaultPrefix + " " + (this.props.sortIndex + 1)
            }
        } else {
            console.warn("Sortable items need a itemTextAttribute to display text. This is the attribute you would like the item to use to display the text.")
        }
    },

    onClick: function(event) {
        event.preventDefault();
        this.props.onClick(this.props.sortIndex);
    },

    render: function() {
        return (
            <li className={this.getClassName()}>
                <div className="form-sortable-item-inner">
                    <FormSortableItemHandleSortable/>
                    <div className="form-sortable-item-text" onClick={this.onClick}>
                        {this.getItemText()}
                    </div>
                </div>
            </li>
        );
    }

});

export default FormSortableItem;