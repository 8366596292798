import React from 'react';
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';

var GuideElement = createReactClass({

    getStyle: function() {
        var style = {};
        
        if (this.props.isDisplayBlock) {
            style.display = 'block';
        }

        return style;

    },

    render: function() {
        
        if (_.includes(this.props.steps, this.props.guides._step)) {

            const { children } = this.props;

            return (
                <span className="guide-element" style={this.getStyle()} onClick={this.props.onElementClicked}>
                    {children}
                </span>
            );
        }

        return this.props.children;

    }
})

export default GuideElement;