import React from 'react';
import classnames from 'classnames';

const CssBorderRadiusSelector = (props) => {

	var getClassName = () => {
		if (props.topLeft && props.topRight && props.bottomRight && props.bottomLeft) {
			return {
				topLeft: 'button css-border-radius-selector-button css-border-radius-selector-button-top-left',
				topRight: 'button css-border-radius-selector-button css-border-radius-selector-button-top-right',
				bottomRight: 'button css-border-radius-selector-button css-border-radius-selector-button-bottom-right',
				bottomLeft: 'button css-border-radius-selector-button css-border-radius-selector-button-bottom-left',
				center: 'button css-border-radius-selector-button css-border-radius-selector-button-center primary'
			}
		}


		return {
			topLeft: classnames({'button css-border-radius-selector-button css-border-radius-selector-button-top-left primary': (props.topLeft)},
				{'button css-border-radius-selector-button css-border-radius-selector-button-top-left': (!props.topLeft)}),

			topRight: classnames({'button css-border-radius-selector-button css-border-radius-selector-button-top-right primary': (props.topRight)},
				{'button css-border-radius-selector-button css-border-radius-selector-button-top-right': !props.topRight}),

			bottomRight: classnames({'button css-border-radius-selector-button css-border-radius-selector-button-bottom-right primary': (props.bottomRight)},
				{'button css-border-radius-selector-button css-border-radius-selector-button-bottom-right': !props.bottomRight}),

			bottomLeft: classnames({'button css-border-radius-selector-button css-border-radius-selector-button-bottom-left primary': (props.bottomLeft)},
				{'button css-border-radius-selector-button css-border-radius-selector-button-bottom-left': !props.bottomLeft}),

			center: classnames({'button css-border-radius-selector-button css-border-radius-selector-button-center': !props.center}),
		}
	}

	var onTopLeftButtonClicked = () => {
		event.preventDefault();
		if (props.topLeft && props.topRight && props.bottomRight && props.bottomLeft) {
			props.onChange(true, false, false, false);
		} else {
			props.onChange(!props.topLeft, props.topRight, props.bottomRight, props.bottomLeft);
		}
	};

	var onTopRightButtonClicked = () => {
		event.preventDefault();
		if (props.topLeft && props.topRight && props.bottomRight && props.bottomLeft) {
			props.onChange(false, true, false, false);
		} else {
			props.onChange(props.topLeft, !props.topRight, props.bottomRight, props.bottomLeft);
		}
	};

	var onCentreButtonClicked = () => {
		event.preventDefault();
		if (props.topLeft && props.topRight && props.bottomRight && props.bottomLeft) {
			props.onChange(false, false, false, false);
		} else {
			props.onChange(true, true, true, true)
		}
	};

	var onBottomRightClicked = () => {
		event.preventDefault();
		if (props.topLeft && props.topRight && props.bottomRight && props.bottomLeft) {
			props.onChange(false, false, true, false);
		} else {
			props.onChange(props.topLeft, props.topRight, !props.bottomRight, props.bottomLeft);
		}
	};

	var onBottomLeftClicked = () => {
		event.preventDefault();
		if (props.topLeft && props.topRight && props.bottomRight && props.bottomLeft) {
			props.onChange(false, false, false, true);
		} else {
			props.onChange(props.topLeft, props.topRight, props.bottomRight, !props.bottomLeft);
		}
	};

	return (
		<div className="css-border-radius-selector">
			<button
				className={getClassName().topLeft}
				onClick={() => onTopLeftButtonClicked()}>
				<span className="css-border-radius-selector-button-icon-top-left">
				</span>
			</button>
			<button
				className={getClassName().topRight}
				onClick={() => onTopRightButtonClicked()}>
				<span className="css-border-radius-selector-button-icon-top-right">
				</span>
			</button>


			<button
				className={getClassName().center}
				onClick={() => onCentreButtonClicked()}>
				<span className="css-border-radius-selector-button-icon-center">
				</span>
			</button>


			<button
				className={getClassName().bottomLeft}
				onClick={() => onBottomLeftClicked()}>
				<span className="css-border-radius-selector-button-icon-bottom-left">
				</span>
			</button>
			<button
				className={getClassName().bottomRight}
				onClick={() => onBottomRightClicked()}>
				<span className="css-border-radius-selector-button-icon-bottom-right">
				</span>
			</button>

		</div>
	)
}

export default CssBorderRadiusSelector;