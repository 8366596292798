import React from 'react';
import createReactClass from 'create-react-class';
import {Loading} from 'uiKit';
import MyAccountOverviewAlert from './myAccountOverviewAlert';
import MyAccountOverviewBasic from './myAccountOverviewBasic';
import MyAccountOverviewProfile from './myAccountOverviewProfile';
import MyAccountOverviewSubscription from './myAccountOverviewSubscription';
import MyAccountOverviewPayment from './myAccountOverviewPayment';
import MyAccountOverviewInvoice from './myAccountOverviewInvoice';
import MyAccountOverviewInvoices from './myAccountOverviewInvoices';
import MyAccountOverviewSubscribers from './myAccountOverviewSubscribers';
import MyAccountOverviewTestSingle from './myAccountOverviewTestSingle';
import MyAccountAddUserToGroupDialog from '../components/MyAccountAddUserToGroupDialog';

var MyAccount = createReactClass({

    render: function() {
        return (
            <div className="my-account-overview">
                <Loading text="Updating account" hasDataLoaded={!this.props.isSyncing}>
                    <div className="my-account-overview-content">
                        <MyAccountOverviewAlert
                            customer={this.props.customer}
                            subscription={this.props.subscription}
                            isUserBasic={this.props.isUserBasic}
                            isAnotherSubscription={this.props.isAnotherSubscription}
                        />
                        <MyAccountOverviewBasic
                            customer={this.props.customer}
                            isUserBasic={this.props.isUserBasic}
                            isAnotherSubscription={this.props.isAnotherSubscription}
                            isMemberOfSubscriptionGroup={this.props.isMemberOfSubscriptionGroup}
                            subscription={this.props.subscription}
                            subscriptions={this.props.subscriptions}
                            getCoupon={this.props.getCoupon}
                            onSubscribeClicked={this.props.onSubscribeClicked}
                        />
                        <div className="my-account-overview-col-1">
                            <MyAccountOverviewProfile
                                customer={this.props.customer}
                                isUserBasic={this.props.isUserBasic}
                                onUpdateDetailsClicked={this.props.onUpdateDetailsClicked}
                            />
                            <MyAccountOverviewPayment
                                customer={this.props.customer}
                                subscription={this.props.subscription}
                                isLoadingPaymentMethod={this.props.isLoadingPaymentMethod}
                                isUserBasic={this.props.isUserBasic}
                                isAnotherSubscription={this.props.isAnotherSubscription}
                                onUpdatePaymentMethodClicked={this.props.onUpdatePaymentMethodClicked}
                            />
                            <MyAccountOverviewInvoices
                                invoices={this.props.invoices}
                                isUserBasic={this.props.isUserBasic}
                                isAnotherSubscription={this.props.isAnotherSubscription}
                                onGetInvoicePdfClicked={this.props.onGetInvoicePdfClicked}
                            />
                        </div>
                        <div className="my-account-overview-col-2">
                            <MyAccountOverviewSubscription
                                customer={this.props.customer}
                                subscription={this.props.subscription}
                                subscriptions={this.props.subscriptions}
                                isUserBasic={this.props.isUserBasic}
                                getCoupon={this.props.getCoupon}
                                onUpdateSubscriptionClicked={this.props.onUpdateSubscriptionClicked}
                                onCancelSubscriptionClicked={this.props.onCancelSubscriptionClicked}
                            />
                            <MyAccountOverviewInvoice
                                customer={this.props.customer}
                                subscription={this.props.subscription}
                                isUserBasic={this.props.isUserBasic}
                                isAnotherSubscription={this.props.isAnotherSubscription}
                                updateInvoiceSchema={this.props.updateInvoiceSchema}
                                onUpdateInvoiceClicked={this.props.onUpdateInvoiceClicked}
                            />
                            <MyAccountOverviewSubscribers
                                user={this.props.user}
                                customer={this.props.customer}
                                isGroupManager={this.props.isGroupManager}
                                subscription={this.props.subscription}
                                subscriptions={this.props.subscriptions}
                                getCoupon={this.props.getCoupon}
                                onAddUserToSubscriptionGroupDialogClicked={this.props.onAddUserToSubscriptionGroupDialogClicked}
                                onChangeSubscriptionGroupNameDialogClicked={this.props.onChangeSubscriptionGroupNameDialogClicked}
                                onRemoveUserFromSubscriptionGroupClicked={this.props.onRemoveUserFromSubscriptionGroupClicked}
                                onSetSeniorMemberClicked={this.props.onSetSeniorMemberClicked}
                                onRemoveSeniorMemberClicked={this.props.onRemoveSeniorMemberClicked}
                            />
                        </div>
                    </div>
                </Loading>
            </div>
        );
    }
});

export default MyAccount;
