import React, {Fragment} from 'react';
import createReactClass from 'create-react-class';
import moment from 'moment';
import LP from 'modules/app/helpers/lp';
import _ from 'lodash';
import classNames from 'classnames';
import getUsersAvatar from 'modules/app/helpers/getUsersAvatar';
import NavigationLink from 'modules/app/components/navigationLink';

var SearchBarResultItem = createReactClass({

    renderDeadlines: function() {
        var {result} = this.props;

        if (result._deadlines && result._deadlines.length) {
            return (
                <p className="search-bar-result-item-content-deadline">
                    {LP('deadline', 'deadline', 'sentencecase')}: {moment(result._deadlines._dueDate).format('MMMM Do YYYY')}
                </p>
            )
        }
    },

    renderCourseAvailability: function() {
        var {result} = this.props;

        if (result._isAvailable && result._currentAvailability) {
            return (
                <p className="search-bar-result-item-content-availability">
                    {LP('courses', 'courseAvailable')}: {LP('global', 'from')} {moment(result._currentAvailability._fromDate).format('MMMM Do YYYY')} {LP('global', 'to')} {moment(result._currentAvailability._toDate).format('MMMM Do YYYY')}
                </p>
            );
        }

        if (!result._isAvailable && result._currentAvailability) {
            return (
                <p className="search-bar-result-item-content-availability">
                    {LP('global', 'notAvailable')}
                </p>
            );
        }
    },

    renderResultItemExtraInformation: function() {

        var extraInformation = _.pick(this.props.result, ['_parents', '_trails', '_hasAssessment', '_assessmentPassed', '_progress', '_hasStarted', '_isComplete']);

        return (
            <div className="search-bar-result-item-content-extra-information-wrapper">
                <div className="search-bar-result-item-content-extra-information">
                    {extraInformation._parents && this.props.activeTab !== 'all' && this.getParents()}
                    <div className={classNames("search-bar-result-item-content-extra-information-assessment-and-completion", (extraInformation._hasAssessment && (extraInformation._hasStarted || extraInformation._isComplete) ? "two-items" : ""))}>
                        {extraInformation._hasAssessment && <p className="search-bar-result-item-extra-information-assessment">Has assessment</p>}
                        {!this.props.isAdmin && this.getCourseCompletionStatus()}
                    </div>
                </div>
            </div>
        )
    },

    getParents: function() {
        var index = 0;
        var suffix = ', ';

        return _.map(this.props.result._parents, (parent) => {
            if (!parent || !parent.displayTitle) {return null};
            
            if (index === Object.keys(this.props.result._parents).length - 1) {
                suffix = '';
            }
            index++;
            return <p key={parent.displayTitle} className="search-bar-result-item-extra-information-parent">{parent.displayTitle + suffix}</p>;
        });
    },

    getCourseCompletionStatus: function() {
        var status = this.props.result._isComplete ? "completed" : this.props.result._hasStarted ? "started" : "notStarted";

        return (
            <p role="text" key={parent._id} className={`search-bar-result-item-course-completion ${status}`} aria-label={`${LP('courses', 'course')} ${LP('courses', status)}`}>{LP('courses', status)}</p>
        )
    },

    renderEditAndViewAdminButtons: function() {
        return (
            <div className="search-bar-result-icons">
                <NavigationLink
                    disabled={this.props.result._type === 'group' || this.props.result._type === 'user'}
                    isFlatButton
                    className="search-bar-result-item-binoculars"
                    onClick={() => {this.props.onResultClicked(this.props.result, 'view')}}
                    icon="binoculars" />
                <NavigationLink
                    isFlatButton
                    className="search-bar-result-item-pencil"
                    onClick={() => {this.props.onResultClicked(this.props.result, 'edit')}}
                    icon="pencil" />
            </div>
        )
    },

    getThumbnail: function() {
        return this.props.result._thumbnail ? {backgroundImage: `url('${this.props.result._thumbnail}')`} : {backgroundImage: `url("images/default-avatar-${this.props.result._type}.png")`};
    },

    render: function() {
        if (this.props.result._type == 'user') {
            return (
                <Fragment>
                    <div className="search-bar-result-item-graphic-wrapper">
                        <div className='search-bar-result-item-graphic' style={{backgroundImage: `url('${getUsersAvatar(this.props.result)}')`}}></div>
                    </div>
                    <div className="search-bar-result-item-wrapper">
                        <div className="search-bar-result-item-content-wrapper">
                            <p className="search-bar-result-item-content-title">{this.props.result.firstName + ' ' + this.props.result.lastName}</p>
                        </div>
                        {this.renderResultItemExtraInformation()}
                        {this.renderEditAndViewAdminButtons()}
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <div className="search-bar-result-item-graphic-wrapper">
                        <div className='search-bar-result-item-graphic' style={this.getThumbnail()}></div>
                    </div>
                    <div className="search-bar-result-item-wrapper">
                        <div className="search-bar-result-item-content-wrapper">
                            <p className="search-bar-result-item-content-title">{this.props.isAdmin ? this.props.result.title : (this.props.result.displayTitle || this.props.result.title)}</p>
                            
                            {this.props.activeTab === 'all' && <p className="search-bar-result-item-result-type">{this.props.result._type}</p>}
                            {!this.props.isAdmin && this.renderDeadlines()}
                            {this.renderCourseAvailability()}
                        </div>
                        {this.renderResultItemExtraInformation()}
                        {this.props.isAdmin && this.renderEditAndViewAdminButtons()}
                    </div>
                </Fragment>
            )
        }
    },
})
export default SearchBarResultItem;