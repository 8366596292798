import React from 'react';
import createReactClass from 'create-react-class';
import {ListItem, ListItems, Loading, Button, Pagination, CheckListItem, ActionBar, SearchBar} from 'uiKit';
import { withRouter } from 'react-router-dom'

var GroupsOverview = createReactClass({

    onEditClicked: function(groupId) {
        this.props.history.push('/groups/' + groupId + '/edit');
    },

    onEditMemberClicked: function(memberId) {
        this.props.history.push('/users/' + memberId + '/edit');
    },

    onShowMembersClicked: function(groupId) {
        this.props.history.push('/groups/' + groupId + '/edit');
    },

    renderGroups: function() {

        return _.map(this.props.groups, (group) => {

            let _itemText = `${group.title}`;

            return (
                <div>
                    <ListItem
                        key={group._id}
                        itemText={_itemText}
                        buttons={
                            [
                            // {
                            //     icon:"users2",
                            //     type: "primary",
                            //     toolTip: "Show members",
                            //     onClick: (event) => {
                            //         event.preventDefault()
                            //         this.onShowMembersClicked(group._id)
                            //     }
                            // },
                            {
                                icon:"pencil4",
                                type: "primary",
                                toolTip: "Edit group",
                                onClick: (event) => {
                                    event.preventDefault()
                                    this.onEditClicked(group._id)
                                }
                            }]
                        }
                    />
                    <ListItems 
                        className="my-kinderly-groups-members"
                        items={this.renderMembers(group.members, group._createdBy)} 
                    />
                </div>
            );
        });
    },

    renderMembers: function(members, groupManagerId) {

        return _.map(members, (member) => {

            let isTeacher = member._role === 'teacher' ? ' - Senior Member' : '';
            let groupManager = member._id === groupManagerId ? ' - Group Manager' : '';
            let memberRole;

            if ( groupManager ) {
                memberRole = groupManager;
            } else if ( isTeacher ) {
                memberRole = isTeacher;
            } else {
                memberRole = '';
            }

            let _itemText = `${member.firstName} ${member.lastName} ${memberRole}`;

            /* var _itemText = user.firstName + ' ' + user.lastName +
                ' (registered ' + moment(user._createdAt).format('DD/MM/YYYY') + ') '+
                user._role + ', groups [' +
                user._groups.map(function(group){
                    return group.displayTitle;
                }).join(", ") + ']'; */

            return (
                
                    <ListItem
                        key={member._id}
                        itemText={_itemText}
                        buttons={
                            [{
                                icon:"pencil4",
                                type: "primary",
                                toolTip: "Edit group",
                                onClick: (event) => {
                                    event.preventDefault()
                                    this.onEditMemberClicked(member._id)
                                }
                            }]
                        }
                    />
            );
        });
    },

    render: function() {

        return (
            <div className="users-overview">
                <Loading text="Loading users" hasDataLoaded={!this.props.isSyncing}>
                    <div className="users-overview-list">
                        <div className="users-pagination">
                            <div className="groups-collection">
                                <ActionBar>
                                    <div className="action-bar-left">
                                    </div>
                                    <div className="action-bar-center">
                                    </div>

                                    <div className="action-bar-right">
                                        <Button 
                                            isSmall={true}
                                            className="action-bar-item-left"
                                            text="Import Users to Subscription Group" 
                                            type="secondary" 
                                            icon="users-plus" 
                                            onClick={this.props.onImportUsersToSubscriptionGroupClicked}/>
                                    </div>
                                </ActionBar>
                            </div>
                        </div>
                        <ListItems items={this.renderGroups()} />
                    </div>
                </Loading>
            </div>
        );
    }
});

export default withRouter(GroupsOverview)
