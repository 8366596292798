import React from 'react';
import createReactClass from 'create-react-class';
import classnames from 'classnames';
import PopupNavigation from './popupNavigation';
import LP from 'modules/app/helpers/lp';

var PopupActions = createReactClass({

    getClassName: function(hasPreviousButton) {
        return classnames("popup-actions", {
            "has-singular-button": !hasPreviousButton
        })
    },

    renderPreviousButton: function(hasPreviousButton) {
        if (hasPreviousButton) {
            return (
                <PopupNavigation 
                    direction="left" 
                    text={LP('global', 'previous', 'uppercase')}
                    onClick={this.props.onPreviousButtonClicked}
                />
            );
        }
    },

    renderNextButton: function() {
    
        var isDisabled = !this.props.isNextButtonEnabled;
        var languageString = 'next';

        if (this.props.popups.length - 1 === this.props.stage) {
            languageString = 'done';
        }

        return (
            <PopupNavigation 
                direction="right" 
                text={LP('global', languageString, 'uppercase')} 
                isDisabled={isDisabled}
                onClick={this.props.onNextButtonClicked}
            />
        );

    },

    render: function() {

        var hasPreviousButton = (this.props.popups[this.props.stage - 1]);

        return (
            <div className={this.getClassName(hasPreviousButton)}>
                {this.renderPreviousButton(hasPreviousButton)}
                {this.renderNextButton(hasPreviousButton)}
            </div>
        );
    }
})

export default PopupActions;