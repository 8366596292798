import React from 'react';
import createReactClass from 'create-react-class';
import {DialogTitle, DialogBody, DialogActions} from 'modules/notifications/components/dialogComponents.js';

var PopupContent = createReactClass({

    renderTitle: function(popup) {
        return (
            <DialogTitle title={popup.title} className="dialog-title default-dialog-title"/>
        );
    },

    renderBody: function(popup) {
        return (
            <DialogBody body={popup.body} className="dialog-body default-dialog-body"/>
        )
    },

    renderComponent: function(popup) {
        var Component = popup.component;
        return (
            <Component 
                {...popup} 
                enableNextButton={this.props.enableNextButton} 
                disableNextButton={this.props.disableNextButton}
                storePopupData={this.props.storePopupData}
            />
        );
    },

    render: function() {
        var currentPopup = this.props.popups[this.props.stage];
        return (
            <div className="popup-content">
                {this.renderTitle(currentPopup)}
                {this.renderBody(currentPopup)}
                {this.renderComponent(currentPopup)}
            </div>
        );
    }
})

export default PopupContent;