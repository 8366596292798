import React from 'react';
import createReactClass from 'create-react-class';
import {connect} from 'react-redux';
import {Button, FlatButton} from 'uiKit';
import {registerType} from '../helpers';
import {addDialog} from 'modules/notifications/actions/notifications';
import {sendTestEmail} from 'modules/settings/actions/settingsActions';

var formFieldTestEmail = createReactClass({

    getInitialState: function() {
        return {
            isSyncing: false,
            sendToEmail: this.props.value || '',
            isDisabled: true
        }
    },

    onSendToEmailInputChanged: function(event) {
        this.setState({
            sendToEmail: event.target.value
        });
    },

    onButtonClicked: function(event) {
        event.preventDefault();

        var dialog = {
            dialogType: 'default',
            title: 'Test email',
            body: `Are you sure you want to send a test email to ${this.props.value}`,
            actions :[
                {
                    action: 'yes',
                    buttonText: 'YES',
                    buttonType: 'primary'
                },
                {
                    action: 'no',
                    buttonText: 'NO',
                    buttonType: 'alert'
                }
            ],
        };
        
        
        this.props.addDialog(dialog).then((response) => {
            if (response.action === 'yes') {
                this.setState({
                    isSyncing: true
                }, () => {
                    this.props.sendTestEmail(this.state.sendToEmail)
                    .then(() => {
                        this.setState({
                            isSyncing: false
                        });
                    })
                });
            }
        });
    },

    onEditClicked: function() {
        this.setState({
            isDisabled: false
        })
    },

    onSaveClicked: function() {
        this.props.updateField(this.state.sendToEmail)
        .then((response) => {
            this.setState({
                isDisabled: true,
            })
        })
        .catch((response) => {
            this.setState({
                error: response.data.message,
                isDisabled: true,
            })
        });
    },


    renderEditAction: function() {
        if (this.state.isDisabled) {
            return (
                <FlatButton
                    icon="pencil4"
                    text="edit"
                    onClick={this.onEditClicked}
                />
            );
        }
    },

    renderSaveAction: function() {
        if (!this.state.isDisabled) {
            return (
                <FlatButton
                    icon="floppy-disk"
                    text="save"
                    onClick={this.onSaveClicked}
                />
            );
        }
    },

    render: function() {
        return (
            <div className="form-field form-field-test-email">

                <label className="form-field-label">
                    {this.props.label}
                </label>

                <div className="form-field-help">
                    {this.props.help}
                </div>
                
                <div className="form-field-test-email-input">
                    <input value={this.state.sendToEmail} onChange={this.onSendToEmailInputChanged} disabled={this.state.isDisabled} />
                </div>


                <div className="form-field-test-email-actions">
                    {this.renderEditAction()}
                    {this.renderSaveAction()}
                </div>
                
                <Button 
                    onClick={this.onButtonClicked} 
                    icon={this.props.buttonIcon} 
                    type={this.props.buttonType} 
                    text={this.props.buttonText}
                    disabled={(!this.state.isDisabled || this.state.isSyncing)}
                    isSyncing={this.state.isSyncing} />

                <div className="form-field-error">
                    {this.props.error}
                </div>

            </div>
        );
    }

});

registerType('TestEmail', connect(function() {
    return {}
}, {
    sendTestEmail,
    addDialog
})(formFieldTestEmail));

export default formFieldTestEmail;