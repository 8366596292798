import React from 'react';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import SearchBarFilterItem from './searchBarFilterItem';
import classnames from 'classnames';

var SearchBarFilters = createReactClass({

    handleFilterMenuItemClick: function(event) {
        var filterType = event.currentTarget.getAttribute('filtertype');
        var filterMenuItemValue = event.currentTarget.getAttribute('value');
        var menuItem = _.find(this.props.filters[filterType], {value: filterMenuItemValue});

        this.props.onUpdateFilter(menuItem);
    },

    renderFilterItems: function() {
        return _.map(this.props.filters, (filter, key) => {
            if (key === '_status' && (this.props.activeTab === 'groups' || this.props.activeTab === 'users')) {
                return null;
            }

            return (
                <SearchBarFilterItem
                    activeTab={this.props.activeTab}
                    isSyncing={this.props.isSyncing}
                    key={key}
                    filterTitle={key}
                    filterMenuItems={filter}
                    onFilterMenuItemClick={(event) => {this.handleFilterMenuItemClick(event)}}
                />
            )
        })
    },

    render: function() {

        return (
            <div className={classnames("search-bar-filters", this.props.displayOnMobile ? 'display-on-mobile' : '')}>
                {this.renderFilterItems()}
            </div>
        )
    }
})
export default SearchBarFilters;