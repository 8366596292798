import axios from 'axios';

export const ENABLE_NEXT_BUTTON = 'ENABLE_NEXT_BUTTON';
export const DISABLE_NEXT_BUTTON = 'DISABLE_NEXT_BUTTON';
export const MOVE_TO_NEXT_POPUP = 'MOVE_TO_NEXT_POPUP';
export const MOVE_TO_PREVIOUS_POPUP = 'MOVE_TO_PREVIOUS_POPUP';
export const STORE_POPUP_DATA = 'STORE_POPUP_DATA';

export function enableNextButton() {

    return (dispatch) => {

        dispatch({
            type: ENABLE_NEXT_BUTTON
        });

    }

}

export function disableNextButton() {

    return (dispatch) => {

        dispatch({
            type: DISABLE_NEXT_BUTTON
        });

    }

}

export function moveToNextPopup() {
    return (dispatch) => {

        dispatch({
            type: MOVE_TO_NEXT_POPUP
        });

    }
}

export function moveToPreviousPopup() {
    return (dispatch) => {

        dispatch({
            type: MOVE_TO_PREVIOUS_POPUP
        });

    }
}

export function storePopupData(data) {
    return (dispatch) => {

        dispatch({
            type: STORE_POPUP_DATA,
            payload: data
        });

    }
}