import {registerRoute, registerMenuItem, registerReducer} from 'bloom';
import MyAccountContainer from './containers/myAccountContainer';
import MyKinderlyUsersContainer from './containers/myKidnerlyUsersContainer';
import MyKinderlyGroupsContainer from './containers/myKidnerlyGroupsContainer';
import reducer from './dux';
import reducerUsers from './duxUsers';
import reducerGroups from './duxUsers';
import './fields/subscriptionQuantityField';
import './fields/subscriptionToggleField';

registerRoute('myKinderlySubscription', MyAccountContainer);
registerRoute('myKinderlyUsers', MyKinderlyUsersContainer);
registerRoute('myKinderlyGroups', MyKinderlyGroupsContainer);
registerMenuItem({text: 'My subscriptions', _link: "myKinderlySubscription", _icon: "cart-full", _permission: "learner"}, 1);
registerMenuItem({text: 'Kinderly Users List', _link: "myKinderlyUsers", _icon: "users2", _permission: "admin"}, 1);
registerMenuItem({text: 'Kinderly Groups List', _link: "myKinderlyGroups", _icon: "users2", _permission: "admin"}, 1);
registerReducer('myKinderlySubscription', reducer);
registerReducer('myKinderlyUsers', reducerUsers);
registerReducer('myKinderlyGroups', reducerGroups);
