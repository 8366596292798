/**
 * Bloom
 * @module bloom
 * @description Bloom's API consists of simple register and get methods that enable plugins to
 * extend the core of Bloom. Due to the nature of React's contained components
 * most of the API calls allow complete components to be switched out. This enables Bloom's
 * frontend code to be flexible enough for any type of Learning Platform.
 */

import _ from 'lodash';
import React from 'react';
import hasUserGotPermissions from 'modules/app/helpers/hasUserGotPermissions';
import Store from 'store';
import {addDialog} from 'modules/notifications/actions/notifications';
import Popup from 'modules/popup/containers/popupContainer';
import { updateAriaLiveRegion, removeAriaLiveRegion } from 'modules/accessibility/actions/accessibilityActions';

var Bloom = {};

var Components = [];
var Routes = [];
var MenuItems = [];
var NavigationItems = [];
var DashboardItems = [];
var Reducers = {};
var Hooks = {};
var Popups = [];

/**
 * @function
 * @description This is a way to push a component into a certain location from within Bloom. The locations are limited to areas that allow plugins like the Dashboard or MyLearning page.
 * They can also be sorted/enabled/disabled from within the relevant section in Bloom's settings page.
 * @param {string} name This is the name given to the registered component
 * @param {string} displayName This is a friendly name given to the registered component
 * @param {object} reactComponent The React Component to be rendered
 * @param {('front'|'dashboard:learner'|'myLearning')} location The location inside Bloom you would like to see this component rendered
 * @example
 * import {registerComponent} from 'bloom';
 * import DashboardAchievementsContainer from './containers/dashboardAchievementsContainer';
 * 
 * registerComponent('dashboardAchievements', 'Dashboard Achievements', DashboardAchievementsContainer, 'dashboard:learner');
 * 
 */

export function registerComponent(name, displayName, reactComponent, location) {
    if (arguments.length != 4) {
        return console.warn("Registering a component needs four arguments, a react component and a location");
    }

    Components.push({
        name: name,
        displayName: displayName,
        location: location,
        component: reactComponent
    });

}

/**
 * @function
 * @description This is a way to register a frontend route into the React Router: {@link https://reacttraining.com/react-router/}. Typically this is used to render container components that fetch data.
 * @param {string} path The path the url must match
 * @param {string} component The React Component rendered when this path is matched
 * @param {object} [options] Any props you want to push onto the ``<Route/>``
 * @example
 * import {registerRoute} from 'bloom';
 * import LibraryTrail from './containers/libraryTrail';
 * 
 * registerRoute("library/bySubject/:subjectId/:id", LibraryTrail, {shouldTrackGoogleAnalytics: true});
 */

export function registerRoute(path, component, options) {

    if (!path) {
        return console.warn("Registering a route needs to have a path argument");
    }

    if (!component) {
        return console.warn("Registering a route needs to have a component argument");
    }

    Routes.push({path, component, options});
}

/**
 * @function
 * @description Enables plugins or core modules to register a menu item into the side panel main menu. This is usually tightly coupled with registering a route as the menu items can only link to an internal route.
 * @param {object} item Object containing details about the menu item
 * @param {string} item._link A react-router style route
 * @param {string} item.text Text displayed in the menu item. This would normally use the ``LP()`` helper for translations
 * @param {string} item._icon An icon to be displayed alongside the text
 * @param {string} [item._permission='learner'] The role that is allowed to see this menu item
 * @param {number} [priority=0] Priority given to the menu item. This will place the menu item further up the list the higher the number
 * @example
 * import {registerRoute, registerMenuItem} from 'bloom';
 * import LP from 'modules/app/helpers/lp';
 * import Courses from './containers/coursesContainer';
 * 
 * registerRoute("courses", Courses);
 * registerMenuItem({text: LP("courses", "courses", "titlecase"), _link: "courses", _icon: "document2", _permission: "admin"}, 1);
 */

export function registerMenuItem(item, priority) {
    if (!item.text || !item._icon || !item._link) {
        return console.warn("Registering a menu item requires a text, _link and _icon attribute");
    } else { 
        // Store against the ``MenuItems`` API store
        MenuItems.push({
            text: item.text,
            _link: item._link,
            _icon: item._icon,
            _permission: item._permission || 'learner',
            _isInBeta: item._isInBeta,
            priority: priority || 0
        });

    }
}

/**
 * @function
 * @description Enables plugins or core modules to register a navigation item into the navigation bar. This is usually tightly coupled with registering a route as the navigation items can only link to an internal route.
 * @param {object} item Object containing details about the navigation item
 * @param {string} item._link A react-router style route
 * @param {string} item.text Text displayed in the navigation item. This would normally use the ``LP()`` helper for translations
 * @param {string} [item._permission='learner'] The role that is allowed to see this menu item
 * @param {number} [priority=0] Priority given to the navigation item. This will place the navigation before other items that have a lower priority.
 * @example
 * import registerNavigationItem from 'bloom';
 * import LP from 'modules/app/helpers/lp';
 * import MyLearners from './containers/myLearnersContainer';
 * 
 * registerNavigationItem({text: LP("myLearners", "myLearners", "titlecase"), _link: "myLearners", _permission: "teacher"}, 9);
 */

export function registerNavigationItem(item, priority) {
    if (!item.text || !item._link) {
        return console.warn("Registering a navigation item requires a text and _link attribute");
    } else { 
        // Store against the ``NavigationItems`` API store
        NavigationItems.push({
            text: item.text,
            _link: item._link,
            _permission: item._permission || 'learner',
            _isInBeta: item._isInBeta,
            priority: priority || 0
        });

    }
}

/**
 * @function
 * @description Enables plugins to sotre state on the redux state tree.
 * @param {object} key String containing the key of the registered router, which can later referenced to require that reducer in
 * @param {function} reducer A function containing the code of your reducer
 * @example
 * import CoursesWithAssignmentsReducer from './reducers/coursesWithAssignmentsReducer';
 * import CourseAssignmentsReducer from './reducers/courseAssignmentsReducer';
 * import UsersAssignmentReducer from './reducers/usersAssignmentReducer';
 * 
 * registerReducer('coursesWithAssignments', CoursesWithAssignmentsReducer);
 * registerReducer('courseAssignments', CourseAssignmentsReducer);
 * registerReducer('usersAssignment', UsersAssignmentReducer);
 */

export function registerReducer(key, reducer) {
    
    if (!key || !reducer) {
        return console.warn("Registering a reducer needs a key and a reducer");
    }

    if (Reducers[key]) {
        return console.warn("This reducer already exists:", key);
    }

    Reducers[key] = reducer;

}

/**
 * @function
 * @description Enables plugins a chance to inject code running in line with a certain place within Bloom
 * @param {string} location String containing the location of the hook
 * @param {function} callback A react-router style route
 * @example
 *  import {registerHook} from 'bloom';
 * 
 *  registerHook('preLoad:course', function(courseId) {
        window.API.model = {};
        getSockets().emit('client/preLoadCourse', {courseId: courseId, userId: Store.getState().auth._id});
    })
 */

export function registerHook(location, callback) {
    if (!location || !callback) {
        return console.warn("Registering a hook needs a location and a callback");
    }

    Hooks[location] = (Hooks[location]) ? Hooks[location].push(callback) : [callback];
}

/**
 * @function
 * @description Enables plugins to display notification to user based on a location.  
 * @param {object} dialog Object containing details about the popup
 * @param {string} dialog.title A friendly user title your popup
 * @param {string} dialog.component The react component which renders your popup
 * @param {function} dialog.isAvailable This function could be a conditional statment defining when the popup should be available
 * @param {function} dialog.onNextButtonClicked This function is the action that is preformed when the next button is clicked.
 * @param {function} location A react-router style route
 * @example
 * import {registerRoute, registerMenuItem, registerReducer, registerPopup} from 'bloom';
 * 
 * registerPopup({
    title: 'Select Password',
    component: PasswordSelectPopupContainer, 
    isAvailable: function(store) {
    
        var user = store.auth;
        
        if(user && user._hasDefaultPassword && user._isNewUser) {
            return true;
        }

    },
    onNextButtonClicked: function(store, popupData) {
        
        var user = store.auth;

        if (user && user._hasDefaultPassword && user._isNewUser) {
            return Store.dispatch(updateMyPassword(popupData.password))
        }
        return Promise.resolve();
        
    }
}, 'app');
 */

export function registerPopup(dialog, location, priority) {

    if (!dialog || !location) {
        return console.warn("Registering a popup needs a dialog and location");
    }

    Popups.push({
        ...dialog,
        location,
        priority
    });

}

/**
 * @function
 * @description This is used internally to fetch menu items. See registerMenuItem for more information.
 
 */

export function getMenuItems() {
    // Filter menu items based upon the current users permissions
    var menuItems = _.orderBy(_.filter(MenuItems, function(menuItem) {
        if (hasUserGotPermissions(menuItem._permission) ) {
            return menuItem;
        }
    }), ['priority'], ['desc']);

    // Filters out duplicate menu items.
    return _.uniqBy(menuItems, 'text');
}

/**
 * @function
 * @description This is used internally to fetch registered navigation items. See registerNavigationItem for more information.
 */

export function getNavigationItems() {
    // Filter navigation items based upon the current users permissions
    return _.orderBy(_.filter(NavigationItems, function(navigationItem) {
        if (hasUserGotPermissions(navigationItem._permission)) {
            return navigationItem;
        }
    }), ['priority'], ['desc']);

}

/**
 * @function
 * @param {string} name String containing the name of the menu item
 * @param {string} location String containing the location of the component item
 * @description This is used internally to fetch a registered specific React component. See registerComponent for more information.
 
 */

export function getComponent(name, location) {
    return _.find(Components, {name: name, location: location});
}

/**
 * @function
 * @param {string} location String containing the location of the component items
 * @description This is used internally to fetch a registered React components. See registerComponent for more information.
 * @example
 * import {getComponents} from 'bloom';
 *         
 *  renderDashboardComponents: function() {
        var components = _.mapKeys(getComponents('dashboard:learner'), 'name');
        
        return _.map(this.props.settings._dashboard._learnerDashboardItems, function(item) {
            if (item._isEnabled) {
                var componentItem = components[item.name];
                if (componentItem) {
                    var DashboardItem = componentItem.component;
                    return (
                        <div className="dashboard-item" key={item.name}>
                            <DashboardItem location="dashboard:learner"/>
                        </div>
                    );
                }
            }
        })
    },
 */

export function getComponents(location) {
    return _.filter(Components, function(componentItem) {
        if (componentItem.location === location) {
            return componentItem;
        }
    });
}

/**
 * @function
 * @description This is used internally to fetch registered routes. See registerRoute for more information.

 */

export function getRoutes() {
    return Routes;
}

/**
 * @function
 * @description This is used internally to fetch registered reducers. See registerReducers for more information.

 */

export function getReducers() {
    return Reducers;
}

/**
 * @function
 * @description Enables plugins to fetch registered hooks. See registerHook for more information.
 * @param {string} location String containing the location of the hook
 * @example
 * import {getHooks} from 'bloom';
 *
 * var preLoadCourseHooks = getHooks('preLoad:course');

    _.each(preLoadCourseHooks, (hook) => {
        hook(courseId);
    });
 */

export function getHooks(location) {
    return Hooks[location];
}

/**
 * @function
 * @description Enables plugins to fetch registered popups. See registerPopup for more information.
 * @param {string} location String containing the location of the popup
 * @example
 * import {getPopups} from 'bloom';
 * 
 *  componentDidMount: function() {
        getPopups('app');
        getSockets().on("server/alertsUpdated", this.onAlertsUpdated);
    },
 */

export function getPopups(location) {
    var popups = _.filter(Popups, {location: location});
    var store = Store.getState();
    var popupComponents = [];

    _.each(popups, function(popup, index) {
        if (popup.isAvailable(store)) {
            popupComponents.push(popup);
        }
    });

    if (popupComponents.length) {
        Store.dispatch(addDialog({
            customDialog: Popup,
            options: { popups: _.orderBy(popupComponents, ['priority'], ['desc'])}
        }));
    }
    
}

export function setAriaLiveRegion(payload) {
    if (payload) {
        return Store.dispatch(updateAriaLiveRegion(payload));
    }
}

export function clearAriaLiveRegion() {
    return Store.dispatch(removeAriaLiveRegion());
}

export default Bloom;