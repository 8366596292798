import React from 'react';
import createReactClass from 'create-react-class';
import classnames from 'classnames';

const DialogOverlay = createReactClass({

	getInitialState: function() {
		return {
			_isVisible: false
		};
	},
    
    componentWillAppear: function(callback) {
    	this.setState({
    		_isVisible: true
    	}, callback());
	},

	componentWillEnter: function(callback) {
		this.setState({
    		_isVisible: true
    	}, callback());
	},

	componentWillLeave: function(callback) {
		this.setState({
    		_isVisible: false
    	}, callback());
	},

	getClassName: function() {
		return classnames("dialog-overlay", {
			"is-visible": this.state._isVisible
		});
	},
    
    render: function() {
        return(
            <div className={this.getClassName()}/>
        );
    }
})

export default DialogOverlay;