import React from 'react';
import Button from '../../buttons/components/button';
import ClassNames from 'classnames';
import _ from 'lodash';

const ButtonGroupItems = function(props) {
	if (props.buttons.length === 2 && props.shouldUseToggle) {
		var item = _.find(props.buttons, (button) => {
			if (button._value === props.value) {
				return button;
			}
		});

		return (
			<Button 
				className={props.buttonType} 
				icon={item._icon}
				toolTip={item.toolTip}
				toolTipPosition={item.toolTipPosition}
				isSmall={props.isSmall}
				onClick={() => {
					var nextItem = _.find(props.buttons, (nextButton) => {
						if (nextButton._value != props.value) {
							return nextButton;
						}
					});
			
					props.onChange(nextItem);
				}} 
			/> 
		);
	} else {
		return _.map(props.buttons, (item, index) => {
			
			return (
				<Button 
					key={index} 
					className={(item._value === props.value) ? props.buttonType : ''} 
					icon={item._icon}
					toolTip={item.toolTip}
					toolTipPosition={item.toolTipPosition}
					isSmall={props.isSmall}
					onClick={() => {
						props.onChange(item._value)
					}}
				/>
			);	
		});
	}
}

const ButtonGroup = function(props) {

	return (
		<div className={ClassNames('button-group-toggle', props.className, { 'is-small': props.isSmall } )}>
			<ButtonGroupItems {...props} />
		</div>
	);
}

export default ButtonGroup;