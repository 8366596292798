import React from 'react';
import createReactClass from 'create-react-class';
import {registerType} from 'modules/form/helpers';

var FormFieldDivider = createReactClass({

    render: function() {
        return (
            <div className="form-field-divider">

                <div className="form-field-divider-content">
                    <div className="form-field-divider-title">
                        {this.props.label}
                    </div> 

                    <div className="form-field-help" dangerouslySetInnerHTML={{__html: this.props.help }}/>
                </div>

            </div>
        );
    }
})

registerType('Divider', FormFieldDivider);
export default FormFieldDivider;
