import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
    PageLoader
} from 'modules/app';
import {addDialog} from 'modules/notifications/actions/notifications';
import {updateHeader} from 'modules/app/actions/appActions';
import handleContainerError from 'modules/app/helpers/handleContainerError';
import {
    fetchMyAccount, 
    fetchMyAccountStatus, 
    fetchUpdatePaymentMethodForm,
    fetchInvoices,
    fetchInvoicePdf,
    updateUserDetails,
    addUserToSubscriptionGroup,
    removeUserFromSubscriptionGroup,
    setSeniorMember,
    removeSeniorMember,
    updateSubscription, 
    cancelSubscription,
    updateInvoice,
    updateSubscriptionGroupName,
    createSingleOrder,
    createSubscriptionOrder,
    createCoupon,
    checkExistingMembershipInKinderly,
    checkExistingMembershipInPACEY,
    getCoupon,
    isMemberOfSubscriptionGroup
} from '../dux';

import MyAccountOverview from '../components/myAccountOverview';

import MyAccountHostedPageDialog from '../components/myAccountHostedPageDialog';
import MyAccountUserDetailsDialog from '../components/myAccountUserDetailsDialog';
import MyAccountAddUserToGroupDialog from '../components/MyAccountAddUserToGroupDialog';
import MyAccountChangeSubscriptionGroupNameDialog from '../components/MyAccountChangeSubscriptionGroupNameDialog';
import MyAccountSubscriptionDialog from '../components/myAccountSubscriptionDialog';
// import MyAccountMembershipDialog from '../components/myAccountMembershipDialog';
import MyAccountInvoiceDialog from '../components/myAccountInvoiceDialog';
import updateUserDetailsSchema from '../schemas/updateUserDetailsSchema';
import addUserToGroupSchema from '../schemas/addUserToGroupSchema';
import updateSubscriptionSchema from '../schemas/updateSubscriptionSchema';
import updateInvoiceSchema from '../schemas/updateInvoiceSchema';
import changeSubscriptionGroupNameSchema from '../schemas/changeSubscriptionGroupNameSchema';
import showErrorDialog from '../helpers/showErrorDialog';

const PageContainer = createReactClass({

    contextTypes: {
        router: PropTypes.object
    },

    getInitialState: function() {

        return {
            _hasDataLoaded: false,
            _isSyncing: true,
            _isLoadingPaymentMethod: false,
            _isLoadingInvoicePdf: false,
            _statusCode: null,
            _isMemberOfSubscriptionGroup: {},
            _isUserBasic: false,
            _userBasicSelectedPlan: '',
            _userBasicSelectedPlanType: '',
            _userBasicCoupon: '',
            _canCancel: false,
            _isGroupManager: false,
            _isAnotherSubscription: false,
            errorMessage: ''
        }
    },

    componentDidMount: function() {
        this.updateHeader();
        this.fetchData();

        this.props.isMemberOfSubscriptionGroup()
        .then((response) => {
            if ( response.isMember ) {
                this.setState({
                    _isMemberOfSubscriptionGroup: response
                });
            }
        });
    }, 

    fetchData: function(forceCheckValidCard) {
        this.setState({
            _isSyncing: true
        });

        var successDialog = {
            dialogType: 'default',
            title: 'Subscription Confirmation',
            body: 'Subscription has been confirmed successfully. Thank you.',
            actions: [
                {
                    action: 'ok',
                    buttonText: 'OK',
                    buttonType: 'info'
                }
            ]
        };

        var failedDialog = {
            dialogType: 'default',
            title: 'Subscription Confirmation Error',
            body: 'Subscription has not been confirmed due to Payment error.',
            actions: [
                {
                    action: 'ok',
                    buttonText: 'OK',
                    buttonType: 'alert'
                }
            ]
        };

        return this.props.fetchMyAccount()
        .then((response) => {

            const isUserGroupManager = this.state._isMemberOfSubscriptionGroup.groupManager && (this.props.user._id === this.state._isMemberOfSubscriptionGroup.groupManager._id);
            const isUserBasic = (this.props.customer.card_status === 'no_card' && (this.props.subscription.status === 'in_trial' || this.props.subscription.status === 'cancelled') && !this.props.invoices.list.length)
                                || (this.state._isMemberOfSubscriptionGroup.isMember && !isUserGroupManager);

            this.setState({
                _isSyncing: false,
                _hasDataLoaded: true,
                _isUserBasic: isUserBasic,
                _canCancel: this.props.subscription.status === 'active'
                        || this.props.subscription.status === 'non_renewing'
                        || this.props.subscription.status === 'future'
            });

            let subscriptionType = this.props.subscriptions[this.props.subscription.plan_id] !== undefined ? this.props.subscriptions[this.props.subscription.plan_id] : false;

            if(subscriptionType.plan !== undefined
                && (
                ("small" === subscriptionType.plan
                    || "medium" === subscriptionType.plan
                    || "large" === subscriptionType.plan)
                && (this.props.subscription.status === 'active' ||  this.props.subscription.status === 'non_renewing')
                && !this.props.subscription.deleted
                )
            ) {

                // Group plan
                this.setState({
                    _isGroupManager: true
                })
            }

            // User has "another" subscription
            if(this.props.subscriptions[this.props.subscription.plan_id] === undefined) {

                this.setState({
                    _isAnotherSubscription: true
                })
            }

            if(forceCheckValidCard === true) {

                if(this.props.customer.card_status === 'valid') {

                    return this.props.updateSubscription({
                        plan_id: this.state._userBasicSelectedPlan,
                        plan: this.state._userBasicSelectedPlanType,
                        coupon: this.state._userBasicCoupon ? [this.state._userBasicCoupon] : false,
                        plan_quantity: 1
                    })
                        .then(() => {
                            this.fetchData(false)
                        })
                        .then(() => {

                            this.props.addDialog(successDialog);
                        })
                        .catch(() => {

                            this.props.addDialog(failedDialog);
                        });

                } else {

                    this.props.addDialog(failedDialog);
                }
            }
            return Promise.resolve();
        })
        .catch((response) => {
            handleContainerError(response, this);
        });
    },

    updateHeader: function() {
         this.props.updateHeader({
             breadcrumbs: [
                 {
                     text: 'My Subscriptions'
                 }
             ]
        });
    },

    onUpdateDetailsClicked: function() {

        var customDialog = {      
            customDialog: MyAccountUserDetailsDialog,
            title: 'Update Account Details',
            body: 'test',
            options: {
                subscription: this.props.subscription,
                customer: this.props.customer,
                schema: updateUserDetailsSchema     
            },
            actions: [
            {
                action: 'cancel-subscription',
                buttonText: 'CANCEL SUBSCRIPTION',
                buttonType: 'secondary',
                disabled: !this.state._canCancel,
            },
            {
                action: 'cancel',
                buttonText: 'CANCEL',
                buttonType: 'alert'
            },
            {
                action: 'save',
                buttonText: 'UPDATE',
                buttonType: 'primary',
                getIsDisabled: function (props) {
                    if (props.model.hasError) return true;
                }
            }
        ]
        }

        this.props.addDialog(customDialog)
        .then((response) => {
            if (response.action === 'save') {
                return this.props.updateUserDetails(response.payload)
                .then(this.fetchData)
                .catch(showErrorDialog);
            } else if (response.action === 'cancel-subscription') {

                return this.onCancelSubscriptionClicked();
            }
        });

    },

    onAddUserToSubscriptionGroupDialogClicked: function() {
        var customDialog = {      
            customDialog: MyAccountAddUserToGroupDialog,
            title: 'Add User To Group',
            body: '',
            options: {
                subscription: this.props.subscription,
                customer: this.props.customer,
                schema: addUserToGroupSchema     
            }
        }

        var successDialog = {
            dialogType: 'default',
            title: 'User in group',
            body: 'User has been added to the Subscription Group successfully.',
            actions: [
                {
                    action: 'ok',
                    buttonText: 'OK',
                    buttonType: 'info'
                }
            ]
        };

        this.props.addDialog(customDialog)
        .then((response) => {
            if (response.action === 'success') {
                return this.setState({
                    _isSyncing: true
                }, function() {

                    return this.props.addUserToSubscriptionGroup(response.payload.addedUser)
                        .then(this.fetchData)
                        .then(() => {
                            return this.props.addDialog(successDialog)
                        })
                        .catch(showErrorDialog)
                        .finally(() => {
                            this.setState({
                                _isSyncing: false
                            });
                        });
                });
            }
        });
    },

    onChangeSubscriptionGroupNameDialogClicked: function() {
        var customDialog = {      
            customDialog: MyAccountChangeSubscriptionGroupNameDialog,
            title: 'Change Group Name',
            options: {
                schema: changeSubscriptionGroupNameSchema,
                groupName: this.props.subscription.subscriptionGroupName
            },
            actions: [
            {
                action: 'cancel',
                buttonText: 'CANCEL',
                buttonType: 'alert'
            },
            {
                action: 'save',
                buttonText: 'UPDATE',
                buttonType: 'primary',
                getIsDisabled: function (props) {
                    if (props.model.hasError) return true;
                }
            }
        ]
        }

        var successDialog = {
            dialogType: 'default',
            title: 'Group name',
            body: 'Group name has been changed successfully.',
            actions: [
                {
                    action: 'ok',
                    buttonText: 'OK',
                    buttonType: 'info'
                }
            ]
        };

        this.props.addDialog(customDialog)
        .then((response) => {
            if (response.action === 'save') {
                return this.setState({
                    _isSyncing: true
                }, function() {

                    const data = {

                        groupName: response.payload.group_name,
                        groupManagerId: this.state._isMemberOfSubscriptionGroup.length ? this.state._isMemberOfSubscriptionGroup.groupManager._id : null
                    };

                    return this.props.updateSubscriptionGroupName( data )
                        .then(this.fetchData)
                        .then(() => {
                            return this.props.addDialog(successDialog)
                        })
                        .catch(showErrorDialog)
                        .finally(() => {
                            this.setState({
                                _isSyncing: false
                            });
                        });
                });
            }
        });
    },

    onRemoveUserFromSubscriptionGroupClicked: function(member) {

        this.setState({
            _isSyncing: true
        });

        var successDialog = {
            dialogType: 'default',
            title: 'User in group',
            body: 'User has been removed from the Subscription Group successfully.',
            actions: [
                {
                    action: 'ok',
                    buttonText: 'OK',
                    buttonType: 'info'
                }
            ]
        };

        return this.props.removeUserFromSubscriptionGroup(member)
            .then(this.fetchData)
            .then(() => {
                return this.props.addDialog(successDialog)
            })
            .catch(showErrorDialog)
            .finally(() => {
                this.setState({
                    _isSyncing: false
                });
            });

    },

    onRemoveSeniorMemberClicked: function(member) {
        
        this.setState({
            _isSyncing: true
        });

        var successDialog = {
            dialogType: 'default',
            title: 'Senior Member',
            body: 'Senior Member role has been removed successfully.',
            actions: [
                {
                    action: 'ok',
                    buttonText: 'OK',
                    buttonType: 'info'
                }
            ]
        };

        return this.props.removeSeniorMember(member)
        .then(this.fetchData)
        .then(() => {
            return this.props.addDialog(successDialog)
        })
        .catch(showErrorDialog)
        .finally(() => {
            this.setState({
                _isSyncing: false
            });
        });

    },

    onSetSeniorMemberClicked: function(member) {

        this.setState({
            _isSyncing: true
        });

        var successDialog = {
            dialogType: 'default',
            title: 'Senior Member',
            body: 'Senior Member has been set successfully.',
            actions: [
                {
                    action: 'ok',
                    buttonText: 'OK',
                    buttonType: 'info'
                }
            ]
        };

        return this.props.setSeniorMember(member)
        .then(this.fetchData)
        .then(() => {
            return this.props.addDialog(successDialog)
        })
        .catch(showErrorDialog)
        .finally(() => {
            this.setState({
                _isSyncing: false
            });
        });

    },

    onUpdatePaymentMethodClicked: function() {
        this.setState({_isLoadingPaymentMethod: true})
        return this.props.fetchUpdatePaymentMethodForm().then((response) => {

            this.setState({
                _isLoadingPaymentMethod: false
            }, function() {

                var customDialog = {      
                    customDialog: MyAccountHostedPageDialog,
                    options: {
                        url: response._hostedPage.url,
                        state: response._hostedPage.state,
                        id: response._hostedPage.id,
                        isUserBasic: this.state._isUserBasic
                    }
                }


                this.props.addDialog(customDialog)
                .then((payload) => {
                    if (payload.action === 'success') {
                        this.fetchData();
                    } else if (payload.action === 'confirm') {
                        this.fetchData(true)
                        .then( () => {
                            location.reload();
                        });
                        
                    }
                });

            });
            return Promise.resolve();
        })
        .catch(showErrorDialog);
    },

    onUpdateSubscriptionClicked: function() {
        var customDialog = {      
            customDialog: MyAccountSubscriptionDialog,
            title: 'Select your Subscription plan Details',
            body: '',
            options: {
                subscription: this.props.subscription,
                customer: this.props.customer,
                subscriptions: this.props.subscriptions,
                schema: updateSubscriptionSchema,
                getCoupon: this.props.getCoupon,
                checkExistingMembershipInKinderly: this.props.checkExistingMembershipInKinderly,
                user: this.props.user,
                checkExistingMembershipInPACEY: this.props.checkExistingMembershipInPACEY
            },
            actions: [
                {
                    action: 'cancel',
                    buttonText: 'CANCEL',
                    buttonType: 'alert'
                },
                {
                    action: 'save',
                    buttonText: this.state._isUserBasic ? 'NEXT' : 'CONFIRM',
                    buttonType: 'primary',
                    getIsDisabled: function (props) {
                        if (props.model.hasError) return true;
                    }
                }
            ]
        }

        var successDialog = {
            dialogType: 'default',
            title: 'Subscription Confirmation',
            body: 'Subscription has been confirmed successfully. Thank you.',
            actions: [
                {
                    action: 'ok',
                    buttonText: 'OK',
                    buttonType: 'info'
                }
            ]
        };

        this.props.addDialog(customDialog)
        .then((response) => {
            if (response.action === 'save') {

                // "check to get discount" checked
                // if (response.payload.checkToGetDiscount) {
                //     const userData = {email: this.props.user.email};
                //
                //     this.props.checkExistingMembershipInKinderly(userData)
                //         .then( resp => {
                //             const { success, is_pacey = false } = resp.data;
                //
                //             if (success || is_pacey) {
                //
                //                 response.payload.plan_id = 'kinderly-single-together-' + response.payload.term;
                //
                //                 if(this.state._isUserBasic) {
                //
                //                     this.setState( { _userBasicSelectedPlan: response.payload.plan_id } );
                //
                //                 } else {
                //
                //                     this.props.updateSubscription(response.payload)
                //                         .then(this.fetchData)
                //                         .then(this.props.addDialog(successDialog))
                //                         .catch(showErrorDialog)
                //                 }
                //
                //                 return true;
                //             } else {
                //                 this.membershipDialog(response.payload.term);
                //             }
                //     }).catch( () => {
                //         this.membershipDialog(response.payload.term);
                //     });
                //
                // } else if (response.payload.discount_code.length > 0) { // Coupon?
                //
                //     response.payload.coupon = response.payload.discount_code;
                //
                //     if(this.state._isUserBasic) {
                //
                //         this.setState({_userBasicSelectedPlan: response.payload.plan_id, _userBasicCoupon: response.payload.discount_code});
                //
                //         this.onUpdatePaymentMethodClicked();
                //
                //     } else {
                //         this.props.updateSubscription(response.payload)
                //             .then(this.fetchData)
                //             .then(this.props.addDialog(successDialog))
                //             .catch(showErrorDialog)
                //     }
                //
                // } else { // No coupons, standard confirmation

                    if(this.state._isUserBasic) {
                        
                        this.setState({_userBasicSelectedPlan: response.payload.plan_id, _userBasicSelectedPlanType: response.payload.plan, _userBasicCoupon: response.payload.discount_code});

                        this.onUpdatePaymentMethodClicked();

                    } else {

                        this.setState({
                            _isSyncing: true
                        }, function() {
                            return this.props.updateSubscription(response.payload)
                                .then(this.fetchData)
                                .then( () => {
                                    return this.props.addDialog(successDialog)
                                })
                                .catch(showErrorDialog)
                                .finally( () => {
                                    this.setState({
                                        _isSyncing: false
                                    });

                                    location.reload();
                                });
                        });

                    }
                // }
            }
        });
    },


    // membershipDialog: function(subscriptionTerm) {
    //     const membershipDialog = {
    //         customDialog: MyAccountMembershipDialog,
    //         title: 'PACEY membership',
    //         body: 'Please provide your PACEY details so we can check if you your account is active and if we can activate a discount for you.',
    //         actions: [
    //             {
    //                 action: 'cancel',
    //                 buttonText: 'CANCEL',
    //                 buttonType: 'alert'
    //             },
    //             {
    //                 action: 'save',
    //                 buttonText: 'CONFIRM',
    //                 buttonType: 'primary',
    //                 getIsDisabled: function (props) {
    //                     if (props.model.hasError) return true;
    //                 }
    //             }
    //         ]
    //     }
    //
    //     var paceyFailureDialog = {
    //         dialogType: 'default',
    //         title: 'PACEY check failed',
    //         body: 'We couldn\'t verify your PACEY account or it does not exist. Please try again later or contact our support.',
    //         actions: [
    //             {
    //                 action: 'ok',
    //                 buttonText: 'OK',
    //                 buttonType: 'info'
    //             }
    //         ]
    //     };
    //
    //     this.props.addDialog(membershipDialog)
    //     .then( (response) => {
    //         if(response.action === 'save') {
    //             // const fakedata = {
    //             //     "pacey_id" : "00145325",
    //             //     "surname" : "Barton",
    //             //     "postcode" : "BA23AF"
    //             // }
    //
    //             const { pacey_id,  postcode } = response.payload;
    //             const data = { pacey_id, postcode, surname: this.props.user.lastName}
    //
    //             console.log('Checking PACEY with surname: ' + this.props.user.lastName);
    //
    //             this.props.checkExistingMembershipInPACEY(data)
    //                 .then( (resp) => {
    //
    //                     if(resp.content) {
    //
    //                         // Confirm PACEY subscription
    //                         response.payload.plan_id = 'kinderly-single-pacey-' + subscriptionTerm;
    //
    //                         if(this.state._isUserBasic) {
    //
    //                             this.setState({_userBasicSelectedPlan: response.payload.plan_id});
    //
    //                             this.onUpdatePaymentMethodClicked();
    //
    //                         } else {
    //
    //                             this.props.updateSubscription(response.payload)
    //                                 .then(this.fetchData)
    //                                 .then(this.props.addDialog(successDialog))
    //                                 .catch(showErrorDialog)
    //                         }
    //                     } else {
    //                         this.props.addDialog(paceyFailureDialog)
    //                     }
    //
    //                     return response;
    //                 })
    //                 .catch(showErrorDialog)
    //
    //             return response;
    //         }
    //     })
    //     .catch(()=>{});
    // },

    onCancelSubscriptionClicked: function() {
        const cancelDialog = {
            title: 'Kinderly Subscription',
            body: 'Are you sure you want to cancel your subscription?',
            dialogType: 'default',
            actions: [
                {
                    action: 'cancel',
                    buttonText: 'CANCEL',
                    buttonType: 'alert'
                },
                {
                    action: 'save',
                    buttonText: 'CONFIRM',
                    buttonType: 'primary',
                    getIsDisabled: function (props) {
                        if (props.model.hasError) return true;
                    }
                }
            ]
        }

        var successDialog = {
            dialogType: 'default',
            title: 'Subscription Confirmation',
            body: 'Subscription has been cancelled successfully, please login again.',
            actions: [
                {
                    action: 'ok',
                    buttonText: 'OK',
                    buttonType: 'info'
                }
            ]
        };

        this.props.addDialog(cancelDialog)
            .then( (response) => {
                if(response.action === 'save') {

                    return this.setState({
                        _isSyncing: true
                    }, function() {
                        // return this.props.updateSubscription(response.payload)
                        //     .then(this.fetchData)
                        //     .then( () => {
                        //         return this.props.addDialog(successDialog)
                        //     })
                        //     .catch(showErrorDialog)
                        //     .finally( () => {
                        //         this.setState({
                        //             _isSyncing: false
                        //         });
                        //     });

                        return this.props.cancelSubscription(response.payload)
                            .then(this.fetchData)
                            .then(() => {
                                return this.props.addDialog(successDialog)
                            })
                            .catch(showErrorDialog)
                            .finally( () => {
                                this.setState({
                                    _isSyncing: false
                                });

                                window.location = '/#/logout';
                            });
                    });

                    // this.props.cancelSubscription(response.payload)
                    //     .then(this.fetchData)
                    //     .then(() => {
                    //         return this.props.addDialog(successDialog)
                    //     })
                    //     .catch(showErrorDialog)
                    //
                    // return response;
                }
            })
            .catch(showErrorDialog);
    },

    onUpdateTestSingleClicked: function() {

        var customDialog = {
            dialogType: 'default',
            title: 'Single Purchase Test',
            body: 'Addon singletest has been invoiced successfully',
            actions: [
                {
                    action: 'ok',
                    buttonText: 'OK',
                    buttonType: 'info'
                }
            ]
        };

        this.props.createSingleOrder()
        .then(this.fetchData)
        .then(() => {
            this.props.addDialog(customDialog)
        })
        .catch(showErrorDialog);
    },

    onUpdateTestSubscriptionClicked: function() {

        var customDialog = {
            dialogType: 'default',
            title: 'Subscription Purchase Test',
            body: 'Subscription has been invoiced successfully',
            actions: [
                {
                    action: 'ok',
                    buttonText: 'OK',
                    buttonType: 'info'
                }
            ]
        };

        this.props.createSubscriptionOrder()
        .then(this.fetchData)
        .then(() => {
            this.props.addDialog(customDialog)
        })
        .catch(showErrorDialog);
    },

    onUpdateInvoiceClicked: function() {

        var customDialog = {      
            customDialog: MyAccountInvoiceDialog,
            title: 'Update Invoice Details',
            options: {
                subscription: this.props.subscription,
                customer: this.props.customer,
                schema: updateInvoiceSchema
            },
            actions: [
            {
                action: 'cancel',
                buttonText: 'CANCEL',
                buttonType: 'alert'
            },
            {
                action: 'save',
                buttonText: 'UPDATE',
                buttonType: 'primary',
                getIsDisabled: function (props) {
                    if (props.model.hasError) return true;
                }
            }
        ]
        }

        this.props.addDialog(customDialog)
        .then((response) => {
            if (response.action === 'save') {
                this.props.updateInvoice(response.payload)
                .then(this.fetchData)
                .catch(showErrorDialog)
            }
        });

    },

    onGetInvoicePdfClicked: function(invoiceId) {
        this.setState({_isLoadingInvoicePdf: true})

        return this.props.fetchInvoicePdf(invoiceId).then((response) => {

            this.setState({
                _isLoadingInvoicePdf: false
            }, function() {

                if(response.download.download_url.length) {
                    window.open(response.download.download_url);
                }

            });
            return Promise.resolve();
        })
        .catch(showErrorDialog);
    },

    onSubscribeClicked: function() {

        this.onUpdateSubscriptionClicked();
    },

    onGetCoupon: function(coupon) {

        return this.getCoupon(coupon);
    },

    render: function() {
        
        return (
            <PageLoader 
                hasLoaded={this.state._hasDataLoaded} 
                hasErrored={this.state._hasErrored}
                statusCode={this.state._statusCode}
                errorMessage={this.state.errorMessage}
                text="Loading"
                >
                <MyAccountOverview
                    myAccount={this.props.myAccountDetails}
                    customer={this.props.customer}
                    subscription={this.props.subscription}
                    subscriptions={this.props.subscriptions}
                    invoices={this.props.invoices}
                    user={this.props.user}
                    isSyncing={this.state._isSyncing}
                    isLoadingPaymentMethod={this.state._isLoadingPaymentMethod}
                    isLoadingInvoicePdf={this.state._isLoadingInvoicePdf}
                    isUserBasic={this.state._isUserBasic}
                    isMemberOfSubscriptionGroup={this.state._isMemberOfSubscriptionGroup}
                    isGroupManager={this.state._isGroupManager}
                    isAnotherSubscription={this.state._isAnotherSubscription}
                    updateInvoiceSchema={updateInvoiceSchema}
                    getCoupon={this.onGetCoupon}
                    onUpdateDetailsClicked={this.onUpdateDetailsClicked}
                    onAddUserToSubscriptionGroupDialogClicked={this.onAddUserToSubscriptionGroupDialogClicked}
                    onChangeSubscriptionGroupNameDialogClicked={this.onChangeSubscriptionGroupNameDialogClicked}
                    onRemoveUserFromSubscriptionGroupClicked={this.onRemoveUserFromSubscriptionGroupClicked}
                    onSetSeniorMemberClicked={this.onSetSeniorMemberClicked}
                    onRemoveSeniorMemberClicked={this.onRemoveSeniorMemberClicked}
                    onUpdatePaymentMethodClicked={this.onUpdatePaymentMethodClicked}
                    onUpdateSubscriptionClicked={this.onUpdateSubscriptionClicked}
                    onCancelSubscriptionClicked={this.onCancelSubscriptionClicked}
                    onUpdateInvoiceClicked={this.onUpdateInvoiceClicked}
                    onUpdateTestSingleClicked={this.onUpdateTestSingleClicked}
                    onUpdateTestSubscriptionClicked={this.onUpdateTestSubscriptionClicked}
                    onGetInvoicePdfClicked={this.onGetInvoicePdfClicked}
                    onSubscribeClicked={this.onSubscribeClicked}
                />
            </PageLoader>
        );
    }

});

export default connect(function(state, props) {
    return {
        myAccountDetails: state.myKinderlySubscription._myAccountDetails,
        subscription: state.myKinderlySubscription._subscriptionDetails.subscription,
        subscriptions: state.myKinderlySubscription._subscriptions,
        customer: state.myKinderlySubscription._subscriptionDetails.customer,
        invoices: state.myKinderlySubscription._invoices,
        user: state.auth
    }
}, {
    updateHeader,
    fetchMyAccount,
    fetchMyAccountStatus, 
    fetchUpdatePaymentMethodForm,
    fetchInvoices,
    fetchInvoicePdf,
    updateUserDetails,
    addUserToSubscriptionGroup,
    removeUserFromSubscriptionGroup,
    setSeniorMember,
    removeSeniorMember,
    updateSubscription, 
    cancelSubscription,
    updateInvoice,
    updateSubscriptionGroupName,
    createSingleOrder,
    createSubscriptionOrder,
    createCoupon,
    addDialog,
    checkExistingMembershipInKinderly,
    checkExistingMembershipInPACEY,
    getCoupon,
    isMemberOfSubscriptionGroup
})(PageContainer);
