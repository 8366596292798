import React from 'react';
import createReactClass from 'create-react-class';
import {registerType} from '../helpers';
import {Button} from 'uiKit';

var FormFieldArrayString = createReactClass({

    getInitialState: function() {
        return {
            value: ''
        }
    },

    onDeleteItemClicked: function(item) {
        var items = _.pull(this.props.value, item);
        return this.props.updateField(items).then(() => {
        });
    },

    onInputChanged: function(event) {
        this.setState({
            value: event.target.value
        })
    },

    onAddClicked: function() {
        var value = this.state.value;

        if (value.length > 0) {
            var items = this.props.value || [];
            items.push(value);
            return this.props.updateField(items).then(() => {
                this.setState({
                    value: ''
                });
            });
        }
    },

    renderItems: function() {
        return _.map(this.props.value, (item) => {

            var onDeleteItemClicked = () => this.onDeleteItemClicked(item);

            return (
                <span className="form-field-array-string-item" key={item}>
                    <span>{item}</span><i onClick={onDeleteItemClicked} className="icon icon-cross"/>
                </span>
            )
        })
    },

    render: function() {

        return (

            <div className="form-field clearfix">

                <div className="form-field-label">
                    {this.props.label}
                </div>

                <div className="form-field-help">
                    {this.props.help}
                </div>

                <div>
                    <input value={this.state.value} onChange={this.onInputChanged}/>
                    <Button
                        type="primary"
                        onClick={this.onAddClicked}
                        icon="plus"
                    />
                </div>

                <div>
                    {this.renderItems()}
                </div>

            </div>

        );

    }

});

registerType('ArrayString', FormFieldArrayString);

export default FormFieldArrayString;