import React from 'react';
import createReactClass from 'create-react-class';
import moment from 'moment';
import {Button} from 'uiKit';
import MyAccountOverviewTitle from './myAccountOverviewTitle';
import MyAccountOverviewItem from'./myAccountOverviewItem'

var STATUS_MAPPING = {
    'in_trial': 'In trial',
    'active': 'Active',
    'cancelled': 'Inactive',
    'non_renewing': 'Soon to be cancelled',
    'future': 'Subscription will start soon'
}

var MyAccountOverviewSubscription = createReactClass({

    renderPlan: function() {
        return (
            <MyAccountOverviewItem
                label="Plan"
                value={
                    this.props.subscriptions[this.props.subscription.plan_id] !== undefined
                        ? this.props.subscriptions[this.props.subscription.plan_id].title
                        : this.props.subscription.plan_id
                }
            />
        );
    },

    renderUsers: function() {
        var subscription = this.props.subscription;
        var planId = subscription.plan_id;
        var editors = subscription.plan_quantity;
        if (planId === 'Monthly') {
            editors = subscription.plan_quantity * 1;
        }

        return (
            <MyAccountOverviewItem
                label="Admins"
                value={editors}
            />
        );
    },

    renderStatus: function() {
        return (
            <MyAccountOverviewItem
                label="Status"
                value={STATUS_MAPPING[this.props.subscription.status]}
            />
        );
    },

    renderTrialStartDate: function() {
        if (this.props.subscription.status === 'in_trial') {
            return (
                <MyAccountOverviewItem
                    label="Trial start date"
                    value={moment.unix(this.props.subscription.trial_start).fromNow()}
                />
            );
        }
    },

    renderTrialEndDate: function() {
        if (this.props.subscription.status === 'in_trial') {
            return (
                <MyAccountOverviewItem
                    label="Trial end date"
                    value={moment.unix(this.props.subscription.trial_end).fromNow()}
                />
            );
        }
    },

    renderEstimatedPlanPricing: function() {
        var subscription = this.props.subscription;
        var estimatedPricing = (subscription.plan_unit_price * subscription.plan_quantity) / 100;

        return (
            <MyAccountOverviewItem
                label="Estimated Plan Pricing"
                value={'£' + estimatedPricing.toFixed(2)}
            />
        );
    },

    renderBuyNowButton: function() {

        if (this.props.customer.card_status != 'valid') {
            var alert = 'A valid payment card must be setup before changing your subscription.';
            return (
                <div className="alert-info warning">
                    <div className="alert-info-inner">
                        <i className="icon icon-warning"/>
                        <span>{alert}</span>
                    </div>
                </div>
            );
        }

        if (this.props.subscription.status === 'in_trial' || this.props.subscription.status === 'cancelled') {

            return (
                this.props.isAnotherSubscription !== true && <Button text="Subscribe" type="primary" onClick={this.props.onUpdateSubscriptionClicked}/>
            );
        }

        // if (this.props.subscription.status === 'active'
        //     || this.props.subscription.status === 'non_renewing'
        //     || this.props.subscription.status === 'future'
        //
        // ) {
        //
        //     return (
        //         <Button text="Cancel subscription" type="alert" onClick={this.props.onCancelSubscriptionClicked}/>
        //     );
        // }
    },

    render: function() {

        if(this.props.isUserBasic) {
            return(
                <div />
            );
        }

        return (
            <div className="my-account-overview-section">
                <MyAccountOverviewTitle text="Plan Details"/>
                <div className="my-account-overview-content">
                    {this.renderPlan()}
                    {this.renderStatus()}
                    {this.renderTrialStartDate()}
                    {this.renderTrialEndDate()}
                    {/*{this.renderEstimatedPlanPricing()}*/}
                    {this.renderBuyNowButton()}
                </div>
            </div>
        );
    }

});

export default MyAccountOverviewSubscription;
