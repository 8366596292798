import React from 'react';
import createReactClass from 'create-react-class';
import classNames from 'classnames';
import ToolTip from'./toolTip';

const FlatButton = React.forwardRef((props, ref) => {
	
	var renderButton = function(button) {
		if(props.toolTip) {
			return (
				<ToolTip toolTip={props.toolTip} toolTipPosition={props.toolTipPosition}>
					{button}
				</ToolTip>
			)
		}
		return button
	}

	var accessibilityTags = function() {
		var ariaLabel = props.text;
		
		if (!props.text || props['aria-label']) {
			ariaLabel = props['aria-label'] ? props['aria-label'] : props.toolTip ? props.toolTip : props.icon.replace(/[0-9]/g, ''); 
		}
		
		return {tabIndex: props.tabIndex || '0', role: props.role || "button", 'aria-label': ariaLabel};
	}

	var flatButtonProps = _.merge(_.omit(props, ['type', 'text', 'icon', 'toolTip', 'toolTipPosition', 'isSmall']), accessibilityTags());

	return renderButton(
		<button ref={ref} {...flatButtonProps} className={classNames('button-flat', props.type, props.className, {
			"tool-tip-button" : props.toolTip
		})}>
			<i className={' icon icon-' + props.icon} style={(props.text && props.icon) && { marginRight: 5 }}></i>
			{props.text && 
				<span className="flat-button-text">	
					{props.text}
				</span>
			}			
		</button>
	);
})

export default FlatButton;