import React from 'react';
import createReactClass from 'create-react-class';
import {DialogTitle, DialogBody, DialogActions} from 'modules/notifications/components/dialogComponents';
import {Form} from 'modules/form';

var UserDetailsDialog = createReactClass({

    getInitialState: function() {

        var customer = this.props.options.customer.billing_address || {};

        var state = _.extend({}, customer, {hasError: false, height: window.innerHeight});

        return state;

    },
    
    handleDialogAction: function(action) {
        this.props.commitAction({
            action: action,
            payload: this.state
        });
    },

    onUpdate: function(update, hasErrored, fieldKey) {
        var updateObject = hasErrored || {hasError: false};
        updateObject[fieldKey] = update[fieldKey];
        this.setState(updateObject);
        return Promise.resolve(update);
    },

    renderForms: function() {
        var lengthOfFields = Object.keys(this.props.options.schema).length;
        var fieldLengthLeft = Math.ceil(lengthOfFields/2);
        var leftColumnSchema = {};
        var rightColumnSchema = {};
        var currentIndex = 0;

        _.each(this.props.options.schema, function(schemaItemValue, schemaItemKey, index) {
            if (currentIndex < fieldLengthLeft) {
                leftColumnSchema[schemaItemKey] = schemaItemValue;
            } else {
                rightColumnSchema[schemaItemKey] = schemaItemValue;
            }
            currentIndex ++;
        });

        return (
            <div className="clearfix">
                <div className="my-account-invoice-dialog-left-column">
                    <Form
                        schema={leftColumnSchema}
                        onUpdate={this.onUpdate}
                        model={this.state}
                    />
                </div>
                <div className="my-account-invoice-dialog-right-column">
                    <Form
                        schema={rightColumnSchema}
                        onUpdate={this.onUpdate}
                        model={this.state}
                    />
                </div>
            </div>
        );

    },
    
    render: function() {

        var height = (this.state.height - 72);
        var contentHeight = (this.state.height - 200);

        return (
            <div className="dialog my-account-invoice-dialog" style={{width:'800px', height: height + 'px'}}>
                <DialogTitle title={this.props.title}/>
                <DialogBody body={this.props.body}/>
                <div style={{height: contentHeight + 'px', overflowY: 'scroll'}}>
                    {this.renderForms()}
                    
                </div>
                <DialogActions model={this.state} actions={this.props.actions} onDialogAction={this.handleDialogAction}/>
            </div>
        );

    }
    
})

export default UserDetailsDialog;
