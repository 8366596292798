import React from 'react';
import createReactClass from 'create-react-class';
import {Button} from 'uiKit';

var PopupNavigation = createReactClass({
    render: function() {

        var direction = this.props.direction;

        return (
            <Button 
                icon={`chevron-${direction}`} 
                onClick={this.props.onClick} 
                text={this.props.text}
                iconPosition={direction}
                type="large primary"
                disabled={this.props.isDisabled}
            />
        );
    }
})

export default PopupNavigation;