import React from 'react';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import {getComponents} from 'bloom';
import LP from 'modules/app/helpers/lp';
import Cookies from 'js-cookie';
import CookieConsentBar from 'modules/app/components/cookieConsentBar';
import FrontNotificationContainer from '../containers/frontNotificationContainer';

const Front = createReactClass({

    renderComponents: function() {
        var components = _.mapKeys(getComponents('front'), 'name');

        if (window.frontSettings._frontItems.length === 0 || _.filter(window.frontSettings._frontItems, {_isEnabled: true}).length === 0) {
            window.frontSettings._frontItems = [{
                name: "login",
                _id: "1",
                _isEnabled: true
            }]
        }

        return _.map(window.frontSettings._frontItems, function(item) {
            if (item._isEnabled) {
                var componentItem = components[item.name];
                if (componentItem) {
                    var FrontItem = componentItem.component;
                    return (
                        <div className="front-item" key={item._id}>
                            <FrontItem
                                location="front" />
                        </div>
                    );
                }
            }
        });

    },

    renderCookieConsentBar: function() {

        return (
            <CookieConsentBar />
        );
    },

    render: function() {
        return (
            <div className="front">
                {this.renderComponents()}
                {this.renderCookieConsentBar()}
            </div>
        );
    }

});

export default Front;