import 'proxy-polyfill/proxy.min.js';
import React from 'react';
import createReactClass from 'create-react-class';
import ReactDOM from 'react-dom';
import Bluebird from 'bluebird';
import Login from '../login/index';
import Plugins from '../../../plugins/plugins';
import { Provider } from 'react-redux';
import Store from 'store';

import FrontContainer from './containers/frontContainer';

ReactDOM.render(<Provider store={Store}><FrontContainer/></Provider>, window.document.getElementById('wrapper'));