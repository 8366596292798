import React from 'react';
import NarrativeGalleryItem from './narrativeGalleryItem';
import Button from '../../buttons/components/button';

const NarrativeGalleryItems = (props) => {
	return _.map(props.items, (item, index) => {
		return (
			<NarrativeGalleryItem
				key={index}
				item={item}
				stage={props.stage}
				width={props.narrativeWidth}
			/>
		)
	});
}

const LeftControl = (props) => {
	return (
		<Button
			type="secondary"
			icon="arrow-left"
			onClick={() => props.onChange(props.stage - 1)}
			className="narrative-slider-controls-left"
		/>
	);
}

const RightControl = (props) => {
	return (
		<Button
			type="secondary"
			icon="arrow-right"
			onClick={() => props.onChange(props.stage + 1)}
			className="narrative-slider-controls-right"
		/>
	);
}

const NarrativeGallery = ({stage, narrativeWidth, sliderWidth, items, onChange}) => {
	var minusInt = narrativeWidth * -1;
	return (
		<div className="narrative-gallery">
			<LeftControl
				onChange={onChange}
				stage={stage}
			/>
			<div className="narrative-slider" style={{width: sliderWidth + 'px', marginLeft: stage * minusInt}}>
				<NarrativeGalleryItems
					items={items}
					stage={stage}
					narrativeWidth={narrativeWidth}
				/>
			</div>
			<RightControl
				onChange={onChange}
				stage={stage}
			/>
		</div>
	);
}

export default NarrativeGallery;