import React from 'react';
import {connect} from 'react-redux';
import createReactClass from 'create-react-class';
import Login from '../components/login';
import {addDialog} from 'modules/notifications/actions/notifications';
import axios from 'axios';
import {parse} from 'qs';
import Validator from 'validator';
import LP from 'modules/app/helpers/lp';
import DocumentTitle from 'react-document-title';

var LoginContainer = createReactClass({

    getInitialState: function() {
        return {
            email: '',
            password: '',
            error: null,
            _isLogginIn: false,
            buttonClickOrigin: '',
            displayNotification: false,
            isAuthenticationTokenVerified: false
        }
    },

    getRedirectRoute: function() {
        var query = location.search.slice(1);

        if (!query) {
            return;
        }
        query = parse(query);

        if (query && query.redirectTo) {
            var href = location.href.split('login')[0];
            href = href + query.redirectTo;
            return href;
        }
    },

    signIn: function() {
        this.setState({
            error: null
        });

        if (!this.isFormValid()) {
            return;
        }

        var {email} = this.state;

        if (authentication._emailSuffix && email.indexOf('@') === -1) {
            email += ('@' + authentication._emailSuffix);
        }

        this.setState({_isLogginIn: true});

        axios.post(`${window.location.origin}/api/login`, {
            email: email,
            password: this.state.password
        })
            .then(this.onLoginSuccess)
            .catch((error) => {
                this.setState({
                    error: error.response.data.message,
                    _isLogginIn: false
                })
            });

    },

    hasUserAlreadyEnteredCorrectAuthenticationToken: function() {
        axios.post(`${window.location.origin}/api/hasUserAlreadyEnteredCorrectAuthenticationToken`, {
            email: this.state.email,
        })
            .then((response) => {
                if (response.data._hasUserAlreadyEnteredCorrectAuthenticationToken) {
                    if (this.isFormValid()) {
                        this.setState({
                            error: ''
                        })
                        this.signIn();
                    }
                } else {
                    this.toggleAuthenticationTokenDialog();
                }
            })
            .catch((error) => {
                this.setState({
                    error: LP('login', 'invalidEmailOrPassword', 'sentencecase')
                })
            })
    },

    isFormValid: function() {
        var isValid = true;

        if (this.state.password.length < 8 || (!Validator.isEmail(this.state.email) && !authentication._emailSuffix)) {
            isValid = false;
        }

        return isValid;
    },

    navigateToOpenRegister: function(event, originButtonName) {
        const defaultLocation = `${window.location.origin}/register`;
        const groupQuery = window.location.pathname.split('/')[2];
        const location = groupQuery ? `${defaultLocation}/group/${groupQuery}` : defaultLocation;

        window.location = location;
    },

    onEmailChanged: function(event) {
        this.setState({
            email: event.target.value,
            error: null
        });
    },

    onPasswordChanged: function(event) {
        this.setState({
            password: event.target.value,
            error: null
        });
    },

    onLoginSuccess: function() {
        var redirectTo = this.getRedirectRoute();
        if (redirectTo) {
            location.replace(redirectTo);
        } else {
            location.reload();
        }
    },

    onSignInClicked: function(event) {
        event.preventDefault();
        if (authentication._shouldUseAuthenticationToken) {
            this.hasUserAlreadyEnteredCorrectAuthenticationToken();
        } else {
            if (this.isFormValid()) {
                this.setState({
                    error: ''
                })
                this.signIn();
            } else {
                this.setState({
                    error: LP('login', 'invalidEmailOrPassword', 'sentencecase')
                })
            }
        };
    },

    onKeyPress: function(event) {
        if (event.which === 13) {
            if (this.state.email && this.state.password) {
                this.onSignInClicked(event);
            }
        }
    },

    navigateToSSOLocation: function(ssoType) {
        var location = `/api/oauth/${ssoType}`;

        if (window.location.search.indexOf('redirectTo=') != -1) {
            location = `${location}${window.location.search}`;
        } else if (window.location.hash) {
            location = `${location}?redirectTo=${encodeURIComponent(window.location.hash)}`;
        }

        window.location = location;
    },

    toggleAuthenticationTokenDialog: function() {
        this.setState({
            displayAuthTokenDialog: !this.state.displayAuthTokenDialog
        })
    },

    handleAuthTokenChanged: function(event) {
        this.setState({
            authenticationToken: event.target.value,
            authenticationTokenError: ''
        })
    },

    updateAuthenticationTokenVerificationStatus: function(status) {

        this.setState({
            displayAuthTokenDialog: status === false ? true : false,
            isAuthenticationTokenVerified: status,
        });

        if (status === true) {
            if (this.state.buttonClickOrigin) {
                this.navigateToSSOLocation(this.state.buttonClickOrigin)
            } else {
                this.signIn();
            }
        }
    },

    onGoogleOAuthClicked: function(event) {
        event.preventDefault();
        if (authentication._shouldUseAuthenticationToken) {
            this.toggleAuthenticationTokenDialog();
            this.setState({
                buttonClickOrigin: 'google'
            });
        } else {
            this.navigateToSSOLocation('google');
        }
    },

    onLinkedInOAuthClicked: function(event) {
        event.preventDefault();
        if (authentication._shouldUseAuthenticationToken) {
            this.toggleAuthenticationTokenDialog();
            this.setState({
                buttonClickOrigin: 'linkedIn'
            });
        } else {
            this.navigateToSSOLocation('linkedIn');
        }
    },

    onTwitterOAuthClicked: function(event) {
        event.preventDefault();
        if (authentication._shouldUseAuthenticationToken) {
            this.toggleAuthenticationTokenDialog();
            this.setState({
                buttonClickOrigin: 'twitter'
            });
        } else {
            this.navigateToSSOLocation('twitter');
        }
    },

    onFacebookOAuthClicked: function(event) {
        event.preventDefault();
        if (authentication._shouldUseAuthenticationToken) {
            this.toggleAuthenticationTokenDialog();
            this.setState({
                buttonClickOrigin: 'facebook'
            });
        } else {
            this.navigateToSSOLocation('facebook');
        }
    },

    onAzureOAuthClicked: function(event) {
        event.preventDefault();
        if (authentication._shouldUseAuthenticationToken) {
            this.toggleAuthenticationTokenDialog();
            this.setState({
                buttonClickOrigin: 'azure'
            });
        } else {
            this.navigateToSSOLocation('azure');
        }
    },

    render: function() {
        return (
            <DocumentTitle title={`${generalSettings._htmlTitle}: ${LP('login', 'login', 'titlecase')}`}>
                <Login
                    {...this.state}
                    onEmailChanged={this.onEmailChanged}
                    onPasswordChanged={this.onPasswordChanged}
                    onLoginSuccess={this.onLoginSuccess}
                    onSignInClicked={this.onSignInClicked}
                    onKeyPress={this.onKeyPress}
                    onGoogleOAuthClicked={this.onGoogleOAuthClicked}
                    onLinkedInOAuthClicked={this.onLinkedInOAuthClicked}
                    onTwitterOAuthClicked={this.onTwitterOAuthClicked}
                    onFacebookOAuthClicked={this.onFacebookOAuthClicked}
                    onAzureOAuthClicked={this.onAzureOAuthClicked}
                    isFormValid={this.isFormValid()}
                    navigateToOpenRegister={this.navigateToOpenRegister}
                    toggleAuthenticationTokenDialog={this.toggleAuthenticationTokenDialog}
                    onAuthTokenInputChange={this.handleAuthTokenChanged}
                    onAuthenticationTokenVerificationStatusChanged={this.updateAuthenticationTokenVerificationStatus}
                />
            </DocumentTitle>
        );
    }

});

export default LoginContainer;
