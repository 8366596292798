import React from 'react';
import createReactClass from 'create-react-class';
import {DialogTitle, DialogBody, DialogActions} from 'modules/notifications/components/dialogComponents';
import {Form} from 'modules/form';
import RegisterNewUserContainer from '../containers/registerNewUserContainer';

var MyAccountAddUserToGroupDialog = createReactClass({

    onUserAdded: function(addedUser) {
        return this.props.commitAction({
            action: 'success',
            payload: {
                addedUser: addedUser
            }
        });
    },

    onCancelDialog: function() {
        return this.props.commitAction({
            action: 'cancel'
        });
    },

    getInitialState: function() {
        return {
            hasError: false
        }
    },
    
    handleDialogAction: function(action) {
        this.props.commitAction({
            action: action,
            payload: {}
        });
    },

    onUpdate: function(update, hasErrored, fieldKey) {
        var updateObject = hasErrored || {hasError: false};
        updateObject[fieldKey] = update[fieldKey];
        this.setState(updateObject);
        return Promise.resolve(update);
    },
    
    render: function() {

        return (
            <div className="dialog">
                <RegisterNewUserContainer 
                    onUserAdded={this.onUserAdded}
                    onCancelDialog={this.onCancelDialog}
                />
            </div>
        );

    }
    
})

export default MyAccountAddUserToGroupDialog;
