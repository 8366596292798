import React from 'react';
import classNames from 'classnames';

const ActionBar = (props) => {
	return (
		<div className={classNames('action-bar', props.className)}>
			<div className='action-bar-inner clearfix'>
				{props.children}
			</div>
		</div>
	);
};

export default ActionBar;