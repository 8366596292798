import React from 'react';
import createReactClass from 'create-react-class';
import {registerType} from '../helpers';
import TextToggle from 'modules/app/components/textToggle';

var FormFieldToggleInput = createReactClass({

    getInitialState: function() {
        return {
            inputField: this.props.model[this.props.inputSettings.inputKey] || '',
            booleanInput: this.props.model[this.props.fieldKey] || false,
            editLockButtonDisabled: true,
            error: ''
        }
    },
    
    onToggleChange: function(event) {
        if (this.state.booleanInput && !this.state.editLockButtonDisabled) {
            this.setState({
                editLockButtonDisabled: true
            })
        }
      
        this.setState({
            booleanInput: event
        });
      
        this.updateFields();
    },

    onInputFieldChange: function(event) {
        var value = event.target.value;
        var {inputSettings} = this.props;

        // If no spaces setting enabled, this statement will replace not allow the user to user spaces
        if (inputSettings.textSettings === 'no-spaces') {
            value = event.target.value.replace(/[^A-Za-z0-9\-]/g, '');
        };
        
        if (inputSettings.type === 'Number' && value !== parseInt(value)) {   
            // This statement will not allow the user to choose 0 and will ensure if the form is using numbers that the input value and the value sent up are numbers and not strings
            if (parseInt(value) === 0) {
                value = 1;
            }
            this.setState({
                inputField: inputSettings.type === 'Number' ? parseInt(value) : value
            })
        }

        if (this.props.inputSettings.textSettings === 'no-spaces') {
            value = event.target.value.replace(/[^A-Za-z0-9\-]/g, '');
        }

        this.setState({
            inputField: value,
            error: ''
        });

        this.updateFields();
    },

    getTransitionStyles: function() {
        return {'transition': 'background-color 0.25s ease-in, color 0.3s, border-color 0.3s'}
    },

    getInputSettings: function() {
        var {inputSettings} = this.props;
        var min = _.get(inputSettings, 'min', 0);
        var max = _.get(inputSettings, 'max', null);

        switch (inputSettings.type) {
            case 'Text':
                return {type: "text", minLength: min, maxLength: max};
            case 'Number':
                return {type: "number", min: min, max: max};
        }
    },

    getInputButtonDisabledStatus: function() {
        var {inputSettings} = this.props;
        if (inputSettings.editLockButton) {
            if (this.state.editLockButtonDisabled || !this.state.booleanInput) {
                return true;
            }
        } else {
            return false;
        }
    },

    handleEditLockButtonClick: function() {
        this.setState({
            editLockButtonDisabled: !this.state.editLockButtonDisabled
        })
    },

    handleInputFieldBlur: function(event) {
        var {inputSettings} = this.props;
        if (inputSettings.min) {
            if (event.target.value.length < inputSettings.min) {
                this.setState({
                    booleanInput: false,
                    editLockButtonDisabled: true,
                    error: `This option will automatically disable if it does not have a minimum length of ${inputSettings.min}`
                })
                this.updateFields();
            }
        }
    },

    updateFields: _.debounce(function() {
        var updateObject = {};
        updateObject[this.props.fieldKey] = this.state.booleanInput;
        updateObject[this.props.inputSettings.inputKey] = this.state.inputField;

        this.props.updateMultipleFields(updateObject);
    }, 300, {leading: false}),

    renderHelpText: function() {
        var {inputSettings} = this.props;
        var helpText = inputSettings.type === 'Text' ? `Only alphanumeric and ' - ' are allowed. Case sensitive.` : '';
        if (inputSettings.min && inputSettings.max) {
            return `${helpText} Must have a minimum length of ${inputSettings.min} and a maximum length of ${inputSettings.max} characters`
        } else if (inputSettings.min) {
            return `${helpText} Must have a minimum length of ${inputSettings.min} characters`
        } else if (inputSettings.max) {
            return `${helpText} Must have a maximum length of ${inputSettings.max} characters`
        }
        return helpText;
    },

    render: function() {

        return (

            <div className="form-field">

                <div className="form-field-label">
                    {this.props.label}
                </div>

                <div className="form-field-help">
                    {this.props.help}
                    {this.renderHelpText()}
                </div>

                <div className="form-field-toggle-inputs">
                  
                    {this.props.inputSettings.editLockButton && <button
                        className="form-field-toggle-input-button"
                        onClick={this.handleEditLockButtonClick}
                        disabled={!this.state.booleanInput}
                    >
                        <i className={`icon ${this.state.editLockButtonDisabled ? 'icon-lock' : 'icon-unlock'}`}></i>
                    </button>}
        
                    <input
                        {...this.getInputSettings()}
                        style={this.getTransitionStyles()}
                        id={this.props.fieldKey}
                        className="form-field-input form-field-toggle-input"
                        value={this.state.inputField}
                        onChange={this.onInputFieldChange}
                        onBlur={this.props.onBlur}
                        onFocus={this.props.onFocus}
                        disabled={this.getInputButtonDisabledStatus()}
                    />

                    <TextToggle
                        className="form-field-toggle-button-text-toggle"
                        value={this.state.booleanInput}
                        isSmall
                        falseText={this.props.toggles[0].label || 'False'}
                        trueText={this.props.toggles[1].label || 'True'}
                        onChange={this.onToggleChange}
                    />

                </div>

                <div className="form-field-error">
                    {this.props.error}
                    {this.state.error}
                </div>

            </div>

        );

    }

});

registerType('ToggleInput', FormFieldToggleInput);

module.exports = FormFieldToggleInput;