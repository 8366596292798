import React from 'react';
import createReactClass from 'create-react-class';
import Store from 'store';
import {combineReducers} from 'redux';
import Front from '../components/front';
import {getReducers} from 'bloom';
import axios from 'axios';
import LP from 'modules/app/helpers/lp';

var FrontContainer = createReactClass({

    componentDidMount: function() {
        // Combine all reducers registered through registerReducers
        var reducers = combineReducers(getReducers());
        // Replace as reducer already exists
        Store.replaceReducer(reducers);
    },

    render: function() {
        return (
            <Front
                {...this.state}
                toggleNotification={this.toggleNotification}
                handleDialogChange={this.handleDialogChange}
                onAuthenticationTokenDialogSignInClick={this.onClick}
            />
        );
    }

});

export default FrontContainer;