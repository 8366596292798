import Promise from 'bluebird';
import {ADD_DIALOG, REMOVE_DIALOG, ADD_TOAST, REMOVE_TOAST} from '../reducers/notificationsReducer';

export function addDialog(dialog){
    return dispatch => {
        var promise = new Promise(function(resolve) {
            dialog.commitAction = function(action) {
                dispatch(removeDialog());
                resolve(action);
            };
        });
        dispatch({
            type: ADD_DIALOG,
            payload: dialog
        });
        return promise;
    }
}


export function removeDialog(){    
    return dispatch => {
        dispatch({
            type: REMOVE_DIALOG
        });
        return Promise.resolve();
    }
}

export function addToast(toast){   
    return dispatch => {
        var promise = new Promise(function(resolve, reject){
            if (toast.action) {
                toast.commitAction = function() {
                    dispatch(removeToast());
                    resolve();
                };              
                if(toast.options && toast.options.duration) {
                    if(toast.options.errorOnTimeout === true) {
                        toast.onTimeout = reject.bind(null, new Error('timeout'));
                    } else {
                        toast.onTimeout = resolve.bind(null, 'timeout');
                    }                                       
                }             
            } else {
                toast.onTimeout = resolve;
            }           
        });       
        dispatch({
            type: ADD_TOAST,
            payload: toast
        });
        return promise;
    }
}


export function removeToast(){
    return dispatch => {
        dispatch({
            type: REMOVE_TOAST
        });
        return Promise.resolve();
    }
}