import React, { Fragment } from 'react';
import FlatButton from '../../buttons/components/flatButton';
import ListItemWrapper from './listItemWrapper';

const Content = function(props) {
	
	if (!props.component) {
		var buttons = _.map(props.buttons, function(button, key){
			return (
				<FlatButton 
					{...button}
					key={key}
				/>
			)
		});

		var listItemContentstyle = !props.icon ? { paddingLeft: '8px' } : null;
		
		return (
			<Fragment>
				<div className="list-item-icon">
					{ props.icon && <i className={"icon icon-" + props.icon} /> } 
				</div>
				<div className="list-item-content">
					<div className="list-item-content-inner" style={listItemContentstyle}>
						<p className="list-item-text">
							{props.itemText}
						</p>
						{props.itemDetail && 
							<p className="list-item-detail">
								{props.itemDetail}
							</p>
						}
					</div>
				</div>
				<div className="list-item-buttons">
					{buttons}
				</div>
			</Fragment>
		);
	} else {
		return props.component;
	}
}

const ListItem = function(props) {
	return (
		<li className={`list-item ${props.className || null}`} role="listitem" aria-label={props.ariaLabel || null}>
			<ListItemWrapper {...props}>
				<Content 
					{...props}
				/>
			</ListItemWrapper>
		</li>
	)
}

export default ListItem;