import React from 'react';
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';
import classNames from 'classnames';
import {Button} from 'uiKit';

export const DialogTitle = createReactClass({
    
    render: function() {
        if (!this.props.title) {
            return null;
        }
        return (
            <h1 className={classNames('dialog-title', this.props.className)}>
                {this.props.title}
            </h1>
        );
    }
});

export const DialogBody = createReactClass({
    
    render: function() {
        if (!this.props.body) {
            return null;
        }
        return (
            <p className={classNames('dialog-body', this.props.className)}>
                {this.props.body}
            </p>
        );
    }
});

export const DialogExtra = createReactClass({

    renderExtraContent: function() {
        return this.props.extra.map(function(item, index) {
            return (
                <div key={index} className="dialog-extra-content">{item}</div>
            )
        })
    },

    render: function() {
        if (!this.props.extra) {
            return null;
        }
        return (
            <div className={classNames('dialog-extra', this.props.className)}>
                {this.renderExtraContent()}
            </div>
        )
    }
})

export const DialogActions = createReactClass({
    
    handleAction: function(action, event) {
        event.preventDefault();
        this.props.onDialogAction(action);
    },

    renderActions: function() {
        return this.props.actions.map(function(dialogAction) {
            return (
                <Button
                    key={dialogAction.action}
                    className={dialogAction.buttonType ? "button " + dialogAction.buttonType : "button primary"}
                    onClick={this.handleAction.bind(this, dialogAction.action)}
                    text={dialogAction.buttonText}
                    disabled={dialogAction.disabled} />
            );
        }.bind(this));
    },

    render: function() {
        var actions = this.props.actions;
        if (!actions || !Array.isArray(actions) || actions.length === 0) {
            return null;
        }
        return (
            <div className={classNames('dialog-actions', this.props.className)}>
                {this.renderActions()}
            </div>
        )
    }
});