
export const UPDATE_ARIA_LIVE_REGION = 'UPDATE_ARIA_LIVE_REGION';
export const REMOVE_ARIA_LIVE_REGION = 'REMOVE_ARIA_LIVE_REGION';

export function updateAriaLiveRegion(payload) {
    
    return (dispatch) => {

        dispatch({
            type: UPDATE_ARIA_LIVE_REGION,
            payload: payload
        });

        return payload;
    }
}

export function removeAriaLiveRegion() {

    var accessibility = {
        ariaLiveText: ''
    }

    return (dispatch) => {
        dispatch({
            type: REMOVE_ARIA_LIVE_REGION,
            payload: accessibility
        });
    }
}