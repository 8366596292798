import LP from 'modules/app/helpers/lp';

export default function() {
    return [{
        _step: 'groupsIntroduction',
        _type: 'dialog',
        title: LP('groups', 'groups', 'sentencecase'),
        body: LP('groups', 'groupsDescription', 'sentencecase'),
        buttonText: LP('groups', 'createGroup', 'sentencecase')
    }, {
        _step: 'createGroup',
        _type: 'dialog',
        title: LP('groups', 'groups', 'sentencecase'),
        body: "Here you can add, edit and delete Groups. Click 'Create a group' to get started. You'll need to fill out a name, as well as a friendly 'display name' that will display to your learners. You can also add a helpful description about the types of users you will have in this group.",
        _location: '/groups'
    }, {
        _step: 'createGroupForm',
        _type: 'dialog',
        title: LP('groups', 'groups', 'sentencecase'),
        body: "",
        buttonText: LP('groups', 'createGroup', 'sentencecase'),
        _location: '/groups',
        _isOverlayHidden: true
    }, {
        _step: 'editGroup',
        _type: 'dialog',
        title: LP('groups', 'groups', 'sentencecase'),
        body: "Now that you've setup a Group, let's click on Edit Group and add a Subject.",
        _location: '/groups',
        _isOverlayHidden: false
    }, {
        _step: 'subjectsTab',
        _type: 'dialog',
        title: LP('subjects', 'subjects', 'sentencecase'),
        body: "On this screen you can edit the Group's title and other characteristics. However, we're more interested in creating a Subject to sit under the Group. Next, click on the Subjects tab.",
        _isOverlayHidden: false,
        _offsetTop: 140
    }, {
        _step: 'createSubject',
        _type: 'dialog',
        title: LP('subjects', 'subjects', 'sentencecase'),
        body: "Here we can select existing subjects or create a new Subject to sit inside this Group. Let's go ahead and create a new Subject by clicking 'Add new Subject'.",
        _isOverlayHidden: false
    }, {
        _step: 'createSubjectForm',
        _type: 'dialog',
        title: LP('subjects', 'subjects', 'sentencecase'),
        body: "",
        _isOverlayHidden: true
    }, {
        _step: 'editSubject',
        _type: 'dialog',
        title: LP('subjects', 'subjects', 'sentencecase'),
        body: "Now that you've setup a Subject, let's edit the Subject and add a Trail.",
        _isOverlayHidden: false
    }, {
        _step: 'trailsTab',
        _type: 'dialog',
        title: LP('trails', 'trails', 'sentencecase'),
        body: "On this screen you can edit the Subject's title and other characteristics. For this quick start guide, we're more interested in creating a Trail to sit under the Subject. Next, click on the Trails tab.",
        _isOverlayHidden: false,
        _offsetTop: 140
    }, {
        _step: 'createTrail',
        _type: 'dialog',
        title: LP('trails', 'trails', 'sentencecase'),
        body: "Here we can select existing Trails or create a new Trail to sit inside this Subject. Trails are effectively learning paths or a collection of courses and content. Let's go ahead and create a new Trail by clicking on 'Add new Trail'",
        _isOverlayHidden: false
    }, {
        _step: 'createTrailForm',
        _type: 'dialog',
        title: LP('trails', 'trails', 'sentencecase'),
        body: "",
        _isOverlayHidden: true
    }, {
        _step: 'editTrail',
        _type: 'dialog',
        title: LP('trails', 'trails', 'sentencecase'),
        body: "Now that you've setup a Trail, let's edit the Trail and add a Course.",
        _isOverlayHidden: false
    }, {
        _step: 'coursesTab',
        _type: 'dialog',
        title: LP('courses', 'courses', 'sentencecase'),
        body: "On this screen you can edit the Trail's title and other characteristics. However, we're more interested in creating a Course to sit under the Trail. Find the Courses tab.",
        _isOverlayHidden: false,
        _offsetTop: 140
    }, {
        _step: 'createCourse',
        _type: 'dialog',
        title: LP('courses', 'courses', 'sentencecase'),
        body: "Here we can select existing courses or create a new Course to sit inside this Trail. Courses can be a Scorm package, an Evolve course, a document, or even an external link. For this next example, we'll be using an Evolve course.",
        _isOverlayHidden: false
    }, {
        _step: 'createCourseForm',
        _type: 'dialog',
        title: LP('courses', 'courses', 'sentencecase'),
        body: "",
        _isOverlayHidden: true
    }, {
        _step: 'editCourse',
        _type: 'dialog',
        title: LP('courses', 'courses', 'sentencecase'),
        body: "Now that you've setup a Course, let's edit the Course and add an Evolve Course.",
        _isOverlayHidden: false
    }, {
        _step: 'addEvolveCourse',
        _type: 'dialog',
        title: LP('courses', 'courses', 'sentencecase'),
        body: "Scroll down to find the upload course area and click to then select your Evolve course. Before going forward please make sure you have published an Evolve course and selected Bloom in the publishing options.",
        _isOverlayHidden: false,
        buttonText: "I have uploaded an Evolve course."
    }, {
        _step: 'navigateToUserThroughMenu',
        _type: 'dialog',
        title: LP('users', 'users', 'sentencecase'),
        body: "Great work! The only things left to do now is to add some learners to your Group. Let's navigate to the Users area.",
        _isOverlayHidden: false,
        buttonText: "Navigate to Users"
    }, {
        _step: 'createUser',
        _type: 'dialog',
        title: LP('users', 'users', 'sentencecase'),
        body: "We've reached the end of our guide! From here you can manage all your users. Try adding a single one to start with. You can modify the emails users get sent in the settings -> translations section, or follow our user guide documentation.",
        _isOverlayHidden: false,
        buttonText: "Finish guide",
        _location: "/users"
    }]
}