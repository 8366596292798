import React from 'react';
import createReactClass from 'create-react-class';
import {Button} from 'uiKit';
import classnames from 'classnames';

var ToggleButton = createReactClass({

    getClassName: function() {
        return classnames("toggle-button", this.props.className);
    },

    renderToggles: function() {
        return _.map(this.props.toggles, (toggle) => {
            var type = "";
            if (this.props.value === toggle.value) {
                type = "primary";
            }
            return (
                <Button 
                    key={toggle.value}
                    type={type} 
                    icon={toggle.icon} 
                    text={toggle.text} 
                    className="toggle-button-toggle" 
                    onClick={() => this.props.onClick(toggle.value)}
                />
            );
        });
    },

    render: function() {
        return (
            <div className={this.getClassName()}>
                {this.renderToggles()}
            </div>
        );
    }
});

export default ToggleButton;