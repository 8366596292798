import React from 'react';
import createReactClass from 'create-react-class';
import {registerType} from '../helpers';
import Field from '../components/field.jsx';
import classnames from 'classnames';
const ENTER_KEY = 13;
const SPACE_KEY = 32;

var FormFieldBoolean = createReactClass({

    onChange: function() {
        var value = !(!!this.props.value);
        this.props.updateField(value);
    },

    onKeyPress: function({ which }) {
        if (which === ENTER_KEY || which === SPACE_KEY) {
            this.onChange();
        }
    },

    renderField: function({ ariaDescribedBy }) {
        return (
            <div
                id={this.props.fieldId}
                tabIndex="0"
                role="checkbox"
                aria-checked={!!this.props.value}
                aria-describedby={ariaDescribedBy}
                onClick={this.onChange}
                onKeyPress={this.onKeyPress} 
                className={classnames("form-field-boolean-toggle", { 'is-checked': !!this.props.value})}/>
        );
    },

    render: function() {
        return (
            <Field 
                fieldId={this.props.fieldId}
                label={this.props.label}
                help={this.props.help}
                error={this.props.error}>
                {this.renderField}
            </Field>
        );
    }

});

registerType('Boolean', FormFieldBoolean);

export default FormFieldBoolean;