import React, {useState} from 'react';
import Button from '../../buttons/components/button';
import classNames from 'classnames';
import LP from 'modules/app/helpers/lp';

const SearchBar = ({isSmall, className, onChange, placeholder}) => {
	const [value, setValue] = useState('');

	var getButton = () => {
		if (!value.length) {
			return (
				<Button icon='magnifier' className='search-bar-button' isSmall={isSmall} />
			)
		} else {
			return (
				<Button icon='cross-circle' className='primary search-bar-button' onClick={onButtonClicked} isSmall={isSmall} />
			)
		}
	}

	var getClassName = () => {
		return classNames('search-bar', className, isSmall);
	}

	var onButtonClicked = (event) => {
		event.preventDefault();
		setValue('');
		onChange('');
	}

	var onInputChange = (event) => {
		var value = event.target.value;
		onChange(value);
		setValue(value);
	}

	return (
		<div className={getClassName()}>
			<input className='search-bar-input' aria-label={LP('search', 'search')} value={value} onChange={onInputChange} placeholder={placeholder} />
			{getButton()}
		</div>
	)
}

export default SearchBar;