import React from 'react';
import createReactClass from 'create-react-class';
import {ListItem, ListItems, Loading, Button, Pagination, CheckListItem, ActionBar, SearchBar} from 'uiKit';
import moment from 'moment';
import { withRouter } from 'react-router-dom'

var UsersOverview = createReactClass({

    onEditClicked: function(userId) {
        this.props.history.push('/users/' + userId + '/edit');
    },

    onUsersInTrialChecked: function(test) {

        this.props.onFetchData(test);
    },

    renderUsers: function() {

        return _.map(this.props.users, (user) => {

            var _itemText = user.firstName + ' ' + user.lastName +
                ' (registered ' + moment(user._createdAt).format('DD/MM/YYYY') + ') '+
                user._role + ', groups [' +
                user._groups.map(function(group){
                    return group.displayTitle;
                }).join(", ") + ']';

            if(this.props.usersInTrial) {
                _itemText = user.firstName + ' ' + user.lastName + ', ' + user.email;
            }

            return (
                <ListItem
                    key={user._id}
                    itemText={_itemText}
                    buttons={[{
                            icon:"pencil4",
                            type: "primary",
                            toolTip: "Edit user",
                            onClick: (event) => {
                                event.preventDefault()
                                this.onEditClicked(user._id)
                            }
                    }]}
                />
            );
        });
    },

    render: function() {

        return (
            <div className="users-overview">
                <Loading text="Loading users" hasDataLoaded={!this.props.isSyncing}>
                    <div className="users-overview-list">
                        <div className="users-pagination">
                            <div className="groups-collection">
                                <ActionBar>
                                    <div className="action-bar-left">
                                        <CheckListItem
                                            isChecked={this.props.usersInTrial}
                                            onCheck={this.onUsersInTrialChecked}>
                                            <div>
                                                Show "Free Trial - Week 2" users only
                                            </div>
                                        </CheckListItem>
                                    </div>
                                    <div className="action-bar-center">
                                        <Pagination
                                            isSmall={true}
                                            className="action-bar-item"
                                            currentPage={this.props.currentPage}
                                            totalPages={this.props.totalPages}
                                            usersInTrial={this.props.usersInTrial}
                                            buttonType="secondary"
                                            onPreviousPageClicked={this.props.onPreviousPageClicked}
                                            onNextPageClicked={this.props.onNextPageClicked}
                                            hasDataLoaded={!this.props.isSyncing}
                                        />
                                    </div>

                                    <div className="action-bar-right">
                                        <Button 
                                            isSmall={true}
                                            className="action-bar-item-left"
                                            text="Import Users to Subscription Group" 
                                            type="secondary" 
                                            icon="users-plus" 
                                            onClick={this.props.onImportUsersToSubscriptionGroupClicked}/>
                                    </div>
                                    {/* <div className="action-bar-right">
                                        <Button 
                                            className="is-small"
                                            text="Add User to Subscription Group" 
                                            type="secondary"
                                            icon="users-plus" 
                                            onClick={this.props.onAdminAddUserToSubscriptionGroupClicked}/>
                                    </div> */}
                                </ActionBar>
                            </div>
                        </div>
                        <ListItems items={this.renderUsers()} />
                    </div>
                </Loading>
            </div>
        );
    }
});

export default withRouter(UsersOverview);
