import store from 'store';
import {addDialog} from 'modules/notifications/actions/notifications';

export default function(error) {

    if (error.response.data && error.response.data.message) {

        var dialog = {
            dialogType: 'default',
            title: 'Error',
            body: error.response.data.message,
            actions: [
                {
                    action: 'ok',
                    buttonText: 'OK',
                    buttonType: 'alert'
                }
            ]
        };

    }

    if (!dialog) return Promise.resolve();

    return store.dispatch(addDialog(dialog)).then((payload) => {
    });

}
