import React from 'react';
import classNames from 'classnames';
import ToolTip from './toolTip';

const Button = React.forwardRef((props, ref) => {
    
    var getLeftIcon = function() {
        if (props.icon) {
            if (props.iconPosition === 'left' || !props.iconPosition) {
                if (!props.text) {
                    return (
                        <i className={`icon icon-${props.icon}`}> </i>
                    );
                }
                return (
                    <i className={classNames('button-icon-position-left', `icon icon-${props.icon}`)}></i>
                );
            }
        }
    }
    
    var getRightIcon = function() {
        if (props.icon && props.iconPosition === 'right') {
            return (
                <i className={classNames('button-icon-position-right', `icon icon-${props.icon}`)}> </i>
            );
        }
    }
    
    var renderButton = function(button) {
        if(props.toolTip) {
            return (
                <ToolTip toolTip={props.toolTip} toolTipPosition={props.toolTipPosition || 'top'}>
                    {button}
                </ToolTip>
            )
        }
        return button;
    }

    var accessibilityTags = function() {
        var ariaLabel =  props['aria-label'] ?  props['aria-label'] :  props.text;
        var role =  props.role ?  props.role : 'button';
        
        return _.merge({tabIndex:  props.tabIndex || '0', role: role, 'aria-label': ariaLabel});
    }

    var buttonProps = _.merge(_.omit(props, ['type', 'text', 'icon', 'iconPosition', 'toolTip', 'toolTipPosition', 'isSmall', 'isLarge']), accessibilityTags());
    

    return renderButton(
        <button
            ref={ref}
            {...buttonProps}
            className={classNames('button', props.type, props.className, {
                "is-small" : props.isSmall,
                "tool-tip-button": props.toolTip
            })}>
            {getLeftIcon()}
            {props.text}
            {getRightIcon()}
        </button>
    );
})

export default Button;