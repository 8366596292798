import React from 'react';
import FlatButton from '../../buttons/components/flatButton';

const Message = function(props) {
					
	return (
		<div className="message-item clearfix">
			<FlatButton 
				className="message-item-close"
				icon="cross"
				onClick={props.onCloseButtonClicked}/>
				
			<i className={`message-item-icon icon icon-${props.icon}`} style={props.iconColor && { color: props.iconColor }} />
						
			<div className="message-item-content clearfix" onClick={props.onClick}>	
				<div className="message-item-content-left"> 	
					{props.avatar && 
						<img src={props.avatar} className="message-item-image"/>
					}
				</div>
				<div className="message-item-content-right">
					{props.title && 
						<h3 className="message-item-title">
							{props.title}
						</h3>
					}
					{props.body && 
						<div className="message-item-body">
							{props.body}
						</div>
					}
					{props.author && 
						<div className="message-item-author">
							{props.author}
						</div>
					}
				</div>
			</div>
		</div>
	);
}

export default Message;