import axios from 'axios';
import Bluebird from 'bluebird';

export const AUTHENTICATED = 'AUTHENTICATED';
export const NOT_AUTHENTICATED = 'NOT_AUTHENTICATED';
export const LOGOUT = 'LOGOUT';

export function authenticateUser() {
    
    return (dispatch) => {

        return axios.get('api/isUserAuthenticated')
            .then(function(response) {

                dispatch({
                    type: AUTHENTICATED,
                    payload: response.data
                });

                return response;

            })
            .catch(function() {
                
                dispatch({
                    type: NOT_AUTHENTICATED,
                    payload: {}
                });

            });

    }

}

export function logout() {
    
    return (dispatch) => {

        return axios.get('api/logout')
        .then(function(response) {

            dispatch({
                type: LOGOUT,
                payload: {}
            });

            return response;

        })
        .catch((error) => {
            console.log(error);
        })

    }

}