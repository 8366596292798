import React from 'react';
import Button from '../../buttons/components/button';

const Title = function(props) {

	return (
		<Button 
			className="accordion-item-title"
			text={props.title}
			icon={icon}
			onClick={() => props.onChange(props.item)}
			type="primary full-width"
		/>
	)
}

const Body = function(props) {
	return (
		<div className="accordion-item-body">
			<div className="accordion-item-body-inner">
				{props.item.body}
			</div>
		</div>
	);
}

const AccordionItem = ({icon, item, iconSelected, onChange}) => {

	return (
		<div className="accordion-item">
			<Title 
				icon={(item._isSelected) ? iconSelected : icon} 
				title={item.title} 
				onChange={onChange} 
				item={item}
			/>
			{item._isSelected && <Body/>}
		</div>
	);

}

export default AccordionItem;