import {
    AUTHENTICATED
} from 'modules/auth/actions/authActions';
import {
    OPEN_MESSAGES,
    CLOSE_MESSAGES
} from 'modules/messages/actions/messagesActions';
import {
    UPDATE_SCREEN_SIZE, 
    UPDATE_HEADER, 
    UPDATE_HEADER_HEIGHT,
    SET_HERO_IMAGE,
    SHOW_FOOTER,
    HIDE_FOOTER
} from '../actions/appActions';
import {
    OPEN_SEARCH,
    CLOSE_SEARCH
} from 'modules/search/actions/searchActions';
import {
    OPEN_MENU,
    CLOSE_MENU
} from 'modules/menu/actions/menuActions';

const initialState = {
    _hasOverlayOpen: false,
    _hasMessagesOpen: false,
    _screenSize: 'large',
    _header: null,
    _headerHeight: 0,
    _heroImage: null,
    _hasSearchOpen: false,
    _hasMenuOpen: false,
    _isFooterVisible: false
};

export default function(state = {}, action) {

    switch(action.type) {
        case AUTHENTICATED:
            return initialState;
        case OPEN_MESSAGES:
            return {...state, _hasMessagesOpen: true}
        case CLOSE_MESSAGES:
            return {...state, _hasMessagesOpen: false}
        case UPDATE_SCREEN_SIZE:
            return {
                ...state, 
                _screenSize: action.payload._screenSize, 
                _screenWidth: action.payload._screenWidth,
                _screenHeight: action.payload._screenHeight
            }
        case UPDATE_HEADER:
            return {...state, _header: action.payload}
        case UPDATE_HEADER_HEIGHT:
            return {...state, _headerHeight: action.payload.headerHeight};
        case SET_HERO_IMAGE:
            return {...state, _heroImage: action.payload}
        case OPEN_SEARCH:
            return {...state, _hasSearchOpen: true}
        case CLOSE_SEARCH:
            return {...state, _hasSearchOpen: false}
        case OPEN_MENU:
            return {...state, _hasMenuOpen: true}
        case CLOSE_MENU:
            return {...state, _hasMenuOpen: false}
        case SHOW_FOOTER:
            return {...state, _isFooterVisible: true}
        case HIDE_FOOTER:
            return {...state, _isFooterVisible: false}
    }
    return state;
}