import React from 'react';
import createReactClass from 'create-react-class';
import classnames from 'classnames';

const TextToggle = createReactClass({

    getDefaultProps: function() {
        return {
            value: false,
            trueText: 'Enabled',
            falseText: 'Disabled',
            isSmall: false
        };
    },

    onInputChange: function(event) {
        const { onChange } = this.props;
        onChange(event.target.checked);
    },

    render: function() {
        const {className, value, name, falseText, trueText, isSmall} = this.props;

        return (
            <label className={classnames('text-toggle', className, {'is-small': isSmall })}>
                <span className={classnames('text-toggle-text text-toggle-text-false', { 'is-enabled': !value })}>
                    {falseText}
                </span>
                <span className={classnames('text-toggle-text text-toggle-text-true', { 'is-enabled': value })}>
                    {trueText}
                </span>
                <input
                    name={name}
                    type="checkbox"
                    checked={value}
                    onChange={this.onInputChange} 
                />
            </label>
        );
    }

});

module.exports = TextToggle;
