import axios from 'axios';

const FETCH_MY_ACCOUNT = 'myKinderlySubscription/FETCH_MY_ACCOUNT';
const FETCH_MY_ACCOUNT_STATUS = 'myKinderlySubscription/FETCH_MY_ACCOUNT_STATUS';
const FETCH_UPDATE_PAYMENT_METHOD_FORM = 'myKinderlySubscription/FETCH_UPDATE_PAYMENT_METHOD_FORM';
const FETCH_INVOICES = 'myKinderlySubscription/FETCH_INVOICES';
const FETCH_INVOICE_PDF = 'myKinderlySubscription/FETCH_INVOICE_PDF';
const UPDATE_USER_DETAILS = 'myKinderlySubscription/UPDATE_USER_DETAILS';
const ADD_USER_TO_SUBSCRIPTION_GROUP = 'myKinderlySubscription/ADD_USER_TO_SUBSCRIPTION_GROUP';
const REMOVE_USER_FROM_SUBSCRIPTION_GROUP = 'myKinderlySubscription/REMOVE_USER_FROM_SUBSCRIPTION_GROUP';
const SET_SENIOR_MEMBER = 'myKinderlySubscription/SET_SENIOR_MEMBER';
const REMOVE_SENIOR_MEMBER = 'myKinderlySubscription/REMOVE_SENIOR_MEMBER';
const UPDATE_SUBSCRIPTION = 'myKinderlySubscription/UPDATE_SUBSCRIPTION';
const CANCEL_SUBSCRIPTION = 'myKinderlySubscription/CANCEL_SUBSCRIPTION';
const GET_COUPON = 'myKinderlySubscription/GET_COUPON';
const UPDATE_INVOICE = 'myKinderlySubscription/UPDATE_INVOICE';
const UPDATE_SUSCRIPTION_GROUP_NAME = 'myKinderlySubscription/UPDATE_SUSCRIPTION_GROUP_NAME';
const CREATE_SUBSCRIPTION_GROUP = 'myKinderlySubscription/CREATE_SUBSCRIPTION_GROUP';
const CREATE_SINGLE_ORDER = 'myKinderlySubscription/CREATE_SINGLE_ORDER';
const CREATE_SUBSCRIPTION_ORDER = 'myKinderlySubscription/CREATE_SUBSCRIPTION_ORDER';
const IS_MEMBER_OF_SUBSCRIPTION_GROUP = 'myKinderlySubscription/IS_MEMBER_OF_SUBSCRIPTION_GROUP';

const CHECK_EXISTING_MEMBERSHIP_START = 'myKinderlySubscription/CHECK_EXISTING_MEMBERSHIP_START';
const CHECK_EXISTING_MEMBERSHIP_SUCCEED = 'myKinderlySubscription/CHECK_EXISTING_MEMBERSHIP_SUCCEED';
const CHECK_EXISTING_MEMBERSHIP_FAILED = 'myKinderlySubscription/CHECK_EXISTING_MEMBERSHIP_FAILED';

export default function reducer(state = {
    _subscriptionDetails: {},
    _subscriptions: {},
    _myAccountDetails: {},
    _invoices: {}
}, action) {
    
    switch(action.type) {
        case FETCH_MY_ACCOUNT:
            return action.payload;
        default:
        	return state;
    }
}

export function fetchMyAccount() {

    return (dispatch) => {

        return axios.get('api/myKinderlySubscription')
        .then(function(response) {

            dispatch({
                type: FETCH_MY_ACCOUNT,
                payload: response.data
            });

            return Promise.resolve(response);
        });
            
    }

}

export function fetchMyAccountStatus() {
    
    return (dispatch) => {

        return axios.get('api/myKinderlySubscription/status')
        .then(function(response) {
        
            dispatch({
                type: FETCH_MY_ACCOUNT_STATUS,
                payload: response.data
            });
            
            return response.data;

        });

    }       

};

export function fetchUpdatePaymentMethodForm() {

    return (dispatch) => {

        return axios.get('api/myKinderlySubscription/updatePaymentMethod')
        .then(function(response) {

            dispatch({
                type: FETCH_UPDATE_PAYMENT_METHOD_FORM,
                payload: response.data
            });
            return response.data;

        });

    }  

}

export function fetchInvoices() {

    return (dispatch) => {

        return axios.get('api/myKinderlySubscription/invoices')
            .then(function(response) {

                dispatch({
                    type: FETCH_INVOICES,
                    payload: response.data
                });

                return Promise.resolve(response);

            });

    }

}

export function isMemberOfSubscriptionGroup() {

    return (dispatch) => {

        return axios.get('api/myKinderlySubscription/isMemberOfSubscriptionGroup')
            .then(function(response) {

                dispatch({
                    type: IS_MEMBER_OF_SUBSCRIPTION_GROUP,
                    payload: response.data
                });

                return response.data;

            });

    }

}

export function fetchInvoicePdf(invoiceId) {

    return (dispatch) => {

        return axios.put('api/myKinderlySubscription/invoicePdf', {invoiceId: invoiceId})
            .then(function(response) {

                dispatch({
                    type: FETCH_INVOICE_PDF,
                    payload: response.data
                });

                return response.data;
            });
    }
}

export function updateUserDetails(userDetails) {

    return (dispatch) => {

        return axios.put('api/myKinderlySubscription/updateUserDetails', userDetails)
        .then(function(response) {
            
            dispatch({
                type: UPDATE_USER_DETAILS,
                payload: response.data
            });
            
            return response.data;

        });

    }  

}

export function addUserToSubscriptionGroup(addedUser) {

    return (dispatch) => {

        return axios.put('api/myKinderlySubscription/addUserToSubscriptionGroup', addedUser)
        .then(function(response) {
            
            dispatch({
                type: ADD_USER_TO_SUBSCRIPTION_GROUP,
                payload: response.data
            });
            
            return response.data;

        });

    }  

}

export function removeUserFromSubscriptionGroup(memberId) {

    return (dispatch) => {

        return axios.put('api/myKinderlySubscription/removeUserFromSubscriptionGroup', memberId)
        .then(function(response) {
            
            dispatch({
                type: REMOVE_USER_FROM_SUBSCRIPTION_GROUP,
                payload: response.data
            });
            
            return response.data;

        });

    }  

}

export function setSeniorMember(memberId) {

    return (dispatch) => {

        return axios.put('api/myKinderlySubscription/setSeniorMember', memberId)
        .then(function(response) {
            
            dispatch({
                type: SET_SENIOR_MEMBER,
                payload: response.data
            });
            
            return response.data;

        });

    }  

}

export function removeSeniorMember(memberId) {

    return (dispatch) => {

        return axios.put('api/myKinderlySubscription/removeSeniorMember', memberId)
        .then(function(response) {
            
            dispatch({
                type: REMOVE_SENIOR_MEMBER,
                payload: response.data
            });
            
            return response.data;

        });

    }  

}

export function updateSubscription(subscription) {

    return (dispatch) => {

        return axios.put('api/myKinderlySubscription/updateSubscription', subscription)
        .then(function(response) {

            dispatch({
                type: UPDATE_SUBSCRIPTION,
                payload: response.data
            });
            
            return response.data;

        });
        // .catch(function(response) {
        //     return Promise.reject(response);
        // });

    }  

}

export function cancelSubscription(subscription) {

    return (dispatch) => {

        return axios.put('api/myKinderlySubscription/cancelSubscription', subscription)
            .then(function(response) {

                dispatch({
                    type: CANCEL_SUBSCRIPTION,
                    payload: response.data
                });

                return response.data;

            });

    }

}

export function getCoupon(coupon, subscriptionTerm) {

    return (dispatch) => {

        return axios.put('api/myKinderlySubscription/getCoupon', {'coupon': coupon, 'subscriptionTerm': subscriptionTerm})
            .then(function(response) {

                dispatch({
                    type: GET_COUPON,
                    payload: response.data
                });

                return response.data;

            });

    }

}

export function createCoupon(coupon) {
    return (dispatch) => {
        const payload = {
            id : coupon.plan_id,
            name : "Sample Offer",
            discount_type : "fixed_amount",
            discount_amount : 500,
            apply_on : "invoice_amount",
        }    
        return axios.put('api/myKinderlySubscription/createCoupon', payload)
            .then(response => {
                return response.data;
            })
            .catch( e => {
                return e
            });
            
    }
}

export function updateInvoice(billing) {
    return (dispatch) => {

        return axios.put('api/myKinderlySubscription/updateInvoice', billing)
        .then(function(response) {

            dispatch({
                type: UPDATE_INVOICE,
                payload: response.data
            });
            
            return response.data;

        });

    } 
}

export function updateSubscriptionGroupName(data) {
    return (dispatch) => {

        return axios.put('api/myKinderlySubscription/updateSubscriptionGroupName', data)
        .then(function(response) {

            dispatch({
                type: UPDATE_SUSCRIPTION_GROUP_NAME,
                payload: response.data
            });
            
            return response.data;

        });

    } 
}

export function createSingleOrder() {

    return (dispatch) => {

        return axios.put('api/myKinderlySubscription/createSingleOrder', null)
            .then(function(response) {

                dispatch({
                    type: CREATE_SINGLE_ORDER,
                    payload: response.data
                });

                return response.data;

            });

    }

}

export function createSubscriptionOrder() {

    return (dispatch) => {

        return axios.put('api/myKinderlySubscription/createSubscriptionOrder', null)
            .then(function(response) {

                dispatch({
                    type: CREATE_SUBSCRIPTION_ORDER,
                    payload: response.data
                });

                return response.data;

            });

    }

}

export function checkExistingMembershipInKinderly(data) {
    return () => {
        return new Promise( (resolve, reject) => {
            kinderlyOauthToken()
                .then( response => {
                    return resolve (kinderlyMembershipRequest(response.data.access_token, data));
                })
                .catch( e => reject(e.response));
        });
    };
};

export function checkExistingMembershipInPACEY(data) {

    return (dispatch) => {
        return new Promise( (resolve, reject) => {
            axios.post(
                'https://api.kinderly.co.uk/api/auth/register/person/check/pacey',
            ).then(function(response) {

                // console.log(response.data);
                // dispatch(response.data)
                resolve(response.data)

            } )
                .catch(function(error) {
                        reject({
                            'response': {
                                'data': {
                                    'message': 'Apologies, we couldn\'t verify your PACEY account at the moment. Please try again later.'
                                }
                            }
                        })
                    }
                )
        });
    };
}


function kinderlyMembershipRequest(token, data) {
    return new Promise( (resolve, reject) =>{


        var kinderlyApiUrlStaging = 'https://staging.api.kinderly.co.uk/api/nursery/person/check',
            kinderlyApiUrl = 'https://api.kinderly.co.uk/api/nursery/person/check';

        try {
            return resolve(
                axios.post(kinderlyApiUrl, data, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                        Accept: 'application/json'
                    }
                })
            );
        } catch( error ){
            return reject(error);
        }  
    });
}

function kinderlyOauthToken() {
    return new Promise( (resolve, reject) => {

        var kinderlyOauthApiUrlStaging = 'https://staging.api.kinderly.co.uk/oauth/token',
            kinderlyOauthApiUrl = 'https://api.kinderly.co.uk/oauth/token';

        try {
            return resolve (
                axios.post(kinderlyOauthApiUrl, {
                    "grant_type" : "client_credentials",
                    "client_secret" : "fmM75on1ulGtPp9vCB2ohFBhFVecxZgTW0uop70L",
                    "client_id" : 1
                })
            );
        } catch (error) {
            return reject(error);
        };
    });  
}
