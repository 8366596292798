import React from 'react';
import createReactClass from 'create-react-class';
import {registerType} from '../helpers';
import Field from '../components/field.jsx';


var FormFieldNumber = createReactClass({

    getInitialState: function() {
        return {
            value: this.props.value
        }
    },

    getMinValue: function() {
        if (this.props.minValue) {
            if (!isNaN(this.props.minValue)) {
                return this.props.minValue;
            }
        } else if (this.props.minValueModelAttribute) {
            if (this.props.model[this.props.minValueModelAttribute]) {
                return this.props.model[this.props.minValueModelAttribute].length;
            }
        }
    },

    getMaxValue: function() {
        if (this.props.maxValue) {
            if (!isNaN(this.props.maxValue)) {
                return this.props.maxValue;
            }
        } else if (this.props.maxValueModelAttribute) {
            if (this.props.model[this.props.maxValueModelAttribute]) {
                return this.props.model[this.props.maxValueModelAttribute].length;
            }
        }
    },

    onChange: function(event) {
        var value = parseInt(event.target.value);

        var that = this;

        this.setState({
            value: value
        });

        this.updateField();

    },

    updateField: _.throttle(function() {
        this.props.updateField(this.state.value);
    }, 2000, {leading: false}),

    renderField: function({ ariaDescribedBy }) {
        return (
            <input 
                id={this.props.fieldId}
                type="number"
                min={this.getMinValue()}
                max={this.getMaxValue()}
                className="form-field-input"
                aria-describedby={ariaDescribedBy}
                value={this.state.value}
                onChange={this.onChange}
                onBlur={this.props.onBlur}
                onFocus={this.props.onFocus}
            />
        )
    },

    render: function() {
        return (
            <Field 
                fieldId={this.props.fieldId}
                label={this.props.label}
                help={this.props.help}
                error={this.props.error} >
                {this.renderField}
            </Field>
        );
    }

});

registerType('Number', FormFieldNumber);

export default FormFieldNumber;