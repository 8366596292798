import React from 'react';
import createReactClass from 'create-react-class';
import moment from 'moment';
import {Button} from 'uiKit';
import MyAccountOverviewTitle from './myAccountOverviewTitle';
import MyAccountOverviewItem from'./myAccountOverviewItem'

var MyAccountOverviewTestSingle = createReactClass({

    renderSingleButton: function() {

        return (
            <Button text="Single Purchase Test" type="primary" onClick={this.props.onUpdateTestSingleClicked}/>
        );
    },

    renderSubscriptionButton: function() {

        return (
            <Button text="Subscription Purchase Test" type="primary" onClick={this.props.onUpdateTestSubscriptionClicked}/>
        );
    },

    render: function() {
        return (
            <div className="my-account-overview-section">
                <MyAccountOverviewTitle text="Test Single Purchase or Subscription (KinderlyLearn)"/>
                <div className="my-account-overview-content">
                    <p>This will create non-reccuring Addon purchase<br />
                        {this.renderSingleButton()}
                    </p>
                    <p>This will create a monthly subscription<br />
                        {this.renderSubscriptionButton()}
                    </p>
                </div>
            </div>
        );
    }

});

export default MyAccountOverviewTestSingle;
