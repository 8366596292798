import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
    Loading
} from 'uiKit';
import {
    PageLoader
} from 'modules/app';
import {updateHeader} from 'modules/app/actions/appActions';
import LP from 'modules/app/helpers/lp';
import handleContainerError from 'modules/app/helpers/handleContainerError';
import {
    fetchGroups,
    uploadCsvSubscriptionGroup
} from '../duxUsers';
import GroupsOverview from '../components/groupsOverview';
import {addDialog, addToast} from 'modules/notifications/actions/notifications';
import csvUploadSchema from '../schemas/csvUploadSchema';
import showErrorDialog from '../helpers/showErrorDialog';
import myAccountImportUsersDialog from '../components/myAccountImportUsersDialog';

const PageContainer = createReactClass({

    contextTypes: {
        router: PropTypes.object
    },

    getInitialState: function() {
        return {
            _hasDataLoaded: false,
            _isSyncing: true,
            _isLoadingUsers: false,
            _statusCode: null,
            errorMessage: '',
        }
    },

    componentDidMount: function() {
        // TODO golive

        this.updateHeader();
        this.fetchData();
    },


    fetchData: function() {
        this.setState({
            _isSyncing: true
        });
        return this.props.fetchGroups()
        .then((response) => {
            this.setState({
                _isSyncing: false,
                _hasDataLoaded: true,
            });
        })
        .catch((response) => {
            handleContainerError(response, this);
        });
    },

    updateHeader: function() {
         this.props.updateHeader({
             breadcrumbs: [
                 {
                     text: 'Groups List'
                 }
             ]
        });
    },

    onImportUsersToSubscriptionGroupClicked: function() {
        var importUsersDialog = {
            dialogType: 'default',
            title: 'Import Users',
            body: '',
            options: {
                uploadCsv: this.props.uploadCsvSubscriptionGroup,
                schema: csvUploadSchema,
                autoEnrollGroups: this.state._autoEnrollGroups
            },
            customDialog: myAccountImportUsersDialog,
            actions: [
                {
                    action: 'upload',
                    buttonText: 'UPLOAD',
                    buttonType: 'primary'
                },
                {
                    action: 'cancel',
                    buttonText: 'CANCEL',
                    buttonType: 'alert'
                }
            ]
        };

        //TODO - Error Handling
        this.props.addDialog(importUsersDialog)
        .then(response => {

            var actionToast = {
                title: 'No users were added'
            };
    
            if (response.action === 'done') {
                this.fetchData(1);
                const actionToast = {
                    title: 'Users have been added'
                };

                this.props.addToast(actionToast);
            }
            
        });
    },

    render: function() {

        return (
            <PageLoader 
                hasLoaded={this.state._hasDataLoaded} 
                hasErrored={this.state._hasErrored}
                statusCode={this.state._statusCode}
                errorMessage={this.state.errorMessage}
                text="Loading"
                >
                <GroupsOverview
                    groups={this.props.groups}
                    isSyncing={this.state._isSyncing}
                    onImportUsersToSubscriptionGroupClicked={this.onImportUsersToSubscriptionGroupClicked}
                />
            </PageLoader>
        );
    }

});

export default connect(function(state, props) {
    return {
        users: state.myKinderlyUsers._users,
        groups: state.myKinderlyGroups._groups
    }
}, {
    uploadCsvSubscriptionGroup,
    updateHeader,
    fetchGroups,
    addToast,
    addDialog,
})(PageContainer);
