import React from 'react';

const CollectionItemParents = function(props) {

    const renderParents = function() {
        return _.map(props.parents, (parent) => {
            return (
                <div className="collection-item-parents-item" onClick={() => props.onParentClicked(parent._id)}>
                    <i className={`icon icon-book2 collection-item-parents-item-icon`}/>
                    <span className="collection-item-parents-item-title">{parent.title}</span>
                </div>
            );
        });
    }

	return (
		<div className="collection-item-parents">
            {renderParents()}
        </div>
	);
}

export default CollectionItemParents;